import { Box, LinearProgress } from '@mui/material';
import { common } from 'constants/ImagesFiles';
import { IFullScreenLoading } from './IFullScreenLoading';

export function FullScreenLoading(props: IFullScreenLoading) {
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <img src={common.exactLogo} style={{ height: 70 }} alt={'logo'} />
      <Box width={210} mt={2}>
        <LinearProgress />
      </Box>
    </Box>
  );
}
