import ITranslations from 'utils/translations/ITranslations';
import { createLaboratoryPage } from './createLaboratoryPage';
import { laboratoryNotFoundPage } from './laboratoryNotFoundPage';
import { loginPageTranslations } from './loginPage';
import { signupPageTranslations } from './signupPage';
import { stepsComponent } from './stepsComponent';
import { sideDrawer } from './sideDrawer';

const en: ITranslations = {
  pages: {
    loginPage: loginPageTranslations,
    signupPage: signupPageTranslations,
    laboratoryNotFoundPage: laboratoryNotFoundPage,
    createLaboratoryPage: createLaboratoryPage,
  },
  components: {
    steps: stepsComponent,
    sideDrawer: sideDrawer,
  },
};

export default en;
