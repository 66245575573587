import { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { ISteps, Steps } from 'components/Steps';
import translations from 'utils/translations';
import { useFormik } from 'formik';
import { ICreateLaboratory, ICreateLaboratoryFormik } from 'pages/CreateLaboratory';
import { BasicDetailsForm } from 'pages/CreateLaboratory/BasicDetailsForm';
import { LocationDetailsForm } from 'pages/CreateLaboratory/LocationDetailsForm';
import { BankingDetailsForm } from 'pages/CreateLaboratory/BankingDetailsForm';
import { createLaboratorySchema } from 'FormSchema/CreateLaboratorySchema';
import { OtherDetailsForm } from './OtherDetailsForm';
import { createLaboratory, getLaboratoryList } from 'actions/LaboratoryActions';
import { ILaboratory } from 'exactt-types/lib';
import { useNavigate } from 'react-router-dom';

const steps: ISteps['steps'] = [
  {
    label: translations.pages.createLaboratoryPage.step1Lable,
    description: translations.pages.createLaboratoryPage.step1Description,
  },
  {
    label: translations.pages.createLaboratoryPage.step2Lable,
    description: translations.pages.createLaboratoryPage.step2Description,
  },
  {
    label: translations.pages.createLaboratoryPage.step3Lable,
    description: translations.pages.createLaboratoryPage.step3Description,
  },
  {
    label: translations.pages.createLaboratoryPage.step4Lable,
    description: translations.pages.createLaboratoryPage.step4Description,
  },
  // {
  //   label: translations.pages.createLaboratoryPage.step5Lable,
  //   description: translations.pages.createLaboratoryPage.step5Description,
  // },
];

function _CreateLaboratory(props: ICreateLaboratory) {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const createLaboratoryFormik: ICreateLaboratoryFormik = useFormik({
    initialValues: createLaboratorySchema.initialValues,
    validationSchema: createLaboratorySchema.validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const createLaboratoryBody: ILaboratory = {
        prefix: values.prefix,
        labType: values.labType as any,
        address: {
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          state: values.state,
          zip: parseInt(values.zip),
        },
        bankingDetails: {
          accountName: values.accountName,
          accountNumber: values.accountNumber,
          bank: values.bank,
          branch: values.branch,
          ifscCode: values.ifscCode,
        },
        name: values.name,
        contactPerson: values.contactPerson,
        country: values.country,
        drugLicenceNumber: values.drugLicenceNumber,
        email: values.email,
        gst: values.gst,
        logo: values.logo,
        pan: values.pan,
        phone: values.phone.toString(),
        type: values.type,
        nabl: values.nabl,
        website: values.website,
        otherLicenceNumber: values.otherLicenceNumber,
        stampImage: values.stampImage,
      };
      let res = await props.createLaboratory(createLaboratoryBody);
      if (res.status === 200) {
        await props.getLaboratoryList();
        navigate('/');
      }
      setSubmitting(false);
    },
  });

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    if (step > 0) setStep(step - 1);
  };

  useEffect(() => {
    if (props.laboratoryList.length) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container display={'flex'} justifyContent={'center'}>
      <Grid container justifyContent={'center'}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Typography color={'GrayText'} mt={3} variant={'body2'} textAlign={'center'} maxWidth={400}>
            {translations.pages.createLaboratoryPage.forms.basicDetails.subTitle}
          </Typography>
          <Typography mt={2} variant={'h5'} textAlign={'center'}>
            {translations.pages.createLaboratoryPage.forms.basicDetails.title}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={4} style={{ height: '100%' }}>
        <Steps steps={steps} step={step} onBack={onBack}>
          {(currentStep) => (
            <Grid container>
              <Grid item xs={12} display={currentStep === 0 ? 'flex' : 'none'}>
                <BasicDetailsForm formik={createLaboratoryFormik} onNext={onNext} />
              </Grid>
              <Grid item xs={12} display={currentStep === 1 ? 'flex' : 'none'}>
                <LocationDetailsForm formik={createLaboratoryFormik} onBack={onBack} onNext={onNext} />
              </Grid>
              <Grid item xs={12} display={currentStep === 2 ? 'flex' : 'none'}>
                <BankingDetailsForm formik={createLaboratoryFormik} onBack={onBack} onNext={onNext} />
              </Grid>
              <Grid item xs={12} display={currentStep === 3 ? 'flex' : 'none'}>
                <OtherDetailsForm formik={createLaboratoryFormik} onBack={onBack} onNext={onNext} />
              </Grid>
            </Grid>
          )}
        </Steps>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratoryList: state.laboratory.list,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createLaboratory, getLaboratoryList }, dispatch),
  };
}

export const CreateLaboratory = connect(mapStateToProps, mapDispatchToProps)(_CreateLaboratory);

export default connect(mapStateToProps, mapDispatchToProps)(_CreateLaboratory);
