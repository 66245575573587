import { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as Laboratory } from 'assets/images/laboratory.svg';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import translations from 'utils/translations';
import { IReducer } from 'Reducers/IReducer';
import { ILaboratoryNotFound } from 'pages/LaboratoryNotFound';

function _LaboratoryNotFound(props: ILaboratoryNotFound) {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.laboratoryList.length) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <Laboratory style={{ width: 686, height: 295 }} />
      <Typography mt={2} variant={'h5'} textAlign={'center'}>
        {translations.pages.laboratoryNotFoundPage.title}
      </Typography>
      <Typography color={'GrayText'} mt={3} mb={3} variant={'body2'} textAlign={'center'} maxWidth={400}>
        {translations.pages.laboratoryNotFoundPage.subTitle}
      </Typography>
      <Button variant={'contained'} onClick={() => navigate('/laboratory/create')}>
        {translations.pages.laboratoryNotFoundPage.laboratorySetupButton}
      </Button>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratoryList: state.laboratory.list,
});

export const LaboratoryNotFound = connect(mapStateToProps)(_LaboratoryNotFound);
