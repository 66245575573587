import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, TableBody, Link } from '@mui/material';
import { IReferenceTable } from './IReferenceTable';

export default function _ReferenceTable(props: IReferenceTable) {
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {/* Mapp code */}
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onEdit(row);
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {row.address?.line1 || row.address?.line2 || row.address?.city || row.address?.zip
                    ? `${row.address.line1} ${row.address.line2} ${row.address.state} ${row.address.zip}`
                    : 'No Address Provided'}
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.companyName}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => props.onEdit(row)}>Edit</Button>
                  <Button color="error" onClick={() => props.onDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  department: state.laboratory.current?.departments ?? [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const ReferenceTable = connect(mapStateToProps, mapDispatchToProps)(_ReferenceTable);
