import { Box, Grid } from '@mui/material';
import React from 'react';

export interface GeneralFieldsValue {
  value: string;
  label: string;
}

export interface GeneralFieldsProps {
  values: GeneralFieldsValue[];
  labelWidth?: number;
  hideNumbers?: boolean;
  autoWidth?: boolean;
  fontWeight?: any;
}

export default function GeneralFields(props: GeneralFieldsProps) {
  const PrintDetails = ({ label, value, labelWidth, autoWidth, fontWeight }: any) => {
    return (
      <Box display={'flex'}>
        <Box fontWeight={fontWeight} width={!autoWidth ? labelWidth || 190 : undefined}>
          <strong>{label} </strong>
        </Box>
        <Box flex={1}> : {value}</Box>
      </Box>
    );
  };

  return (
    <Grid container>
      {props.values.map((value, index) => (
        <Grid item xs={6} key={index} mt={0.3}>
          <PrintDetails
            labelWidth={props.labelWidth}
            label={(props.hideNumbers ? `(${String.fromCharCode(97 + index)}) ` : '') + value.label}
            value={value.value}
            autoWidth={props.autoWidth}
            fontWeight={props.fontWeight}
          />
        </Grid>
      ))}
    </Grid>
  );
}
