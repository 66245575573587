import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AuthNavigation from 'Navigations/AuthNavigationRoute';
import UnauthNavigation from 'Navigations/UnauthNavigationRoute';
import { IReducer } from 'Reducers/IReducer';
import { IUser } from 'exactt-types/lib';
import { bindActionCreators } from 'redux';
import { VariantType, useSnackbar } from 'notistack';
import { getEnums } from 'actions/AuthActions';
import { getLaboratoryList } from 'actions/LaboratoryActions';
import { FullScreenLoading } from 'components/FullScreenLoading';
import axios from 'axios';

interface INavigations {
  user: null | IUser;
  snackbar: {
    variant: VariantType;
    message: string | null;
  };
  accessToken: null | string;
  getEnums: () => Promise<any>;
  getLaboratoryList: () => Promise<any>;
}

function Navigations(props: INavigations) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    await props.getEnums();
    await props.getLaboratoryList();
    setLoading(false);
  };

  useEffect(() => {
    if (props.snackbar.message) {
      enqueueSnackbar(props.snackbar.message, {
        variant: props.snackbar.variant,
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
    }
    // eslint-disable-next-line
  }, [props.snackbar]);

  useEffect(() => {
    if (props.accessToken) axios.defaults.headers.common['Authorization'] = `Bearer ${props.accessToken}`;
    else axios.defaults.headers.common['Authorization'] = '';
    if (props.user) {
      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [props.user, props.accessToken]);

  if (loading) return <FullScreenLoading />;
  if (props.user && !loading) return <AuthNavigation />;
  return <UnauthNavigation />;
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  accessToken: state.auth.accessToken,
  snackbar: state.system.snackbar,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ getEnums, getLaboratoryList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigations);
