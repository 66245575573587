import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import AsyncSelect from 'react-select/async';
import { IReducer } from 'Reducers/IReducer';
import { ICreatePriceListProps, IFormikCreatePriceList } from './ICreatePriceList';
import { searchCustomer } from 'actions/CustomerActions';
import { createPriceList, getPriceListById, searchMasterList } from 'actions/LaboratoryActions';
import { ICustomer, IMaster, IPriceListParameter } from 'exactt-types/lib';
import { CreatePriceListSchema } from 'FormSchema/CreatePriceListSchema';
import { useFormik } from 'formik';
import useQuery from 'utils/getQueryParams';

function _CreatePriceList(props: ICreatePriceListProps) {
  const navigate = useNavigate();

  const laboratoryId = props.laboratory?.details?._id || '';

  // get id from query params
  const query = useQuery();
  const priceListId = query.get('id');

  const [masterSearchQuery, setMasterSearchQuery] = useState('');
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [selectedMaster, setSelectedMaster] = useState<IMaster | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [parameters, setParameters] = useState<IPriceListParameter[]>([]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Price List',
      onClick: () => navigate('/laboratory/price-list'),
    },
  ];

  const formik: IFormikCreatePriceList = useFormik({
    initialValues: CreatePriceListSchema.initialValues,
    validationSchema: CreatePriceListSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await props.createPriceList(laboratoryId, {
          laboratory: laboratoryId,
          customer: selectedCustomer?._id || '',
          master: selectedMaster?._id || '',
          parameters: parameters,
        });
        navigate('/laboratory/price-list');
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    props.searchCustomer(laboratoryId, { page: 1, size: 20, query: customerSearchQuery });
    // eslint-disable-next-line
  }, [customerSearchQuery]);

  useEffect(() => {
    props.searchMasterList(laboratoryId, { page: 1, size: 20, query: masterSearchQuery });
    // eslint-disable-next-line
  }, [masterSearchQuery]);

  const customersOptions = async (inputValue: string) => {
    const list = await props.searchCustomer(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.customers.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const mastersOptions = async (inputValue: string) => {
    const list = await props.searchMasterList(laboratoryId, { page: 1, size: 10, query: inputValue });

    return list.data.masters.map((i: any) => ({
      value: i._id,
      label: i.sampleName,
      option: i,
    }));
  };

  useEffect(() => {
    const parameters: IPriceListParameter[] = [];
    if (selectedMaster?.parameters && !priceListId) {
      selectedMaster.parameters.forEach((i: any) => {
        parameters.push({
          name: i.name,
          price: 0,
        });
      });

      setParameters(parameters);
    }
  }, [selectedMaster, selectedCustomer]);

  useEffect(() => {
    if (props.priceList) {
      setSelectedCustomer((props.priceList as any).customer);
      setSelectedMaster((props.priceList as any).master);
      setParameters(props.priceList.parameters);

      formik.setFieldValue('customer', (props.priceList as any).customer?._id || '');
      formik.setFieldValue('master', (props.priceList as any).master?._id || '');
    }
  }, [props.priceList]);

  useEffect(() => {
    console.log('priceListId -> ', priceListId);
    if (priceListId) {
      props.getPriceListById(laboratoryId, priceListId);
    } else {
      setSelectedCustomer(null);
      setSelectedMaster(null);
      setParameters([]);
    }
  }, []);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Price List" />
      <Box
        pt={12}
        ml={3}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
        flexDirection={'column'}
        width={'95%'}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomFieldWithLabel width={120} label="Customer">
              <AsyncSelect
                loadOptions={customersOptions}
                value={{ value: selectedCustomer?._id, label: selectedCustomer?.name }}
                isDisabled={!!priceListId}
                defaultOptions={(props.customerList || []).map((i) => ({
                  label: i.name,
                  value: i._id,
                }))}
                onChange={(newValue: any) => {
                  const findCustomer = props.customerList.find((i) => i._id === newValue?.value);
                  if (findCustomer) setSelectedCustomer(findCustomer);
                  formik.setFieldValue('customer', newValue?.value || '');
                }}
              />
            </CustomFieldWithLabel>

            {formik.touched.customer && formik.errors.customer}
          </Grid>
          <Grid item xs={4}>
            <CustomFieldWithLabel width={120} label="Master">
              <AsyncSelect
                loadOptions={mastersOptions}
                value={{ value: selectedMaster?._id, label: selectedMaster?.sampleName }}
                defaultOptions={(props.masterList || []).map((i) => ({
                  label: i.sampleName,
                  value: i._id,
                }))}
                isDisabled={!!priceListId}
                onChange={(newValue: any) => {
                  const findMaster = props.masterList.find((i) => i._id === newValue?.value);
                  if (findMaster) setSelectedMaster(findMaster);
                  formik.setFieldValue('master', newValue?.value || '');
                }}
              />
            </CustomFieldWithLabel>
            {formik.touched.master && formik.errors.master}
          </Grid>
          <Grid item xs={4}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Box>
                <Button variant="outlined" onClick={() => navigate('/')}>
                  Cancle
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  disabled={!selectedCustomer || !selectedMaster}
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {priceListId ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {selectedMaster ? (
          <>
            {parameters.length > 0 ? (
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Typography variant="h6" color={'#000'} fontSize={14}>
                  Parameters
                </Typography>
                <Divider />
                <Box
                  style={{
                    height: '70vh',
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                    padding: '10px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '5px',
                  }}
                >
                  {parameters.map((i) => (
                    <Box display={'flex'} alignItems={'center'} mt={0.5} justifyContent={'space-between'}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            required
                            margin="none"
                            value={parseFloat(i.price.toString())}
                            name="rate"
                            type="number"
                            onChange={(e) => {
                              const newParameters = parameters.map((j) => {
                                if (j.name === i.name) {
                                  return {
                                    ...j,
                                    price: Number(e.target.value),
                                  };
                                }
                                return j;
                              });
                              setParameters(newParameters);
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} style={{ alignItems: 'center', display: 'flex' }}>
                          <Typography variant="h6" color={'#000'} fontSize={14} style={{ textAlign: 'right' }}>
                            {i.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <Grid item xs={12} mt={2}>
                <Typography variant="h6" color={'red'} fontSize={14}>
                  No parameters found
                </Typography>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12} mt={2}>
            <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
              Please Select a master
            </Typography>
            <Divider />
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    customerList: state.laboratory.searchCustomer,
    masterList: state.laboratory.masters,
    priceList: state.laboratory.priceListDetails,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        searchCustomer,
        searchMasterList,
        createPriceList,
        getPriceListById,
      },
      dispatch
    ),
  };
}

export const CreatePriceList = connect(mapStateToProps, mapDispatchToProps)(_CreatePriceList);
