import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { ISearchDialogProps } from 'components/SearchDialog';

export function SearchDialog(props: ISearchDialogProps) {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.body}</DialogContentText>
      </DialogContent>
      {!props.hideButtons && (
        <DialogActions>
          <Button disabled={props.isLoading} onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button variant="contained" color="error" disabled={props.isLoading} onClick={() => props.onAction()}>
            {props.actionText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
