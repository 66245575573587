import axios, { AxiosResponse } from 'axios';
import { ILoginServiceRequest, ISignupServiceRequest } from 'services/AuthService';

export const loginService = async (request: ILoginServiceRequest): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post('/auth', request);
    return res;
  } catch (err: any) {
    throw err;
  }
};

export const signupService = async (request: ISignupServiceRequest): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post('/auth/signup', request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const enumsService = async (): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get('/enums');
    return res;
  } catch (err) {
    throw err;
  }
};
