import { Box, Button, Pagination, Stack, TextField } from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IDepartmentProps } from 'pages/Department';
import { useEffect, useState } from 'react';
import { searchDepartment, deleteDepartmentById } from 'actions/LaboratoryActions';
import { DepartmentTable } from './DepartmentTable';
import { IDepartment } from 'exactt-types/lib';
import { DeleteDialog } from 'components/DeleteDialog';

export function _Department(props: IDepartmentProps) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState<undefined | IDepartment>(undefined);
  const [searchQuery, setSearchQuery] = useState('');

  const laboratoryId = props.laboratory?.details._id || '';

  // pagination
  const [page, setPage] = useState(1);
  const size = 15;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.searchDepartment(props.laboratory?.details?._id || '', { page: value, size: size, query: searchQuery });
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Departments',
      onClick: () => navigate('/laboratory/departments'),
    },
  ];

  const onFocus = async () => {
    await props.searchDepartment(props.laboratory?.details._id || '', { page: page, size: size, query: searchQuery });
  };

  const handleDeleteDepartment = async () => {
    try {
      setDeleteLoading(true);
      await props.deleteDepartmentById(laboratoryId, selectedDepartment?._id || '');
      setDeleteModal(false);
    } catch (err) {
      console.log('err -> ', err);
    } finally {
      setDeleteLoading(false);
      onFocus();
    }
  };

  useEffect(() => {
    props.searchDepartment(props.laboratory?.details?._id || '', { page: page, size: size, query: searchQuery });
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Departments"
        right={
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <TextField
              label={'Search'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => navigate('/laboratory/departments/persist')}
            >
              Add Department
            </Button>
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <DepartmentTable
          onDelete={(department) => {
            setDeleteModal(true);
            setSelectedDepartment(department);
          }}
          onEdit={(department) => {
            navigate(`/laboratory/departments/persist?id=${department._id}`);
          }}
          data={props.department || []}
        />
      </Box>
      <DeleteDialog
        title="Delete Department"
        message={`Are you sure you want to remove ${selectedDepartment?.name} Department?`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => handleDeleteDepartment()}
        isLoading={deleteLoading}
      />

      <Stack spacing={2}>
        <Pagination count={props.count} shape="rounded" page={page} onChange={handlePageChange} defaultPage={page} />
      </Stack>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  department: state.laboratory.current?.departments || [],
  count: state.laboratory.count.department,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchDepartment, deleteDepartmentById }, dispatch),
  };
}

export const Department = connect(mapStateToProps, mapDispatchToProps)(_Department);
