import axios, { AxiosResponse } from 'axios';
import { ICreateCustomerRequest } from 'services/CustomerService';

export const addCustomer = async (request: ICreateCustomerRequest): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${request.laboratoryId}/customer`, request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateCustomerById = async (request: ICreateCustomerRequest): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${request.laboratoryId}/customer/${request._id}`, request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerList = async (laboratoryId: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/customer`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerById = async (laboratoryId: string, id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/customer/${id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteCustomerById = async (laboratoryId: string, id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/customer/${id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchCustomer = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/customer/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};
