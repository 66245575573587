import { Grid, TextField, Button } from '@mui/material';
import translations from 'utils/translations';
import { IOtherDetailsForm } from 'pages/CreateLaboratory/OtherDetailsForm';

export function OtherDetailsForm(props: IOtherDetailsForm) {
  const { formik, onBack } = props;
  const isValid =
    formik.errors.gst || formik.errors.pan || formik.errors.city || formik.errors.state || formik.errors.zip
      ? false
      : true;

  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        <TextField
          name="gst"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.gst}
          label={translations.pages.createLaboratoryPage.forms.otherDetails.gstNumber}
          error={formik.touched.gst && formik.errors.gst ? true : false}
          helperText={formik.touched.gst && formik.errors.gst}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="pan"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.pan}
          label={translations.pages.createLaboratoryPage.forms.otherDetails.panNumber}
          error={formik.touched.pan && formik.errors.pan ? true : false}
          helperText={formik.touched.pan && formik.errors.pan}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="nabl"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.nabl}
          label={translations.pages.createLaboratoryPage.forms.otherDetails.nablNumber}
          error={formik.touched.nabl && formik.errors.nabl ? true : false}
          helperText={formik.touched.nabl && formik.errors.nabl}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="drugLicenceNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.drugLicenceNumber}
          label={translations.pages.createLaboratoryPage.forms.otherDetails.drugNumber}
          error={formik.touched.drugLicenceNumber && formik.errors.drugLicenceNumber ? true : false}
          helperText={formik.touched.drugLicenceNumber && formik.errors.drugLicenceNumber}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="otherLicenceNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.otherLicenceNumber}
          label={translations.pages.createLaboratoryPage.forms.otherDetails.otherNumber}
          error={formik.touched.otherLicenceNumber && formik.errors.otherLicenceNumber ? true : false}
          helperText={formik.touched.otherLicenceNumber && formik.errors.otherLicenceNumber}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} display={'flex'} flexDirection={'row-reverse'} mt={1}>
        <Button
          sx={{ marginRight: 1 }}
          variant={'contained'}
          disabled={!isValid || formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {translations.components.steps.saveButton}
        </Button>
        <Button sx={{ marginRight: 1 }} onClick={() => onBack()}>
          {translations.components.steps.backButton}
        </Button>
      </Grid>
    </Grid>
  );
}
