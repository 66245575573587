import * as Yup from 'yup';

export const createTestGroupSchema = {
  initialValues: {
    name: '',
    description: '',
    prefix: '',
    additionalFields: [],
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().optional(),
    prefix: Yup.string().optional(),
    additionalFields: Yup.mixed().optional(),
  }),
};
