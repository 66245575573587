import A4 from 'PdfPages/pages/A4';
import { useEffect } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { GeneralFieldsValue } from 'PdfPages/components/GeneralFields';
import { searchSample } from 'actions/LaboratoryActions';
import './print.css';
import { ICustomer, ISample } from 'exactt-types/lib';
import moment from 'moment';

interface SampleAllPrintProps {
  setDisableScroll: (value: boolean) => void;
  searchSample: (
    laboratoryId: string,
    params: {
      page?: number;
      size?: number;
      query?: string;
      startDate?: string;
      endDate?: string;
      testGroup?: string;
      customer?: string;
      status?: string;
    }
  ) => Promise<any>;
  sampleList: ISample[] | [];
  laboratory: ILaboratoryDetails | null;
}

export function _OutwardPrint(props: SampleAllPrintProps) {
  let query = useQuery();

  const onFocus = async () => {
    props.searchSample(props.laboratory?.details?._id || '', {
      query: '',
      startDate: query.get('from') as string,
      endDate: query.get('to') as string,
      testGroup: query.get('group') as string,
    });
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
    // eslint-disable-next-line
  }, []);

  const values: GeneralFieldsValue[] = [];

  const startDate = moment(query.get('from')).format('DD/MM/YYYY');
  const endDate = moment(query.get('to')).format('DD/MM/YYYY');

  return (
    <A4
      header={
        <>
          {props.laboratory && (
            <>
              <h2 style={{ marginBottom: 2 }}>{props.laboratory.details.name}</h2>
              <div>
                Sample Exit Report for the period from {''}
                {startDate} to {endDate}
              </div>
              <div>Group Name: {query.get('groupName')}</div>
            </>
          )}
        </>
      }
      landscape={true}
      body={
        <>
          <table style={{ width: '100%', marginTop: 5 }}>
            <thead style={{ border: '1px solid black' }}>
              <tr>
                <th colSpan={7} style={{ borderTop: '1px solid black' }}></th>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: 'left',
                    width: '3%',
                  }}
                >
                  Sr. No
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '3%',
                  }}
                >
                  Date of Delivery
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                  }}
                >
                  Lab ID
                </th>
                <th
                  style={{
                    textAlign: 'left',

                    width: '8%',
                  }}
                >
                  Name of Customer
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                  }}
                >
                  Sample Name
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '10%',
                  }}
                >
                  Test Required
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                  }}
                >
                  Recieved Quantity
                </th>
              </tr>
              <tr>
                <th colSpan={7} style={{ borderTop: '1px solid black' }}></th>
              </tr>
            </thead>
            <tbody>
              {props.sampleList.map((sample, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{moment(sample?.createdAt).format('DD/MM/YYYY')}</td>
                  <td>{sample?.sampleId}</td>
                  <td>{(sample?.customer as ICustomer)?.name || 'N/A'}</td>
                  <td>{sample?.name || 'NA'}</td>
                  <td>
                    {sample?.parameters
                      ?.map((i) => i?.name || 'N/A')
                      .join(',')
                      .slice(0, 250)}
                  </td>
                  <td>{sample?.sampleQty}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
      footer={
        <div style={{ borderTop: '1px solid black' }}>
          <div id="page-number"></div>
        </div>
      }
    />
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleList: state.laboratory.samples,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchSample }, dispatch),
  };
}

export const OutwardPrint = connect(mapStateToProps, mapDispatchToProps)(_OutwardPrint);
