import { Box, Button, Checkbox, Grid, IconButton, TextField, Typography } from '@mui/material';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';

interface IAdditionalFieldsProps {
  value: { key: string; type: 'text'; required: boolean }[];
  laboratory: null | ILaboratoryDetails;
  onChange: (value: { key: string; type: 'text'; required: boolean }[]) => void;
}

function AdditionalFieldsTable(props: IAdditionalFieldsProps) {
  const deleteRow = (index: number) => {
    const updatedParameters = [...props.value];
    updatedParameters.splice(index, 1);
    props.onChange(updatedParameters);
  };

  return (
    <Box>
      <Box display={'flex'}>
        <Grid container flex={1} spacing={1}>
          <Grid item xs={5}>
            <Typography fontWeight={'500'} fontSize={12}>
              NAME
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography fontWeight={'500'} fontSize={12}>
              REQUIRED
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {props.value.map((field, index) => {
        return (
          <Box display={'flex'} key={index}>
            <Grid container flex={1} spacing={1}>
              <Grid item xs={5}>
                <TextField
                  autoFocus
                  value={field.key}
                  onChange={(e) => {
                    const value = e.target.value;
                    const updatedParameters = [...props.value];
                    updatedParameters[index].key = value;
                    props.onChange(updatedParameters);
                  }}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1} display={'flex'}>
                <Checkbox
                  checked={field.required}
                  onClick={() => {
                    const updatedParameters = [...props.value];
                    updatedParameters[index].required = !updatedParameters[index].required;
                    props.onChange(updatedParameters);
                  }}
                />
                <IconButton color="error" onClick={() => deleteRow(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <Box display={'flex'} justifyContent={'flex-end'} mt={1}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            const updatedParameters = [...props.value];
            updatedParameters.push({
              key: '',
              required: false,
              type: 'text',
            });
            props.onChange(updatedParameters);
          }}
        >
          Add Field
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFieldsTable);
