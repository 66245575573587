import { ISignupTranslation } from 'pages/Signup';

export const signupPageTranslations: ISignupTranslation = {
  getStartedLable: 'Let’s Get Started',
  signupSubTitle: 'Sign up for free. No credit card required.',
  signupTitle: 'Signup',
  usernameLabel: 'Email',
  nameLabel: 'Name',
  phoneLabel: 'Phone',
  countryLabel: 'Country',
  passwordLabel: 'Password',
  confirmPasswordLabel: 'Confirm Password',
  signupButtonText: 'Sign up',
};
