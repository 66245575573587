import { useCallback } from 'react';
import {
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { IBasicDetailsForm } from 'pages/CreateLaboratory/BasicDetailsForm';
import translations from 'utils/translations';
import { countryPhoneCodes } from 'utils/countryPhoneCodes';
import { useDropzone } from 'react-dropzone';
import { CommonServices } from 'services/Common';

const options = countryPhoneCodes.map((item) => ({ label: `${item.dial_code} ${item.name}`, value: item.dial_code }));

export function BasicDetailsForm(props: IBasicDetailsForm) {
  const { formik, onNext } = props;

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('logo', acceptedFiles[0]);
      const response = await CommonServices.mediaUploadService({ file: acceptedFiles[0] });
      if (response.status === 200) {
        formik.setFieldValue('logo', response.data.location);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onDropStamp = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      formik.setFieldValue('stampImage', acceptedFiles[0]);
      const response = await CommonServices.mediaUploadService({ file: acceptedFiles[0] });
      if (response.status === 200) {
        formik.setFieldValue('stampImage', response.data.location);
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const {
    getRootProps: getRootStampProps,
    getInputProps: getInputStampProps,
    isDragActive: isDragStampActive,
  } = useDropzone({ onDrop: onDropStamp });

  const isValid =
    formik.errors.logo ||
    formik.errors.prefix ||
    formik.errors.contactPerson ||
    formik.errors.name ||
    formik.errors.country ||
    formik.errors.phone ||
    formik.errors.stampImage ||
    formik.errors.email
      ? false
      : true;

  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        {formik.values.logo ? (
          <Box>
            <Typography>Logo</Typography>
            <img src={formik.values.logo} alt="laboratoy logo" style={{ height: 50, marginTop: 5, marginBottom: 20 }} />
            <Button
              onClick={() => {
                formik.setFieldValue('logo', '');
              }}
              color="error"
            >
              Remove
            </Button>
          </Box>
        ) : (
          <Box
            p={3}
            mb={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            {...getRootProps()}
            sx={{
              background: 'rgba(0,0,0,0.05)',
              borderRadius: 1,
              borderStyle: 'dashed',
              borderWidth: 2,
              borderColor: 'rgba(0,0,0,0.1)',
            }}
          >
            {' '}
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop logo here, or click to select logo</Typography>
            )}
          </Box>
        )}
        {formik.values.stampImage ? (
          <Box>
            <Typography>Stamp Image</Typography>
            <img src={formik.values.stampImage} alt="" style={{ height: 50, marginTop: 5, marginBottom: 20 }} />
            {/* remove button */}
            <Button
              onClick={() => {
                formik.setFieldValue('stampImage', '');
              }}
              color="error"
            >
              Remove
            </Button>
          </Box>
        ) : (
          <Box
            p={3}
            mb={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            {...getRootStampProps()}
            sx={{
              background: 'rgba(0,0,0,0.05)',
              borderRadius: 1,
              borderStyle: 'dashed',
              borderWidth: 2,
              borderColor: 'rgba(0,0,0,0.1)',
            }}
          >
            {' '}
            <input {...getInputStampProps()} />
            {isDragStampActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>Drag 'n' drop logo here, or click to select Stamp Image</Typography>
            )}
          </Box>
        )}

        <TextField
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          label={translations.pages.createLaboratoryPage.forms.basicDetails.labName}
          error={formik.touched.name && formik.errors.name ? true : false}
          helperText={formik.touched.name && formik.errors.name}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            name="prefix"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prefix}
            label={'Prefix'}
            error={formik.touched.prefix && formik.errors.prefix ? true : false}
            helperText={formik.touched.prefix && formik.errors.prefix}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" size="small" sx={{ width: '100%' }} required>
            <InputLabel id="Laboratory-type">Laboratory Type</InputLabel>
            <Select
              labelId="Laboratory-type"
              name="labType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.labType}
              error={formik.touched.labType && formik.errors.labType ? true : false}
              label={'Laboratory Type'}
            >
              <MenuItem value={'PHARMA'}>
                <em>Pharma</em>
              </MenuItem>
              <MenuItem value={'FOOD | WATER'}>
                <em>FOOD | WATER</em>
              </MenuItem>
            </Select>
            <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="contactPerson"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.contactPerson}
          label={translations.pages.createLaboratoryPage.forms.basicDetails.contactPerson}
          error={formik.touched.contactPerson && formik.errors.contactPerson ? true : false}
          helperText={formik.touched.contactPerson && formik.errors.contactPerson}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel id="country-select-label">
              {translations.pages.createLaboratoryPage.forms.basicDetails.code}
            </InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              name="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.country}
              label={translations.pages.createLaboratoryPage.forms.basicDetails.code}
              autoWidth
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options.map((item) => (
                <MenuItem value={item.value} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={8}>
          <TextField
            name={'phone'}
            type={'number'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.touched.phone && formik.errors.phone ? true : false}
            helperText={formik.touched.phone && formik.errors.phone}
            label={translations.pages.createLaboratoryPage.forms.basicDetails.phoneNumber}
            variant="outlined"
            size="small"
            fullWidth={true}
            required
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          label={translations.pages.createLaboratoryPage.forms.basicDetails.email}
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={formik.touched.email && formik.errors.email}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="website"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.website}
          label={translations.pages.createLaboratoryPage.forms.basicDetails.website}
          helperText={formik.touched.website && formik.errors.website}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mt={1}>
        <Button sx={{ marginRight: 1 }} variant={'contained'} disabled={!isValid} onClick={() => onNext()}>
          {translations.components.steps.nextButton}
        </Button>
      </Grid>
    </Grid>
  );
}
