import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import ITranslations from './ITranslations';
import en from './locale/en';

interface ILocalized extends LocalizedStringsMethods, ITranslations {}

let translations: ILocalized = new LocalizedStrings({
  en,
});

export default translations;
