import { Box, Button, TextField, Typography } from '@mui/material';
import Editor from 'components/Editor';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { editorTokens } from './editorTokens';
import Token from 'components/Token';
import { IReducer } from 'Reducers/IReducer';
import { createResultTemplate, updateResultTemplate, getResultTemplateById } from 'actions/LaboratoryActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IPersistTestResultTemplateProps } from './IPersistTestResultTemplate';
import useQuery from 'utils/getQueryParams';

export function _PersistTestResultTemplate(props: IPersistTestResultTemplateProps) {
  const query = useQuery();
  const resultTemplateId = query.get('id');

  const navigate = useNavigate();

  const headerQuillRef = useRef(null);
  const footerQuillRef = useRef(null);
  const bodyQuillRef = useRef(null);

  const [headerHtml, setHeaderHtml] = useState('');
  const [footerHtml, setFooterHtml] = useState('');
  const [bodyHtml, setBodyHtml] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Test Result Template',
      onClick: () => navigate('/laboratory/resultTemplate'),
    },
    {
      label: 'Create Test Result Template',
    },
  ];

  const tokensById: any = {};
  editorTokens.forEach((token) => {
    tokensById[token.id] = token;
  });

  useEffect(() => {
    if (resultTemplateId) {
      props.getResultTemplateById(props.laboratory?.details?._id || '', resultTemplateId).then((res) => {
        setHeaderHtml(res.header);
        setBodyHtml(res.body);
        setFooterHtml(res.footer);
        setName(res.name);
        setReload(true);
        setTimeout(() => {
          setReload(false);
        }, 2000);
      });
    } else {
      setTimeout(() => {
        setReload(false);
      }, 500);
    }
  }, []);

  console.log('reload -> ', reload);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Test Result Template" />
      <Box p={2} pt={10}>
        <Box display={'flex'} gap={1}>
          {!reload && (
            <Box flex={1}>
              <TextField
                label="Name of template"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                variant="outlined"
                fullWidth
                size="small"
              />
              <Typography variant="body1" mb={1}>
                Header
              </Typography>
              <Editor
                id={'header'}
                value={headerHtml}
                onChange={setHeaderHtml}
                quillRef={headerQuillRef}
                tokensById={tokensById}
              />
              <Typography variant="body1" mt={2} mb={1}>
                Body
              </Typography>
              <Editor
                id={'body'}
                value={bodyHtml}
                onChange={setBodyHtml}
                quillRef={bodyQuillRef}
                tokensById={tokensById}
              />
              <Typography variant="body1" mt={2} mb={1}>
                Footer
              </Typography>
              <Editor
                id={'footer'}
                value={footerHtml}
                onChange={setFooterHtml}
                quillRef={footerQuillRef}
                tokensById={tokensById}
              />
              <Box mt={2}>
                <Button
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true);
                    if (resultTemplateId) {
                      await props.updateResultTemplate(props.laboratory?.details?._id || '', {
                        _id: resultTemplateId,
                        name: name,
                        header: headerHtml,
                        body: bodyHtml,
                        footer: footerHtml,
                      });
                      navigate('/laboratory/resultTemplate');
                    } else {
                      await props.createResultTemplate(props.laboratory?.details?._id || '', {
                        name: name,
                        header: headerHtml,
                        body: bodyHtml,
                        footer: footerHtml,
                      });
                      navigate('/laboratory/resultTemplate');
                    }

                    setLoading(false);
                  }}
                  variant="contained"
                >
                  {loading ? 'Loading...' : resultTemplateId ? 'Update Template' : 'Create Template'}
                </Button>
              </Box>
            </Box>
          )}
          <Box width={300} />

          <Box
            width={290}
            p={1}
            borderRadius={1}
            right={10}
            bgcolor={'rgba(0,0,0,0.08)'}
            height={'70vh'}
            style={{ overflowX: 'scroll' }}
            mt={2}
            position={'absolute'}
          >
            <TextField
              label="Search"
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
              value={searchKeyword}
              variant="outlined"
              fullWidth
              size="small"
            />
            <ul className="token-list">
              {[...editorTokens]
                .filter((token) => token.title.toLowerCase().includes(searchKeyword.toLowerCase()))
                .map((tokenProps) => (
                  <Token {...tokenProps} key={tokenProps.id} inputs={[]} />
                ))}
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createResultTemplate, updateResultTemplate, getResultTemplateById }, dispatch),
  };
}

export const PersistTestResultTemplate = connect(mapStateToProps, mapDispatchToProps)(_PersistTestResultTemplate);
