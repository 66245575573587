import { Box } from '@mui/material';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { common } from 'constants/ImagesFiles';
import { ICustomer, ISample } from 'exactt-types/lib';
import moment from 'moment';
import QRCode from 'react-qr-code';

interface IGeneralHeaderProps {
  laboratory: ILaboratoryDetails | null;
  sample: ISample | null;
}

export default function GeneralHeader(props: IGeneralHeaderProps) {
  if (!props.laboratory || !props.sample) return null;

  const HeaderDetails = ({ label, value }: any) => {
    return (
      <Box display={'flex'} gap={1}>
        <Box width={'31mm'} fontWeight={'bold'}>
          {label}
        </Box>
        <Box flex={1}>{value}</Box>
      </Box>
    );
  };

  const customer = props.sample.customer as ICustomer;

  return (
    <div style={{ width: '100%' }}>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={common.nablLogo} width={'60mm'} alt="nabl" />
                <div>{props.laboratory.details.nabl}</div>
              </div>

              <div style={{ width: '100%' }}>
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: 10,
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginBottom: 10,
                  }}
                >
                  TEST RESULT
                </div>
                <Box display={'flex'}>
                  <Box display={'flex'} width={'80%'}>
                    <Box width={'33mm'} fontWeight={'bold'}>
                      Product Description:
                    </Box>
                    <Box>{props.sample.name}</Box>
                  </Box>
                  <div style={{ height: 'auto', margin: '0 auto', maxWidth: 32, width: '100%' }}>
                    <QRCode
                      size={256}
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={props.sample.sampleId as string}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Box flex={0.7}>
                    <HeaderDetails
                      label={'Report Issued To:'}
                      value={
                        <div>
                          {customer.name}
                          <br />
                          {customer?.address?.[0]?.line1}
                          <br />
                          {customer?.address?.[0]?.line2}
                          <br />
                          {customer?.address?.[0]?.city}, {customer?.address?.[0]?.state}-{customer?.address?.[0]?.zip}
                          <br />
                          Mobile No:- {customer.phone}
                        </div>
                      }
                    />
                  </Box>
                  <Box flex={0.3}>
                    {props.sample.ulr ? (
                      <Box display={'flex'}>
                        <Box fontWeight={'bold'}>ULR:</Box>
                        <Box>{props.sample.ulr}</Box>
                      </Box>
                    ) : null}
                    <Box display={'flex'} gap={1}>
                      <Box fontWeight={'bold'}>Issued Date:</Box>
                      <Box>{moment(props.sample.completeDate).format('DD/MM/YYYY')}</Box>
                    </Box>
                    <Box display={'flex'} gap={1}>
                      <Box fontWeight={'bold'}>Report No:</Box>
                      <Box>{props.sample.sampleId}</Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
