import actionTypes from 'constants/ActionTypes';
import { IDispatchType } from 'Reducers/IActionDispatch';
import { ILoginServiceRequest, AuthService, ISignupServiceRequest } from 'services/AuthService';
import showAlert from './showAlert';

export const login = (request: ILoginServiceRequest): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await AuthService.loginService(request);
      dispatch({
        type: actionTypes.auth.LOGIN,
        payload: {
          user: res.data,
          accessToken: res.data.accessToken,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const signup = (request: ISignupServiceRequest): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await AuthService.signupService(request);

      dispatch({
        type: actionTypes.auth.SIGNUP,
        payload: { user: res.data.details, accessToken: res.data.accessToken },
      });

      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      throw error;
    }
  };
};

export const getEnums = (): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await AuthService.enumsService();

      dispatch({
        type: actionTypes.system.GET_ENUMS,
        payload: { enums: res.data },
      });

      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const logout = (): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    dispatch({
      type: actionTypes.auth.LOGOUT,
      payload: null,
    });
  };
};
