import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { IDeleteDialog } from 'components/DeleteDialog';

export function DeleteDialog(props: IDeleteDialog) {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose} maxWidth={'sm'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={props.isLoading} onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button variant="contained" color="error" disabled={props.isLoading} onClick={() => props.onAction()}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
