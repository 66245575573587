import actionTypes from 'constants/ActionTypes';
import { IDispatchType } from 'Reducers/IActionDispatch';
import { ICreateCustomerRequest, CustomerServices } from 'services/CustomerService';
import showAlert from './showAlert';

export const addCustomer = (request: ICreateCustomerRequest): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.addCustomer(request);
      showAlert(dispatch, 'Customer added!', 'success');
      dispatch({
        type: actionTypes.customer.ADD_CUSTOMER,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      throw error;
    }
  };
};

export const updateCustomerById = (request: ICreateCustomerRequest): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.updateCustomerById(request);
      showAlert(dispatch, 'Customer updated!', 'success');
      dispatch({
        type: actionTypes.customer.UPDATE_CUSTOMER,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      throw error;
    }
  };
};

export const getCustomerList = (laboratoryId: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.getCustomerList(laboratoryId);
      dispatch({
        type: actionTypes.customer.GET_CUSTOMER_LIST,
        payload: { list: res.data.data },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getCustomerById = (laboratoryId: string, id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.getCustomerById(laboratoryId, id);
      dispatch({
        type: actionTypes.customer.GET_CUSTOMER_BY_ID,
        payload: { data: res.data },
      });
      return res.data.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const deleteCustomerById = (laboratoryId: string, id: string): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.deleteCustomerById(laboratoryId, id);
      dispatch({
        type: actionTypes.customer.GET_CUSTOMER_BY_ID,
        payload: { data: res.data },
      });
      return res.data.data;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const searchCustomer = (laboratoryId: string, params: { page: number; size: number; query: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await CustomerServices.searchCustomer(laboratoryId, params);
      dispatch({
        type: actionTypes.customer.SEARCH_CUSTOMER,
        payload: { list: res.data.customers, count: res.data.totalPages },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
