import { Box, Button, Stack, TextField } from '@mui/material';
import { IHeader } from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { getPriceList, deletePriceList } from 'actions/LaboratoryActions';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { useEffect, useState } from 'react';
import { DeleteDialog } from 'components/DeleteDialog';
import { IPriceListProps } from './IPriceList';
import { PriceListTable } from './PriceListTable';

function _PriceList(props: IPriceListProps) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const laboratoryId = props.laboratory?.details._id || '';

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Price List',
      onClick: () => navigate('/laboratory/price-list'),
    },
  ];

  const onFocus = async () => {
    await props.getPriceList(laboratoryId);
  };

  const handleDeleteReference = () => {
    try {
      setDeleteLoading(true);
      setDeleteModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      onFocus();
    }
  };

  useEffect(() => {
    props.getPriceList(laboratoryId);
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Price List"
        right={
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <TextField
              label={'Search'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => navigate('/laboratory/price-list/persist')}
            >
              Add Price List
            </Button>
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <PriceListTable
          data={props.priceList || []}
          onDelete={() => {}}
          onEdit={(priceList) => {
            navigate(`/laboratory/price-list/persist?id=${priceList._id}`);
          }}
        />
      </Box>
      <DeleteDialog
        title="Delete Reference"
        message={`Are you sure you want to remove`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => handleDeleteReference()}
        isLoading={deleteLoading}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    priceList: state.laboratory.priceList,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ getPriceList, deletePriceList }, dispatch),
  };
}

export const PriceList = connect(mapStateToProps, mapDispatchToProps)(_PriceList);
