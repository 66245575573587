import actionTypes from 'constants/ActionTypes';
import { IActionInterface } from 'Reducers/IActionDispatch';
import { IAuthReducer } from './IAuthReducer';

const initialState: IAuthReducer = {
  user: null,
  accessToken: null,
};

export const AuthReducer = (state: IAuthReducer = initialState, action: IActionInterface): IAuthReducer => {
  switch (action.type) {
    case actionTypes.auth.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };

    case actionTypes.auth.SIGNUP:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
      };

    case actionTypes.auth.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
