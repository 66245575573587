import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, Link } from '@mui/material';
import { ICustomerTabel } from 'pages/Customer/CustomerTable';
import { useNavigate } from 'react-router-dom';

export default function _CustomerTable(props: ICustomerTabel) {
  const navigate = useNavigate();

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Customer Name</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>GST</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {props.data.map((customerDetails) => (
            <TableRow key={customerDetails._id}>
              <TableCell>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/laboratory/customers/${customerDetails._id}`);
                  }}
                >
                  {customerDetails.name}
                </Link>
              </TableCell>
              <TableCell>{customerDetails.contactDetails[0].name || 'N/A'}</TableCell>
              <TableCell>
                {customerDetails.contactDetails && customerDetails.contactDetails[0]
                  ? `${customerDetails.contactDetails[0].country}-${customerDetails.phone}`
                  : 'N/A'}
              </TableCell>
              <TableCell>{customerDetails.email || 'N/A'}</TableCell>
              <TableCell>{customerDetails.gst || 'N/A'}</TableCell>
              <TableCell align="right">
                <Button onClick={() => props.onEdit(customerDetails)}>Edit</Button>
                <Button color="error" onClick={() => props.onDelete(customerDetails)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  department: state.laboratory.current?.departments ?? [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const CustomerTable = connect(mapStateToProps, mapDispatchToProps)(_CustomerTable);
