import { Box } from '@mui/material';
import { getSampleById } from 'actions/LaboratoryActions';
// import GeneralPwdHeader from 'PdfPages/components/PwdHeader';

// import GeneralPwdFooter from 'PdfPages/components/PwdFooter';

import A4 from 'PdfPages/pages/A4';
import { useEffect } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { ICustomer, ISample } from 'exactt-types/lib';
import GeneralFields, { GeneralFieldsValue } from 'PdfPages/components/GeneralFields';
import DocumentLatterHead from 'PdfPages/components/DocumentLatterHead';
import moment from 'moment';
import QRCode from 'react-qr-code';

interface SampleJobRequestProps {
  setDisableScroll: (value: boolean) => void;
  getSampleById: (laboratoryId: string, _id: string) => Promise<any>;
  laboratory: ILaboratoryDetails | null;
  sampleDetails: ISample | null;
}

export function _SampleJobRequest(props: SampleJobRequestProps) {
  let query = useQuery();
  let sampleId = query.get('id');

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(props.laboratory?.details._id || '', sampleId);
      setTimeout(() => {
        // window.print();
      }, 1000 * 5);
      // setLoading(false);
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();

    // eslint-disable-next-line
  }, []);

  const values: GeneralFieldsValue[] = [];

  if (props.sampleDetails) {
    values.push({ label: 'Party Name', value: (props.sampleDetails.customer as ICustomer)?.name || '' });
    values.push({ label: 'Lab Code', value: props.sampleDetails.sampleId || '' });
    values.push({
      label: 'Party Address',
      value: `${(props.sampleDetails.customer as ICustomer)?.address?.[0]?.line1 || ''} \n 
      ${(props.sampleDetails.customer as ICustomer)?.address?.[0]?.line2 || ''} \n
      ${(props.sampleDetails.customer as ICustomer)?.address?.[0]?.city || ''} \n
      ${(props.sampleDetails.customer as ICustomer)?.address?.[0]?.state || ''} \n
      ${(props.sampleDetails.customer as ICustomer)?.address?.[0]?.zip || ''} \n
      `,
    });
    values.push({
      label: 'Date',
      value: moment(props.sampleDetails.collectionDate).format('DD-MM-YYYY') || '',
    });
    values.push({ label: 'Commodity', value: props.sampleDetails.name });
    values.push({ label: 'Batch No', value: props.sampleDetails.batchNo || '' });
    values.push({ label: 'Sample Code', value: props.sampleDetails.sampleCode || '' });
    values.push({ label: 'DOM', value: moment(props.sampleDetails.mfgDate).format('DD-MM-YYYY') || '' });
    values.push({ label: 'Brand', value: props.sampleDetails.brandName || '' });
    values.push({ label: 'Qty', value: props.sampleDetails.sampleQty || '' });
  }

  return (
    <Box>
      <A4
        header={<DocumentLatterHead laboratory={props.laboratory} />}
        body={
          <>
            <Box border={'1px solid #000'} p={2} style={{ marginTop: -5 }}>
              <Box textAlign={'center'} mb={1} fontWeight={'bold'}>
                TEST REQUEST
              </Box>
              <GeneralFields values={values} labelWidth={85} />
              <Box display={'flex'} justifyContent={'flex-end'}>
                <div>
                  <div style={{ height: 'auto', margin: '0 auto', maxWidth: 32, width: '100%' }}>
                    <QRCode
                      size={256}
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={props.sampleDetails?.sampleId as string}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </Box>
            </Box>
            <Box className="table-with-border">
              <table style={{ width: '100%', marginTop: -1 }}>
                <thead>
                  <tr>
                    <th style={{ width: '6%' }}>Sr. No</th>
                    <th style={{ width: '94%' }}>Test Parameters</th>
                  </tr>
                </thead>
                <tbody>
                  {props.sampleDetails?.parameters?.map((i, index) => (
                    <tr>
                      <td style={{ padding: 4 }}>{index + 1}.</td>
                      <td style={{ padding: 4 }}>{i.name}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2} style={{ padding: 14 }}>
                      <Box display={'flex'} width="100%" fontWeight={'bold'}>
                        <Box textAlign={'center'} flex={0.5} mt={6}>
                          <div>......................................</div>
                          <div>Sign (Customer Representative), if any</div>
                        </Box>
                        <Box textAlign={'center'} flex={0.5} mt={6}>
                          <div>......................................</div>
                          <div>Sign (Lab Representative)</div>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: '100%', marginTop: -1 }}>
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>REVIEWED BY</th>
                    <th style={{ width: '75%' }}>SAMPLE ALLOTTED TO</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ alignContent: 'end' }}>
                      <Box textAlign={'center'}>
                        <div></div>
                        <div>......................................</div>
                        <div>
                          <strong>Authorized Sign</strong>
                        </div>
                      </Box>
                    </td>
                    <td>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Box display={'flex'} fontWeight={'bold'} mb={3}>
                          <Box flex={0.16}>CHEM.</Box>
                          <Box flex={0.16}>1)</Box>
                          <Box flex={0.16}>2)</Box>
                          <Box flex={0.16}>3)</Box>
                          <Box flex={0.16}>4)</Box>
                          <Box flex={0.16}>5)</Box>
                        </Box>
                        <Box display={'flex'} fontWeight={'bold'} mb={3}>
                          <Box flex={0.16}>BIO.</Box>
                          <Box flex={0.16}>1)</Box>
                          <Box flex={0.16}>2)</Box>
                          <Box flex={0.16}>3)</Box>
                        </Box>
                        <Box display={'flex'} fontWeight={'bold'}>
                          <Box flex={0.16}>MEC.</Box>
                          <Box flex={0.16}>1)</Box>
                          <Box flex={0.16}>2)</Box>
                          <Box flex={0.16}>3)</Box>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </>
        }
        footer={<></>}
        // finalFooter={<GeneralFinalFooter />}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
      },
      dispatch
    ),
  };
}

export const SampleJobRequestPrint = connect(mapStateToProps, mapDispatchToProps)(_SampleJobRequest);
