import * as Yup from 'yup';

export const createLaboratorySchema = {
  initialValues: {
    name: '',
    logo: '',
    prefix: '',
    contactPerson: '',
    country: '+91',
    labType: 'FOOD-WATER',
    phone: '',
    email: '',
    website: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    gst: '',
    pan: '',
    nabl: '',
    drugLicenceNumber: '',
    bank: '',
    branch: '',
    accountName: '',
    accountNumber: '',
    ifscCode: '',
    otherLicenceNumber: '',
    type: 'FOOD_AND_WATER',
    stampImage: '',
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Please provide laboratory name'),
    logo: Yup.string().required('Please provide laboratory logo'),
    contactPerson: Yup.string().required('Please provide contact person name'),
    country: Yup.string().required('Please provide country'),
    phone: Yup.string().required('Please provide phone'),
    email: Yup.string().email('Please enter correct email').required('Please provide email'),
    website: Yup.string(),
    line1: Yup.string().required('Please provide address line 1'),
    line2: Yup.string().required('Please provide address line 2'),
    city: Yup.string().required('Please provide city'),
    state: Yup.string().required('Please provide state'),
    zip: Yup.string().required('Please provide zip'),
    gst: Yup.string().required('Please provide GST'),
    pan: Yup.string().required('Please provide PAN'),
    nabl: Yup.string(),
    otherLicenceNumber: Yup.string(),
    type: Yup.string().required('Please provice type'),
    bank: Yup.string().required('Please provide bank name'),
    branch: Yup.string().required('Please provide bank branch'),
    accountName: Yup.string().required('Please provide account name'),
    accountNumber: Yup.string().required('Please provide account number'),
    ifscCode: Yup.string().required('Please provide IFSC Code'),
    stampImage: Yup.string().required('Please provide Stamp Image'),
  }),
};
