import { Grid, TextField, Button } from '@mui/material';
import { ILocationDetailsForm } from 'pages/CreateLaboratory/LocationDetailsForm';
import translations from 'utils/translations';

export function LocationDetailsForm(props: ILocationDetailsForm) {
  const { formik, onBack, onNext } = props;
  const isValid =
    formik.errors.line1 || formik.errors.line2 || formik.errors.city || formik.errors.state || formik.errors.zip
      ? false
      : true;
  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        <TextField
          name="line1"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.line1}
          label={translations.pages.createLaboratoryPage.forms.locationDetails.addressLine1}
          error={formik.touched.line1 && formik.errors.line1 ? true : false}
          helperText={formik.touched.line1 && formik.errors.line1}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="line2"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.line2}
          label={translations.pages.createLaboratoryPage.forms.locationDetails.addressLine2}
          error={formik.touched.line2 && formik.errors.line2 ? true : false}
          helperText={formik.touched.line2 && formik.errors.line2}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="city"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
          label={translations.pages.createLaboratoryPage.forms.locationDetails.city}
          error={formik.touched.city && formik.errors.city ? true : false}
          helperText={formik.touched.city && formik.errors.city}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            label={translations.pages.createLaboratoryPage.forms.locationDetails.state}
            error={formik.touched.state && formik.errors.state ? true : false}
            helperText={formik.touched.state && formik.errors.state}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="zip"
            type={'number'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zip}
            label={translations.pages.createLaboratoryPage.forms.locationDetails.zipCode}
            error={formik.touched.zip && formik.errors.zip ? true : false}
            helperText={formik.touched.zip && formik.errors.zip}
            variant="outlined"
            size="small"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display={'flex'} flexDirection={'row-reverse'} mt={1}>
        <Button
          sx={{ marginRight: 1 }}
          variant={'contained'}
          disabled={!isValid}
          onClick={() => {
            onNext();
          }}
        >
          {translations.components.steps.nextButton}
        </Button>
        <Button sx={{ marginRight: 1 }} onClick={() => onBack()}>
          {translations.components.steps.backButton}
        </Button>
      </Grid>
    </Grid>
  );
}
