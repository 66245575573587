import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IFormikSampleFood, IPersistSampleKSZProps } from 'pages/Sample/PersistSampleKSZ';
import { searchCustomer } from 'actions/CustomerActions';
import {
  searchMasterList,
  searchTestGroupList,
  createSample,
  getSampleById,
  updateSample,
  searchReference,
} from 'actions/LaboratoryActions';
import { getUserList } from 'actions/UserAction';
import { SearchDialog } from 'components/SearchDialog';
import { ICustomer, IMaster, IReference, ITestGroup, IUser } from 'exactt-types/lib';
import { useFormik } from 'formik';
import useQuery from 'utils/getQueryParams';
import moment from 'moment';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import { createSampleSchema } from 'FormSchema/CreateSampleSchema';
import { handleChangeWhiteSpace } from 'utils/removeWhiteSpaces';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

export default function _PersistSampleKSZ(props: IPersistSampleKSZProps) {
  const navigate = useNavigate();
  let query = useQuery();
  let sampleId = query.get('id');
  const laboratoryId = props.laboratory?.details?._id || '';

  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [referenceSearchQuery, setReferenceSearchQuery] = useState('');
  const [referenceSearchModal, setReferenceSearchModal] = useState(false);
  const [selectedReference, setSelectedReference] = useState<IReference | null>(null);
  const [masterSearchQuery, setMasterSearchQuery] = useState('');
  const [masterSearchModal, setMasterSearchModal] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState<IMaster | null>(null);
  const [testGroupSearchQuery, setTestGroupSearchQuery] = useState('');
  const [testGroupSearchModal, setTestGroupSearchModal] = useState(false);
  const [selectedTestGroup, setSelectedTestGroup] = useState<ITestGroup | null>(null);

  const formik: IFormikSampleFood = useFormik({
    initialValues: createSampleSchema.initialValues,
    validationSchema: createSampleSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (sampleId) {
          await props.updateSample({
            ...values,
            laboratoryId,
            _id: sampleId,
            master: selectedMaster?._id || '',
            formType: 'FOOD',
            retainDate: new Date(),
            retainBy: '',
            sampleReceivedBy: values.sampleReceivedMode || '',
            discordedOn: new Date(),
            discordedBy: '',
            dispatchMode: '',
          });
          navigate('/laboratory/samples/sampleDetails?id=' + sampleId);
        } else {
          await props.createSample({
            ...values,
            laboratoryId,
            master: selectedMaster?._id || '',
            formType: 'FOOD',
            retainDate: new Date(),
            retainBy: '',
            discordedOn: new Date(),
            discordedBy: '',
            sampleReceivedBy: '',
            sampleReceivedMode: values.sampleReceivedMode || '',
            dispatchMode: '',
          });
          navigate('/laboratory/samples');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  console.log('formik -> ', formik.errors);

  const handleChange = (event: any) => {
    const { value } = event.target;
    const length = event.target.value.length;
    if (value[length - 1] === ' ' && value[length - 2] === ' ') {
      event.target.value = handleChangeWhiteSpace(value);
    }
    formik.handleChange(event);
  };

  useEffect(() => {
    if (props.sampleDetails && sampleId) {
      setSelectedCustomer(props.sampleDetails.customer as ICustomer);
      setSelectedTestGroup(props.sampleDetails.testGroup as ITestGroup);
      setSelectedMaster(props.sampleDetails.master as IMaster);

      formik.setValues({
        // !dates are set to new Date() because of the error in date format
        batchNo: props.sampleDetails.batchNo || '',
        batchSize: props.sampleDetails.batchSize || '',
        brandName: props.sampleDetails.brandName || '',
        collectionBy: (props.sampleDetails.collectionBy as any) || '',
        collectionDate: props.sampleDetails.collectionDate || new Date(),
        description: props.sampleDetails.description || '',
        discount: props.sampleDetails.discount || 0,
        dueDate: props.sampleDetails.dueDate || new Date(),
        drugLicNo: props.sampleDetails.drugLicNo || '',
        envCondition: props.sampleDetails.envCondition || '',
        expDate: props.sampleDetails.expDate || new Date(),
        gst: props.sampleDetails.gst || 0,
        hod: (props.sampleDetails.hod as IUser)?._id || '',
        igst: props.sampleDetails.igst || false,
        labDueDate: props.sampleDetails.labDueDate || new Date(),
        mfgDate: props.sampleDetails.mfgDate || new Date(),
        sampleReceivedBy: props.sampleDetails.sampleReceivedBy || '',
        sampleReceivedMode: props.sampleDetails.sampleReceivedMode || '',
        otherInformation: props.sampleDetails.otherInformation || '',
        name: props.sampleDetails.name,
        paidAmount: props.sampleDetails.paidAmount || 0,
        // @ts-expect-error
        parameters: props.sampleDetails.parameters?.map((i) => ({ ...i, department: i.department._id })),
        rate: props.sampleDetails.rate || 0,
        sac: props.sampleDetails.sac || '',
        sampleCode: props.sampleDetails.sampleCode || '',
        sampleCondition: props.sampleDetails.sampleCondition || '',
        samplePacking: props.sampleDetails.samplePacking || '',
        sampleQty: props.sampleDetails.sampleQty || '',
        samplingMethod: props.sampleDetails.samplingMethod || '',
        servingSize: props.sampleDetails.servingSize || '',
        supplier: props.sampleDetails.supplier || '',
        testGroup: (props.sampleDetails.testGroup as any)?._id,
        type: props.sampleDetails.type || '',
        additionalFields: props.sampleDetails.additionalFields || {},
        customer: (props.sampleDetails.customer as any)._id || '',
        reference: (props.sampleDetails.reference as any)?._id || '',
        genericName: props.sampleDetails.genericName,
        manufacture: props.sampleDetails.manufacture || '',
        marking: props.sampleDetails.marking || '',
        paymentDueDate: props.sampleDetails.paymentDueDate || new Date(),
        reviewedBy: (props.sampleDetails.reviewedBy as any)?._id || '',
        authorizedSignature1: (props.sampleDetails.authorizedSignature1 as IUser)?._id || '',
        authorizedSignature2: (props.sampleDetails.authorizedSignature2 as IUser)?._id || '',
        nutritionalInformation: props.sampleDetails.nutritionalInformation || '',
        vagNonVegDetails: props.sampleDetails.vagNonVegDetails || '',
        nameAndAddressOfImporter: props.sampleDetails.nameAndAddressOfImporter || '',
        countryOfOrigin: props.sampleDetails.countryOfOrigin || '',
        testMethod: props.sampleDetails.testMethod || '',
        nameOfPlantSection: props.sampleDetails.nameOfPlantSection || '',
        purposeOfMonitoring: props.sampleDetails.purposeOfMonitoring || '',
        emissionSourceMonitor: props.sampleDetails.emissionSourceMonitor || '',
        productManufacturedSamplingTime: props.sampleDetails.productManufacturedSamplingTime || '',
        nameOfAuthorizedPerson: props.sampleDetails.nameOfAuthorizedPerson || '',
        controlMeasures: props.sampleDetails.controlMeasures || '',
        authorizedPersonAddress: props.sampleDetails.authorizedPersonAddress || '',
        recoveryOfMaterial: props.sampleDetails.recoveryOfMaterial || '',
        sealedCoverByThe: props.sampleDetails.sealedCoverByThe || '',
        sealOnContainerBISSeal: props.sampleDetails.sealOnContainerBISSeal || '',
        generalSensory: props.sampleDetails.generalSensory || '',
        CHACardHolder: props.sampleDetails.CHACardHolder || '',
        CHSRegnNo: props.sampleDetails.CHSRegnNo || '',
        fugitiveEmission: props.sampleDetails.fugitiveEmission || '',
        stackTemp: props.sampleDetails.stackTemp || '',
        stackVelocity: props.sampleDetails.stackVelocity || '',
        sampleLocation: props.sampleDetails.sampleLocation || '',
        dateAndPlaceOfCollectionOfSample: props.sampleDetails.dateAndPlaceOfCollectionOfSample || '',
        ambientTemperature: props.sampleDetails.ambientTemperature || '',
        srNo: props.sampleDetails.srNo || '',
        sealsFixedNo: props.sampleDetails.sealsFixedNo || '',
        conformsOrNon: props.sampleDetails.conformsOrNon || '',
        serviceMode: props.sampleDetails.serviceMode || '',
        foundTheSampleToBe: props.sampleDetails.foundTheSampleToBe || '',
        sampleStatus: props.sampleDetails.sampleStatus || '',
        statusReasons: props.sampleDetails.statusReasons || '',

        // ! new added fields
        regulationNo: props.sampleDetails.regulationNo || '',
        beNo: props.sampleDetails.beNo || '',
        sealOnContainer: props.sampleDetails.sealOnContainer || '',
        dateOfDispatch: props.sampleDetails.dateOfDispatch || new Date(),
        testMemoAndDate: props.sampleDetails.testMemoAndDate || '',
        sampleDescription: props.sampleDetails.sampleDescription || '',
        physicalAppearance: props.sampleDetails.physicalAppearance || '',
        label: props.sampleDetails.label || '',
        fassiLogoAndLicenseNo: props.sampleDetails.fassiLogoAndLicenseNo || '',
        listOfIngratiation: props.sampleDetails.listOfIngratiation || '',
        parametersMonitors: props.sampleDetails.parametersMonitors || '',
      });
    }
    // eslint-disable-next-line
  }, [props.sampleDetails]);

  useEffect(() => {
    props.searchCustomer(laboratoryId, { page: 1, size: 10, query: customerSearchQuery });
    // eslint-disable-next-line
  }, [customerSearchQuery]);

  useEffect(() => {
    props.searchMasterList(laboratoryId, { page: 1, size: 10, query: masterSearchQuery });
    // eslint-disable-next-line
  }, [masterSearchQuery]);

  useEffect(() => {
    props.searchTestGroupList(laboratoryId, { page: 1, size: 10, query: testGroupSearchQuery });
    // eslint-disable-next-line
  }, [testGroupSearchQuery]);

  useEffect(() => {
    props.searchReference(laboratoryId, { page: 1, size: 10, query: referenceSearchQuery });
    // eslint-disable-next-line
  }, [referenceSearchQuery]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Samples',
      onClick: () => navigate('/laboratory/samples'),
    },
  ];

  const promiseCustomerOptions = async (inputValue: string) => {
    const list = await props.searchCustomer(laboratoryId, { page: 1, size: 10, query: inputValue });
    console.log('list -> ', list.data.customers);

    return list.data.customers.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const promiseReferenceOptions = async (inputValue: string) => {
    const list = await props.searchReference(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.references.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const promiseTestGroupOptions = async (inputValue: string) => {
    const list = await props.searchTestGroupList(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.testGroups.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const handleSelectAllParameter = () => {
    if (selectedMaster) {
      const parameters = selectedMaster?.parameters;
      if (parameters?.length) {
        formik.setFieldValue('parameters', parameters);
      }
    } else {
      if (formik.values.parameters.length === 0) {
        formik.setFieldError('parameters', 'Please select a master');
      }
    }
  };
  const gstAmount = formik.values.rate * (formik.values.gst / 100);
  const totalRate = formik.values.rate + gstAmount;
  const discountAmount = totalRate * (formik.values.discount / 100);
  const total = totalRate - discountAmount;
  const remainingTotal = total - formik.values.paidAmount;

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Sample Entry" />

      <Box p={2} pt={10}>
        <Grid container spacing={1} mt={1}>
          <Grid item xs={6}>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Basic Details
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Booking Party">
                    <AsyncSelect
                      loadOptions={promiseCustomerOptions}
                      value={{ value: selectedCustomer?._id, label: selectedCustomer?.name }}
                      defaultOptions={props.searchCustomerList.map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const findCustomer = props.searchCustomerList.find((i) => i._id === newValue?.value);
                        if (findCustomer) setSelectedCustomer(findCustomer);
                        formik.setFieldValue('customer', newValue?.value || '');
                      }}
                    />
                  </CustomFieldWithLabel>
                  {formik.touched.customer && formik.errors.customer}
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1}>
                <Grid item xs={12}>
                  {/* <CustomFieldWithLabel width={120} label="Reference by">
                    <AsyncSelect
                      loadOptions={promiseReferenceOptions}
                      value={{ value: selectedReference?._id, label: selectedReference?.name }}
                      defaultOptions={(props.referenceList || []).map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const findRef = (props.referenceList || []).find((i) => i._id === newValue?.value);
                        if (findRef) setSelectedReference(findRef);
                        formik.setFieldValue('reference', newValue?.value || '');
                      }}
                    />
                  </CustomFieldWithLabel>
                  {formik.touched.reference && formik.errors.reference} */}
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1} style={{ display: 'flex' }}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Test Group">
                    <AsyncSelect
                      loadOptions={promiseTestGroupOptions}
                      value={{ value: selectedTestGroup?._id, label: selectedTestGroup?.name }}
                      defaultOptions={(props.testGroupsList || []).map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const testGroup = (props.testGroupsList || []).find((i) => i._id === newValue?.value);
                        if (testGroup) setSelectedTestGroup(testGroup);
                        formik.setFieldValue('testGroup', newValue?.value || '');
                      }}
                    />
                    {/* <TextField
                      fullWidth
                      margin="none"
                      required
                      disabled={!!sampleId}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setTestGroupSearchModal(true);
                        }
                      }}
                      value={selectedTestGroup?.name || ''}
                      error={formik.touched.testGroup && formik.errors.testGroup ? true : false}
                      helperText={formik.touched.testGroup && formik.errors.testGroup}
                    /> */}
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Receipt date">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.collectionDate}
                      error={formik.touched.collectionDate && formik.errors.collectionDate ? true : false}
                      helperText={formik.touched.collectionDate && formik.errors.collectionDate}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        // disabled={true}
                        onChange={(value) => {
                          formik.setFieldValue('collectionDate', moment(value).toDate());
                          formik.setFieldTouched('collectionDate', true);
                        }}
                        value={formik.values.collectionDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            margin="none"
                            error={formik.touched.collectionDate && formik.errors.collectionDate ? true : false}
                            helperText={formik.touched.collectionDate && formik.errors.collectionDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>

                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Due date">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.dueDate}
                      error={formik.touched.dueDate && formik.errors.dueDate ? true : false}
                      helperText={formik.touched.dueDate && formik.errors.dueDate}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(value) => {
                          formik.setFieldValue('dueDate', moment(value).toDate());
                          formik.setFieldTouched('dueDate', true);
                        }}
                        value={formik.values.dueDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            margin="none"
                            fullWidth
                            error={formik.touched.dueDate && formik.errors.dueDate ? true : false}
                            helperText={formik.touched.dueDate && formik.errors.dueDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel width={120} label="Lab due Date">
                  <LocalizationProvider
                    dateAdapter={DateAdapter}
                    value={formik.values.labDueDate}
                    error={formik.touched.labDueDate && formik.errors.labDueDate ? true : false}
                    helperText={formik.touched.labDueDate && formik.errors.labDueDate}
                  >
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={(value) => {
                        formik.setFieldValue('labDueD.labDueDate', moment(value).toDate());
                        formik.setFieldTouched('labDueDate', true);
                      }}
                      value={formik.values.labDueDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="none"
                          error={formik.touched.labDueDate && formik.errors.labDueDate ? true : false}
                          helperText={formik.touched.labDueDate && formik.errors.labDueDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase', marginTop: '1%' }} fontSize={14}>
                  Sample Details
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Sample Name">
                    <TextField
                      fullWidth
                      required
                      disabled={!!sampleId}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setMasterSearchModal(true);
                        }
                      }}
                      name="name"
                      onChange={handleChange}
                      value={formik.values.name || ''}
                      error={formik.touched.name && formik.errors.name ? true : false}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Generic Name">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      value={formik.values.genericName || ''}
                      error={formik.touched.genericName && formik.errors.genericName ? true : false}
                      helperText={formik.touched.genericName && formik.errors.genericName}
                      onChange={handleChange}
                      name={'genericName'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1} style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="BE No">
                    <TextField
                      margin="none"
                      fullWidth
                      sx={{ padding: '0px !important' }}
                      value={formik.values.beNo}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.beNo && formik.errors.beNo ? true : false}
                      helperText={formik.touched.beNo && formik.errors.beNo}
                      name={'beNo'}
                    />
                  </CustomFieldWithLabel>
                </Grid>

                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Seal on Container">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.sealOnContainer}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.sealOnContainer && formik.errors.sealOnContainer ? true : false}
                      helperText={formik.touched.sealOnContainer && formik.errors.sealOnContainer}
                      name={'sealOnContainer'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid gap={1} item xs={12} style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Date of Dispatch">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.dateOfDispatch}
                      error={formik.touched.dateOfDispatch && formik.errors.dateOfDispatch ? true : false}
                      helperText={formik.touched.dateOfDispatch && formik.errors.dateOfDispatch}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(value) => {
                          formik.setFieldValue('dateOfDispatch', moment(value).toDate());
                          formik.setFieldTouched('dateOfDispatch', true);
                        }}
                        value={formik.values.dateOfDispatch}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            margin="none"
                            fullWidth
                            error={formik.touched.dateOfDispatch && formik.errors.dateOfDispatch ? true : false}
                            helperText={formik.touched.dateOfDispatch && formik.errors.dateOfDispatch}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>

                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Test memo No & Date">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.testMemoAndDate}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.testMemoAndDate && formik.errors.testMemoAndDate ? true : false}
                      helperText={formik.touched.testMemoAndDate && formik.errors.testMemoAndDate}
                      name={'testMemoAndDate'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Type of sample">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.type}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.type && formik.errors.type ? true : false}
                      helperText={formik.touched.type && formik.errors.type}
                      name={'type'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Regulation No">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.regulationNo}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.regulationNo && formik.errors.regulationNo ? true : false}
                      helperText={formik.touched.regulationNo && formik.errors.regulationNo}
                      name={'regulationNo'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Sample Description">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.sampleDescription}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.sampleDescription && formik.errors.sampleDescription ? true : false}
                      helperText={formik.touched.sampleDescription && formik.errors.sampleDescription}
                      name={'sampleDescription'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Physical Appearance">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.physicalAppearance}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.physicalAppearance && formik.errors.physicalAppearance ? true : false}
                      helperText={formik.touched.physicalAppearance && formik.errors.physicalAppearance}
                      name={'physicalAppearance'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Label">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.label}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.label && formik.errors.label ? true : false}
                      helperText={formik.touched.label && formik.errors.label}
                      name={'label'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="FASSI Logo & License No">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.fassiLogoAndLicenseNo}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.fassiLogoAndLicenseNo && formik.errors.fassiLogoAndLicenseNo ? true : false}
                      helperText={formik.touched.fassiLogoAndLicenseNo && formik.errors.fassiLogoAndLicenseNo}
                      name={'fassiLogoAndLicenseNo'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="List Of Ingredient">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.listOfIngratiation}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.listOfIngratiation && formik.errors.listOfIngratiation ? true : false}
                      helperText={formik.touched.listOfIngratiation && formik.errors.listOfIngratiation}
                      name={'listOfIngratiation'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Nutritional Information">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.nutritionalInformation}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.nutritionalInformation && formik.errors.nutritionalInformation ? true : false
                      }
                      helperText={formik.touched.nutritionalInformation && formik.errors.nutritionalInformation}
                      name={'nutritionalInformation'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Veg Non-Veg Details">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.vagNonVegDetails}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.vagNonVegDetails && formik.errors.vagNonVegDetails ? true : false}
                      helperText={formik.touched.vagNonVegDetails && formik.errors.vagNonVegDetails}
                      name={'vagNonVegDetails'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Sample Packing">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.samplePacking}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.samplePacking && formik.errors.samplePacking ? true : false}
                      helperText={formik.touched.samplePacking && formik.errors.samplePacking}
                      name={'samplePacking'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Name And Address Of Importer">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.nameAndAddressOfImporter}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.nameAndAddressOfImporter && formik.errors.nameAndAddressOfImporter ? true : false
                      }
                      helperText={formik.touched.nameAndAddressOfImporter && formik.errors.nameAndAddressOfImporter}
                      name={'nameAndAddressOfImporter'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Sample Qty">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.sampleQty}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.sampleQty && formik.errors.sampleQty ? true : false}
                      helperText={formik.touched.sampleQty && formik.errors.sampleQty}
                      name={'sampleQty'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Sample Code/Batch">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.sampleCode}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.sampleCode && formik.errors.sampleCode ? true : false}
                      helperText={formik.touched.sampleCode && formik.errors.sampleCode}
                      name={'sampleCode'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Mfg Date">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.mfgDate}
                      error={formik.touched.mfgDate && formik.errors.mfgDate ? true : false}
                      helperText={formik.touched.mfgDate && formik.errors.mfgDate}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(value) => {
                          formik.setFieldValue('mfgDate', moment(value).toDate());
                          formik.setFieldTouched('mfgDate', true);
                        }}
                        value={formik.values.mfgDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            margin="none"
                            error={formik.touched.mfgDate && formik.errors.mfgDate ? true : false}
                            helperText={formik.touched.mfgDate && formik.errors.mfgDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Best before buy (Exp Date)">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.expDate}
                      error={formik.touched.expDate && formik.errors.expDate ? true : false}
                      helperText={formik.touched.expDate && formik.errors.expDate}
                    >
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        onChange={(value) => {
                          formik.setFieldValue('expDate', moment(value).toDate());
                          formik.setFieldTouched('expDate', true);
                        }}
                        value={formik.values.expDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            margin="none"
                            error={formik.touched.expDate && formik.errors.expDate ? true : false}
                            helperText={formik.touched.expDate && formik.errors.expDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Country Of Origin">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.countryOfOrigin}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.countryOfOrigin && formik.errors.countryOfOrigin ? true : false}
                      helperText={formik.touched.countryOfOrigin && formik.errors.countryOfOrigin}
                      name={'countryOfOrigin'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Instruction For Usage">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.instructionForUsed}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.instructionForUsed && formik.errors.instructionForUsed ? true : false}
                      helperText={formik.touched.instructionForUsed && formik.errors.instructionForUsed}
                      name={'instructionForUsed'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Test Method">
                    <TextField
                      fullWidth
                      margin="none"
                      value={formik.values.testMethod}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.testMethod && formik.errors.testMethod ? true : false}
                      helperText={formik.touched.testMethod && formik.errors.testMethod}
                      name={'testMethod'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}></Grid>
              </Grid>

              {/* {selectedTestGroup?.additionalFields && selectedTestGroup?.additionalFields.length ? (
                <Grid item xs={12}>
                  <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                    Additional Details
                  </Typography>
                  <Divider />
                </Grid>
              ) : null} */}

              {/* {selectedTestGroup?.additionalFields &&
                selectedTestGroup?.additionalFields.map((field) => {
                  return (
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        // required
                        label={field.key}
                        value={additionalFields[field.key]}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        // error={formik.touched.rate && formik.errors.rate ? true : false}
                        // helperText={formik.touched.rate && formik.errors.rate}
                        name={`additionalFields.${field.key}`}
                      />
                    </Grid>
                  );
                })} */}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} mt={0.5}>
              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Details of Sampling
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Name of Plant/Section" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.nameOfPlantSection}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nameOfPlantSection && formik.errors.nameOfPlantSection ? true : false}
                    helperText={formik.touched.nameOfPlantSection && formik.errors.nameOfPlantSection}
                    name={'nameOfPlantSection'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Purpose of Monitoring" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.purposeOfMonitoring}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.purposeOfMonitoring && formik.errors.purposeOfMonitoring ? true : false}
                    helperText={formik.touched.purposeOfMonitoring && formik.errors.purposeOfMonitoring}
                    name={'purposeOfMonitoring'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Emission Source Monitor" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.emissionSourceMonitor}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.emissionSourceMonitor && formik.errors.emissionSourceMonitor ? true : false}
                    helperText={formik.touched.emissionSourceMonitor && formik.errors.emissionSourceMonitor}
                    name={'emissionSourceMonitor'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Product Mgf / Sampling Time" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.productManufacturedSamplingTime}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.productManufacturedSamplingTime && formik.errors.productManufacturedSamplingTime
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.productManufacturedSamplingTime && formik.errors.productManufacturedSamplingTime
                    }
                    name={'productManufacturedSamplingTime'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Name of Authorized Person" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.nameOfAuthorizedPerson}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nameOfAuthorizedPerson && formik.errors.nameOfAuthorizedPerson ? true : false}
                    helperText={formik.touched.nameOfAuthorizedPerson && formik.errors.nameOfAuthorizedPerson}
                    name={'nameOfAuthorizedPerson'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Control Measures" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.controlMeasures}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.controlMeasures && formik.errors.controlMeasures ? true : false}
                    helperText={formik.touched.controlMeasures && formik.errors.controlMeasures}
                    name={'controlMeasures'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Address" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.authorizedPersonAddress}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.authorizedPersonAddress && formik.errors.authorizedPersonAddress ? true : false
                    }
                    helperText={formik.touched.authorizedPersonAddress && formik.errors.authorizedPersonAddress}
                    name={'authorizedPersonAddress'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Recovery of Material (if any)" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.recoveryOfMaterial}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.recoveryOfMaterial && formik.errors.recoveryOfMaterial ? true : false}
                    helperText={formik.touched.recoveryOfMaterial && formik.errors.recoveryOfMaterial}
                    name={'recoveryOfMaterial'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Seal on container/BIS Seal" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.sealOnContainerBISSeal}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sealOnContainerBISSeal && formik.errors.sealOnContainerBISSeal ? true : false}
                    helperText={formik.touched.sealOnContainerBISSeal && formik.errors.sealOnContainerBISSeal}
                    name={'sealOnContainerBISSeal'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="General Sensory Observer sampling flow rate" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.generalSensory}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.generalSensory && formik.errors.generalSensory ? true : false}
                    helperText={formik.touched.generalSensory && formik.errors.generalSensory}
                    name={'generalSensory'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="CHA Regn No." width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.CHSRegnNo}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.CHSRegnNo && formik.errors.CHSRegnNo ? true : false}
                    helperText={formik.touched.CHSRegnNo && formik.errors.CHSRegnNo}
                    name={'CHSRegnNo'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Fugitive Emission" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.fugitiveEmission}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fugitiveEmission && formik.errors.fugitiveEmission ? true : false}
                    helperText={formik.touched.fugitiveEmission && formik.errors.fugitiveEmission}
                    name={'fugitiveEmission'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="CHA G/H Card Holder" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.CHACardHolder}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.CHACardHolder && formik.errors.CHACardHolder ? true : false}
                    helperText={formik.touched.CHACardHolder && formik.errors.CHACardHolder}
                    name={'CHACardHolder'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Stack Temperature" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.stackTemp}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.stackTemp && formik.errors.stackTemp ? true : false}
                    helperText={formik.touched.stackTemp && formik.errors.stackTemp}
                    name={'stackTemp'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Date and Place of collection of sample" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.dateAndPlaceOfCollectionOfSample}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.dateAndPlaceOfCollectionOfSample && formik.errors.dateAndPlaceOfCollectionOfSample
                        ? true
                        : false
                    }
                    helperText={
                      formik.touched.dateAndPlaceOfCollectionOfSample && formik.errors.dateAndPlaceOfCollectionOfSample
                    }
                    name={'dateAndPlaceOfCollectionOfSample'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Ambient Temperature" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.ambientTemperature}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.ambientTemperature && formik.errors.ambientTemperature ? true : false}
                    helperText={formik.touched.ambientTemperature && formik.errors.ambientTemperature}
                    name={'ambientTemperature'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Sr. No." width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.srNo}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.srNo && formik.errors.srNo ? true : false}
                    helperText={formik.touched.srNo && formik.errors.srNo}
                    name={'srNo'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Stack Velocity / IO Signature" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.stackVelocity}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.stackVelocity && formik.errors.stackVelocity ? true : false}
                    helperText={formik.touched.stackVelocity && formik.errors.stackVelocity}
                    name={'stackVelocity'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Seals fixed no." width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.sealsFixedNo}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sealsFixedNo && formik.errors.sealsFixedNo ? true : false}
                    helperText={formik.touched.sealsFixedNo && formik.errors.sealsFixedNo}
                    name={'sealsFixedNo'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Conforms / Non Conforms" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.conformsOrNon}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.conformsOrNon && formik.errors.conformsOrNon ? true : false}
                    helperText={formik.touched.conformsOrNon && formik.errors.conformsOrNon}
                    name={'conformsOrNon'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="sealed cover by the" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.sealedCoverByThe}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sealedCoverByThe && formik.errors.sealedCoverByThe ? true : false}
                    helperText={formik.touched.sealedCoverByThe && formik.errors.sealedCoverByThe}
                    name={'sealedCoverByThe'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Service mode" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.serviceMode}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.serviceMode && formik.errors.serviceMode ? true : false}
                    helperText={formik.touched.serviceMode && formik.errors.serviceMode}
                    name={'serviceMode'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="I found the sample to be" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.foundTheSampleToBe}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.foundTheSampleToBe && formik.errors.foundTheSampleToBe ? true : false}
                    helperText={formik.touched.foundTheSampleToBe && formik.errors.foundTheSampleToBe}
                    name={'foundTheSampleToBe'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Sample status" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.sampleStatus}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sampleStatus && formik.errors.sampleStatus ? true : false}
                    helperText={formik.touched.sampleStatus && formik.errors.sampleStatus}
                    name={'sampleStatus'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Sampling Completed at" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.parametersMonitors}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.parametersMonitors && formik.errors.parametersMonitors ? true : false}
                    helperText={formik.touched.parametersMonitors && formik.errors.parametersMonitors}
                    name={'parametersMonitors'}
                  />
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Status reasons" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.statusReasons}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.statusReasons && formik.errors.statusReasons ? true : false}
                    helperText={formik.touched.statusReasons && formik.errors.statusReasons}
                    name={'statusReasons'}
                  />
                </CustomFieldWithLabel>
              </Grid>
            </Grid>

            <Grid container spacing={1} mt={0.5}>
              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Details for laboratory use
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <CustomFieldWithLabel label="Any other information" width={110}>
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.otherInformation}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.otherInformation && formik.errors.otherInformation ? true : false}
                    helperText={formik.touched.otherInformation && formik.errors.otherInformation}
                    name={'otherInformation'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Collection By" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.collectionBy}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.collectionBy && formik.errors.collectionBy ? true : false}
                    helperText={formik.touched.collectionBy && formik.errors.collectionBy}
                    name={'collectionBy'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel width={110} label="Receive Mode">
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    // @ts-ignore: Unreachable code error
                    value={formik.values.sampleReceivedMode}
                    error={formik.touched.sampleReceivedMode && formik.errors.sampleReceivedMode ? true : false}
                    helperText={formik.touched.sampleReceivedMode && formik.errors.sampleReceivedMode}
                  >
                    <Select
                      labelId="sampleReceivedMode-select-label"
                      id="sampleReceivedMode-select-helper"
                      name="sampleReceivedMode"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sampleReceivedMode}
                      error={formik.touched.sampleReceivedMode && formik.errors.sampleReceivedMode ? true : false}
                    >
                      <MenuItem value={'Self'}>Self</MenuItem>
                      <MenuItem value={'Courier'}>Courier</MenuItem>
                      <MenuItem value={'Mail'}>Mail</MenuItem>
                    </Select>
                    <FormHelperText>
                      {formik.touched.sampleReceivedMode && formik.errors.sampleReceivedMode}
                    </FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="HOD" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.hod}
                    error={formik.touched.hod && formik.errors.hod ? true : false}
                    helperText={formik.touched.hod && formik.errors.hod}
                  >
                    <Select
                      labelId="hod-select-label"
                      id="hod-select-helper"
                      name="hod"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.hod}
                      error={formik.touched.hod && formik.errors.hod ? true : false}
                    >
                      {props.userList
                        .filter((i) => i.role === 'ADMIN' || i.role === 'HOD')
                        .map((item) => (
                          <MenuItem value={item.user._id} key={item._id}>
                            {item.user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.hod && formik.errors.hod}</FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Authorized Signature 1" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.authorizedSignature1}
                    error={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1 ? true : false}
                    helperText={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1}
                  >
                    <Select
                      labelId="authorizedSignature1-select-label"
                      id="authorizedSignature1-select-helper"
                      name="authorizedSignature1"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.authorizedSignature1}
                      error={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1 ? true : false}
                    >
                      {props.userList
                        .filter((i) => i.role === 'ADMIN' || i.role === 'HOD')
                        .map((item) => (
                          <MenuItem value={item.user._id} key={item._id}>
                            {item.user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1}
                    </FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>
              <Grid item xs={6}>
                <CustomFieldWithLabel label="Authorized Signature 2" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.authorizedSignature2}
                    error={formik.touched.authorizedSignature2 && formik.errors.authorizedSignature2 ? true : false}
                    helperText={formik.touched.authorizedSignature2 && formik.errors.authorizedSignature2}
                  >
                    <Select
                      labelId="authorizedSignature2-select-label"
                      id="authorizedSignature2-select-helper"
                      name="authorizedSignature2"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.authorizedSignature2}
                      error={formik.touched.authorizedSignature2 && formik.errors.authorizedSignature2 ? true : false}
                    >
                      {props.userList
                        .filter((i) => i.role === 'ADMIN' || i.role === 'HOD')
                        .map((item) => (
                          <MenuItem value={item.user._id} key={item._id}>
                            {item.user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.authorizedSignature2 && formik.errors.authorizedSignature2}
                    </FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Reviewed By" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.reviewedBy}
                    error={formik.touched.reviewedBy && formik.errors.reviewedBy ? true : false}
                    helperText={formik.touched.reviewedBy && formik.errors.reviewedBy}
                  >
                    <Select
                      labelId="reviewedBy-select-label"
                      id="reviewedBy-select-helper"
                      name="reviewedBy"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reviewedBy}
                      error={formik.touched.reviewedBy && formik.errors.reviewedBy ? true : false}
                    >
                      {props.userList.map((item) => (
                        <MenuItem value={item.user._id} key={item._id}>
                          {item.user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formik.touched.reviewedBy && formik.errors.reviewedBy}</FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} gap={2}>
              <Box flex={1}>
                <CustomFieldWithLabel width={120} label="Parameters">
                  {/* @ts-ignore */}
                  <ReactSelect
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={formik.values.parameters.map((i: any) => ({ value: i.name, label: i.name }))}
                    onChange={(value) => {
                      // @ts-ignore
                      console.log('value -> ', value);
                      console.log('selectedMaster?.parameters -> ', selectedMaster?.parameters);
                      const selectedParameters = selectedMaster?.parameters?.filter((i) =>
                        value.map((i) => i.value).includes(i.name)
                      );
                      formik.setFieldValue('parameters', selectedParameters);
                    }}
                    options={selectedMaster?.parameters?.map((i) => ({
                      value: i.name,
                      label: i.name,
                    }))}
                  />
                </CustomFieldWithLabel>
                <div>{formik.errors.parameters && <div style={{ color: 'red' }}>{formik.errors.parameters}</div>}</div>
              </Box>
              <div>
                <Button variant="contained" onClick={handleSelectAllParameter}>
                  Select All Parameters
                </Button>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
              Billing Details
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              required
              label={'Charges (Rate)'}
              type="number"
              value={formik.values.rate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.rate && formik.errors.rate ? true : false}
              helperText={formik.touched.rate && formik.errors.rate}
              name={'rate'}
            />
            <TextField
              fullWidth
              required
              label={'Discount %'}
              value={formik.values.discount}
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.discount && formik.errors.discount ? true : false}
              helperText={formik.touched.discount && formik.errors.discount}
              name={'discount'}
            />
            <Box display={'flex'} gap={1}>
              <TextField
                fullWidth
                required
                type="number"
                style={{ flex: 0.4 }}
                label={'GST %'}
                value={formik.values.gst}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gst && formik.errors.gst ? true : false}
                helperText={formik.touched.gst && formik.errors.gst}
                name={'gst'}
              />
              <Box display={'flex'} style={{ flex: 0.6 }} alignItems={'center'}>
                <FormControl margin="dense" fullWidth size="small" required>
                  {/* <InputLabel id="department-select-label">Department</InputLabel> */}
                  <Select
                    id="demo-simple-select-helper"
                    name="igst"
                    onChange={(e) => {
                      formik.setFieldValue('igst', e.target.value === 'true');
                    }}
                    value={formik.values.igst ? 'true' : 'false'}
                  >
                    <MenuItem value={'false'}>CGST / SGST</MenuItem>
                    <MenuItem value={'true'}>IGST</MenuItem>
                  </Select>
                </FormControl>
                {/* Render checkbox for igst formik field */}
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.igst}
                      onChange={formik.handleChange}
                      name="igst"
                      color="primary"
                      size="small"
                    />
                  }
                  label="IGST"
                /> */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              required
              type="number"
              label={'Paid Amount'}
              value={formik.values.paidAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.paidAmount && formik.errors.paidAmount ? true : false}
              helperText={formik.touched.paidAmount && formik.errors.paidAmount}
              name={'paidAmount'}
            />
            <TextField fullWidth required disabled label={'Total'} value={total} />
            <TextField fullWidth required disabled label={'Remaining Total'} value={remainingTotal} />
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DesktopDatePicker
                label="Payment Due Date"
                inputFormat="DD/MM/YYYY"
                onChange={(value) => {
                  formik.setFieldValue('paymentDueDate', moment(value).toDate());
                  formik.setFieldTouched('paymentDueDate', true);
                }}
                value={formik.values.paymentDueDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    error={formik.touched.paymentDueDate && formik.errors.paymentDueDate ? true : false}
                    helperText={formik.touched.paymentDueDate && formik.errors.paymentDueDate}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              required
              label={'SAC Code'}
              value={formik.values.sac}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sac && formik.errors.sac ? true : false}
              helperText={formik.touched.sac && formik.errors.sac}
              name={'sac'}
            />
          </Grid>
        </Grid>

        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
          <Box>
            <Button disabled={formik.isSubmitting} variant="outlined" onClick={() => navigate(`/laboratory/samples`)}>
              Back
            </Button>
          </Box>
          <Box ml={1}>
            <Button variant="contained" disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
              {sampleId ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Box>

      <SearchDialog
        isVisible={customerSearchModal}
        title={'Search customer'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={customerSearchQuery}
              autoFocus
              onChange={(e) => setCustomerSearchQuery(e.target.value)}
            />
            {!props.searchCustomerList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.searchCustomerList.map((c) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCustomer(c);
                  setCustomerSearchModal(false);
                  setCustomerSearchQuery('');
                  formik.setFieldValue('customer', c._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {c.name}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {c.email}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {c.country} {c.phone}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setCustomerSearchModal(false);
          setCustomerSearchQuery('');
        }}
        hideButtons={true}
      />

      <SearchDialog
        isVisible={testGroupSearchModal}
        title={'Search Test Group'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={testGroupSearchQuery}
              autoFocus
              onChange={(e) => setTestGroupSearchQuery(e.target.value)}
            />
            {!props.masterList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.testGroupsList.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedTestGroup(m);
                  setTestGroupSearchModal(false);
                  setTestGroupSearchQuery('');
                  formik.setFieldValue('testGroup', m._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.name}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.prefix}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setTestGroupSearchModal(false);
          setTestGroupSearchQuery('');
        }}
        hideButtons={true}
      />

      <SearchDialog
        isVisible={masterSearchModal}
        title={'Search Master'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={masterSearchQuery}
              autoFocus
              onChange={(e) => setMasterSearchQuery(e.target.value)}
            />
            {!props.masterList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.masterList.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedMaster(m);
                  setMasterSearchModal(false);
                  setMasterSearchQuery('');
                  formik.setFieldValue('marking', m.marking || '');
                  formik.setFieldValue('name', m.sampleName);
                  formik.setFieldValue('genericName', m.genericName);
                  formik.setFieldValue('testMethod', m.testingMethod);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.sampleName}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.genericName}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.masterId}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setMasterSearchModal(false);
          setMasterSearchQuery('');
        }}
        hideButtons={true}
      />

      <SearchDialog
        isVisible={referenceSearchModal}
        title={'Search Reference'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={referenceSearchQuery}
              autoFocus
              onChange={(e) => setReferenceSearchQuery(e.target.value)}
            />
            {!props?.referenceList?.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props?.referenceList?.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedReference(m);
                  setReferenceSearchModal(false);
                  setReferenceSearchQuery('');
                  formik.setFieldValue('reference', m._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.name}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setReferenceSearchModal(false);
          setReferenceSearchQuery('');
        }}
        hideButtons={true}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  department: state.department.list,
  searchCustomerList: state.laboratory.searchCustomer,
  masterList: state.laboratory.masters,
  testGroupsList: state.laboratory.testGroups,
  referenceList: state.laboratory.reference,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        searchCustomer,
        searchMasterList,
        searchTestGroupList,
        getUserList,
        createSample,
        searchReference,
        getSampleById,
        updateSample,
      },
      dispatch
    ),
  };
}

export const PersistSampleKSZ = connect(mapStateToProps, mapDispatchToProps)(_PersistSampleKSZ);
