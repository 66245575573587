import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IReducer } from 'Reducers/IReducer';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import SettingsIcon from '@mui/icons-material/Settings';
import { logout } from 'actions/AuthActions';
import LogoutIcon from '@mui/icons-material/Logout';
import { bindActionCreators } from 'redux';
import { IUser } from 'exactt-types/lib';

const logo = require('assets/images/exact.png');

interface IMainNav {
  laboratory: null | ILaboratoryDetails;
  user?: null | IUser;
  logout: () => any;
}

export function _MainNav(props: IMainNav) {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      position={'fixed'}
      zIndex={10000000}
      bgcolor={'#fff'}
      left={0}
      right={0}
      justifyContent={'space-between'}
      p={1}
      alignItems={'center'}
      borderBottom={'2px solid rgba(9, 30, 66, 0.14)'}
    >
      <Box display={'flex'} gap={1} alignItems={'center'}>
        <img src={logo} style={{ height: 40 }} alt="" />
        <Typography fontSize={16} fontWeight={'500'}>
          Exact LIMS
        </Typography>
      </Box>
      <Box>
        <Typography fontSize={14} fontWeight={'600'}>
          {props.laboratory?.details?.name}
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Box>
          <IconButton aria-label="Setting">
            <SettingsIcon fontSize={'small'} />
          </IconButton>
        </Box>
        <Box>
          <IconButton
            aria-label="Logout"
            onClick={() => {
              props.logout();
              navigate('/');
            }}
          >
            <LogoutIcon fontSize={'small'} />
          </IconButton>
        </Box>
        <Avatar sx={{ bgcolor: '#a76440', width: 30, height: 30, fontSize: 16, fontWeight: 'bold' }}>
          {props.user?.name[0]}
        </Avatar>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  user: state.auth.user,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ logout }, dispatch),
  };
}

const MainNav = connect(mapStateToProps, mapDispatchToProps)(_MainNav);

export default MainNav;
