import { Box, Breadcrumbs, Divider, Typography, Link } from '@mui/material';
import { IHeader } from 'components/Header';

export default function Header(props: IHeader) {
  return (
    <Box position={'absolute'} right={0} left={250} zIndex={100} bgcolor={'#fff'}>
      <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Breadcrumbs separator="/">
            {props.breadcrumbsList.map((item, idx) =>
              idx === props.breadcrumbsList.length - 1 ? (
                <Typography variant={'subtitle1'} fontSize={14} key={item.label + '_header'}>
                  {item.label}
                </Typography>
              ) : (
                <Link
                  variant={'subtitle1'}
                  underline="hover"
                  color="inherit"
                  sx={{ cursor: 'pointer' }}
                  fontSize={14}
                  onClick={item.onClick}
                  key={item.label + '_header'}
                >
                  {item.label}
                </Link>
              )
            )}
          </Breadcrumbs>
          <Typography variant={'subtitle2'} fontSize={16} lineHeight={1}>
            {props.title}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          {props.right}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}
