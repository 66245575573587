import { Box } from '@mui/material';
import React from 'react';

export default function GeneralFinalFooter() {
  return (
    <Box mt={2}>
      <Box>Notes:</Box>
      <Box fontSize={10}>
        1. This report, in full or in part, shall not be published, advertised, used for any legal action, unless prior
        permission has been secured from the Director of Laboratory.
      </Box>
      <Box fontSize={10}>2. This test report is ONLY FOR THE SAMPLE TESTED.</Box>
      <Box fontSize={10} textAlign={'center'}>
        .....................................................END OF
        REPORT.....................................................
      </Box>
    </Box>
  );
}
