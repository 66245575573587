import actionTypes from 'constants/ActionTypes';
import { VariantType } from 'notistack';
import { IDispatchType } from 'Reducers/IActionDispatch';

const showAlert = (dispatch: IDispatchType, message: string, variant: VariantType) => {
  dispatch({
    type: actionTypes.system.SHOW_SNACK,
    payload: { message, variant },
  });
  dispatch({
    type: actionTypes.system.SHOW_SNACK,
    payload: { message: null, variant },
  });
};

export default showAlert;
