import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  FormLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { IPersistUserModal } from 'pages/User/PersistUserModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { inviteUser, getUserList, editUserAccess } from 'actions/UserAction';
import { useEffect } from 'react';
import { IAccess } from 'exactt-types/lib';

function _PersistUserModal(props: IPersistUserModal) {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email.').required('Required'),
    laboratoryId: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    departmentId: Yup.string().required('Required'),
  });

  const initialValues = {
    email: props.editUser?.user?.email ?? '',
    laboratoryId: props.laboratory?.details._id ?? '',
    departmentId: props.editUser?.departmentId ?? '',
    role: props.editUser?.role ?? '',
    accessList: props.editUser?.accessList ?? {
      master: false,
      customer: true,
      accounting: true,
      samples: true,
      stokManagement: true,
      laboratorySetting: false,
      reference: true,
      payment: false,
      resultTemplate: true,
      testGroup: true,
      department: true,
    },
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any, { setSubmitting }) => {
      try {
        setSubmitting(true);
        let res;
        if (props.editUser) {
          const formData: IAccess = {
            _id: props.editUser._id,
            accessList: values.accessList,
            departmentId: values.departmentId,
            laboratoryId: values.laboratoryId,
            role: values.role,
            userId: props.editUser.userId,
          };
          res = await props.editUserAccess(formData);
        } else {
          res = await props.inviteUser(values);
        }
        if (res.status === 200) {
          await props.getUserList({ laboratoryId: props.laboratory?.details._id || '' });
          formik.resetForm();
          props.onClose();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.resetForm();
    const formValues = {
      email: props.editUser?.user?.email ?? '',
      laboratoryId: props.laboratory?.details._id ?? '',
      departmentId: props.editUser?.departmentId ?? '',
      role: props.editUser?.role ?? '',
      accessList: props.editUser?.accessList ?? {
        master: false,
        customer: true,
        accounting: true,
        samples: true,
        stokManagement: true,
        laboratorySetting: false,
        reference: true,
        payment: false,
        resultTemplate: true,
        testGroup: true,
        department: true,
      },
    };
    formik.setValues(formValues);
    // eslint-disable-next-line
  }, [props.isOpen]);

  const rolesType = Object.keys(props.enums?.userTypes || {}).map((key: any) => ({
    value: key,
    label: (props.enums as any)?.userTypes[key] as any,
  }));

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} maxWidth={'sm'}>
      <DialogTitle>{props.editUser ? 'Edit User' : 'Invite user'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To invite user in your laboratory, add user's email address and some other details
        </DialogContentText>

        <TextField
          autoFocus
          disabled={props.editUser ? true : false}
          label={'Email'}
          name={'email'}
          type={'email'}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email ? true : false}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
          variant="outlined"
        />

        <FormControl margin="dense" fullWidth size="small" required>
          <InputLabel id="department-select-label">Department</InputLabel>
          <Select
            labelId="department-select-label"
            id="department-select-helper"
            name="departmentId"
            label="Department"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.departmentId}
            error={formik.touched.departmentId && formik.errors.departmentId ? true : false}
          >
            {props.laboratory?.departments?.map((item) => (
              <MenuItem value={item._id} key={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.departmentId && formik.errors.departmentId}</FormHelperText>
        </FormControl>
        <FormControl margin="dense" fullWidth size="small" required>
          <InputLabel id="department-select-label">Role</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select-helper"
            name="role"
            label="Role"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
            error={!!(formik.touched.role && formik.errors.role)}
          >
            {rolesType.map((item) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.touched.departmentId && formik.errors.departmentId}</FormHelperText>
        </FormControl>

        <FormControl margin="dense">
          <FormLabel>Access</FormLabel>
          <FormGroup>
            <Box>
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.master} />}
                label="Master"
                defaultChecked={false}
                onChange={(e) => formik.setFieldValue('accessList.master', !formik.values.accessList.master)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.customer} />}
                label="Customer"
                defaultChecked={false}
                onChange={(e) => formik.setFieldValue('accessList.customer', !formik.values.accessList.customer)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.accounting} />}
                label="Accounting"
                onChange={(e) => formik.setFieldValue('accessList.accounting', !formik.values.accessList.accounting)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.samples} />}
                label="Samples"
                onChange={(e) => formik.setFieldValue('accessList.samples', !formik.values.accessList.samples)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.stokManagement} />}
                label="Stok management"
                onChange={(e) =>
                  formik.setFieldValue('accessList.stokManagement', !formik.values.accessList.stokManagement)
                }
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.laboratorySetting} />}
                label="Laboratory setting"
                onChange={(e) =>
                  formik.setFieldValue('accessList.laboratorySetting', !formik.values.accessList.laboratorySetting)
                }
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.reference} />}
                label="Reference"
                onChange={(e) => formik.setFieldValue('accessList.reference', !formik.values.accessList.reference)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.payment} />}
                label="Payment"
                onChange={(e) => formik.setFieldValue('accessList.payment', !formik.values.accessList.payment)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.resultTemplate} />}
                label="Result Template"
                onChange={(e) =>
                  formik.setFieldValue('accessList.resultTemplate', !formik.values.accessList.resultTemplate)
                }
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.testGroup} />}
                label="Test Group"
                onChange={(e) => formik.setFieldValue('accessList.testGroup', !formik.values.accessList.testGroup)}
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.accessList.department} />}
                label="Department"
                onChange={(e) => formik.setFieldValue('accessList.department', !formik.values.accessList.department)}
              />
            </Box>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.resetForm();
            props.onClose();
          }}
          color="error"
        >
          Cancel
        </Button>
        <Button disabled={!formik.isValid || formik.isSubmitting} onClick={() => formik.handleSubmit()}>
          {props.editUser ? 'Edit' : 'Invite'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
  enums: state.system.enums,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ inviteUser, getUserList, editUserAccess }, dispatch),
  };
}

export const PersistUserModal = connect(mapStateToProps, mapDispatchToProps)(_PersistUserModal);
