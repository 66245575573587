import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@mui/material';
import { IReducer } from 'Reducers/IReducer';
import { getCustomerById } from 'actions/CustomerActions';
import {
  generateInvoiceApi,
  getCustomerInvoice,
  getCustomerPendingSamplesForInvoice,
  updateCutomerAmount,
} from 'actions/LaboratoryActions';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { ICustomer, IInvoice, ISample } from 'exactt-types/lib';
import moment from 'moment';
import { ICustomerDetails } from 'pages/Customer/CustomerDetails';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UpgradeIcon from '@mui/icons-material/Upgrade';

export function _CustomerDetails(props: ICustomerDetails) {
  const [customer, setCustomer] = useState<ICustomer | undefined>(undefined);
  const [value, setValue] = useState(0);
  const [samplesList, setSamplesList] = useState<ISample[]>([]);
  const [selectedSamples, setSelectedSamples] = useState<ISample[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [igst, setIgst] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [updateBalanceModal, setUpdateBalanceModal] = useState(false);
  const [customerBalance, setCustomerBalance] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const params = useParams();
  const customerId = params.customerId;

  const onFocus = async () => {
    if (customerId) {
      // @ts-ignore: Unreachable code error
      const customer = await props.getCustomerById(props.laboratory?.details._id || '', customerId);
      const samples = await getCustomerPendingSamplesForInvoice(props.laboratory?.details._id || '', customerId);
      const invoicesList = await getCustomerInvoice(props.laboratory?.details._id || '', customerId);
      setInvoices(invoicesList);

      setSamplesList(samples);

      if (customer) {
        setCustomer(customer);
      }
    }
  };

  const navigate = useNavigate();

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Customers',
      onClick: () => navigate('/laboratory/customers'),
    },
    {
      label: 'Customer Details',
    },
  ];

  useEffect(() => {
    onFocus();
  }, [customerId]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const generateInvoice = async () => {
    setLoading(true);
    const samples = selectedSamples.map((sample) => sample._id);
    const data = {
      igst,
      samples,
    };
    generateInvoiceApi(props.laboratory?.details._id || '', customerId || '', data);

    onFocus();
    setLoading(false);
    setIsVisible(false);
  };

  const updateCustomerBalance = async () => {
    setUpdateBalanceModal(false);
    const amount = customerBalance;
    updateCutomerAmount(props.laboratory?.details._id || '', customerId || '', amount);
  };

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title={customer?.name || ''}
        right={
          <Box display="flex" alignItems="center" gap={2} justifyContent="center">
            <Button variant="contained" startIcon={<UpgradeIcon />} onClick={() => setUpdateBalanceModal(true)}>
              Update Payment
            </Button>
            {customer?.balance !== undefined && customer?.balance > 0 ? (
              <Box>
                Balance:
                <Box component="span" sx={{ color: 'green' }}>
                  {customer?.balance}
                </Box>
              </Box>
            ) : (
              <Box>
                Balance:
                <Box component="span" sx={{ color: 'red' }}>
                  {customer?.balance ?? 'N/A'}
                </Box>
              </Box>
            )}
          </Box>
        }
      />
      <Dialog open={updateBalanceModal} onClose={() => setUpdateBalanceModal(false)} maxWidth={'sm'}>
        <DialogTitle>Update Payment</DialogTitle>
        <DialogContent>
          <Box mt={1} />
          <Box width={'80%'}>
            <TextField
              value={Number(customerBalance)}
              label="Amount"
              type="number"
              fullWidth
              onChange={(e) => setCustomerBalance(Number(e.target.value))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateBalanceModal(false)}>Cancel</Button>
          <Button variant="contained" onClick={() => updateCustomerBalance()}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={2} pt={10}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Box>Name: {customer?.name}</Box>
            <Box>Email: {customer?.email}</Box>
            <Box>Phone: {customer?.phone}</Box>
            <Box>GST: {customer?.gst}</Box>
          </Box>
          <Box>
            <Box style={{ textAlign: 'right' }}>
              <Box>Address: {customer?.address?.[0]?.line1}</Box>
              <Box> {customer?.address?.[0]?.line2}</Box>
              <Box>
                {customer?.address?.[0]?.city}, {customer?.address?.[0]?.state} - {customer?.address?.[0]?.zip}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ pt: 2 }} />
        <Box sx={{ pt: 2 }}>
          <Box display={'flex'} gap={4} alignItems={'center'}>
            <Box style={{ fontWeight: 'bold' }}>Selected Samples: {selectedSamples.length}</Box>
            <Button disabled={selectedSamples.length === 0} variant="contained" onClick={() => setIsVisible(true)}>
              Generate Invoice
            </Button>
          </Box>
          <Divider sx={{ mt: 2 }} />
          {/* make a tabs 1. Pending Invoice 2. Invoice */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Pending Invoices" {...a11yProps(0)} />
              <Tab label="Invoices" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {value === 0 && (
            <Box>
              <Table sx={{ minWidth: 500, marginTop: '0.6%' }} size="small">
                <TableHead>
                  <TableCell width={40}>
                    <Checkbox
                      checked={selectedSamples?.length === samplesList.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSamples(samplesList);
                        } else {
                          setSelectedSamples([]);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell width={250}>Sample ID</TableCell>
                  <TableCell width={250}>Date</TableCell>
                  <TableCell width={250}>Name</TableCell>
                  <TableCell width={250}>Amount</TableCell>
                </TableHead>
                {samplesList?.map((data) => {
                  const taxAmount = data.gst && data.rate ? (data.rate * data.gst) / 100 : 0;
                  const totalWithTax = data.rate ? data.rate + taxAmount : 0;
                  return (
                    <TableRow>
                      <TableCell width={40}>
                        <Checkbox
                          checked={selectedSamples.includes(data)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedSamples([...selectedSamples, data]);
                            } else {
                              setSelectedSamples(selectedSamples.filter((sample) => sample._id !== data._id));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell width={250}>
                        <Button
                          variant={'text'}
                          onClick={() => navigate(`/laboratory/samples/sampleDetails?id=${data._id}`)}
                          style={{ fontSize: 14 }}
                        >
                          ↗ {data.sampleId ? data.sampleId : 'SAMPLE ID not found'}
                        </Button>
                      </TableCell>
                      <TableCell width={100}>{moment(data.collectionDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell width={450}>{data.name}</TableCell>
                      <TableCell width={250}>₹ {totalWithTax}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Box>
          )}
          {value === 1 && (
            <Box>
              <Table sx={{ minWidth: 500, marginTop: '0.6%' }} size="small">
                <TableHead>
                  <TableCell width={250}>Invoice ID</TableCell>
                  <TableCell width={250}>Date</TableCell>
                  <TableCell width={250}></TableCell>
                </TableHead>

                {invoices.map((invoice) => {
                  return (
                    <TableRow>
                      <TableCell width={250}>{invoice.invoiceNumber}</TableCell>
                      <TableCell width={250}>{moment(invoice.invoiceDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell width={250}>
                        <Button
                          variant={'contained'}
                          // @ts-ignore
                          onClick={() => {
                            window.open(
                              // @ts-ignore
                              `${window.location.origin}/laboratory/print/invoice?id=${invoice?._id || ''}`,
                              'Print',
                              'width=800,height=500'
                            );
                          }}
                        >
                          View Invoice
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Box>
          )}
          <Dialog open={isVisible} onClose={() => setIsVisible(false)} maxWidth={'sm'} fullWidth>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to generate invoice for {selectedSamples.length} samples?
              </DialogContentText>
              <DialogContentText>
                IGST Invoice: <Checkbox checked={igst} onChange={(e) => setIgst(e.target.checked)} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={() => setIsVisible(false)}>
                Cancel
              </Button>
              <Button variant="contained" disabled={loading} onClick={() => generateInvoice()}>
                Generate Invoice
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ getCustomerById }, dispatch),
  };
}

export const CustomerDetails = connect(mapStateToProps, mapDispatchToProps)(_CustomerDetails);
