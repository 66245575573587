import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, TableBody, Link } from '@mui/material';
import { IPriceListTable } from './IPriceListTable';

export default function _PriceListTable(props: IPriceListTable) {
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Customer</TableCell>
              <TableCell>Master</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {/* Mapp code */}
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      props.onEdit(row);
                    }}
                  >
                    {row?.customer?.name || 'N/A'}
                  </Link>
                </TableCell>
                <TableCell>{row?.master?.sampleName || 'N/A'}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => props.onEdit(row)}>Edit</Button>
                  <Button color="error" onClick={() => props.onDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const PriceListTable = connect(mapStateToProps, mapDispatchToProps)(_PriceListTable);
