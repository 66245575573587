import { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  // FormControlLabel,
} from '@mui/material';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IPersistMasterProps } from 'pages/Master/PersistMater';
import { IFormikCreateMaster } from './IPersistMaster';
import { useFormik } from 'formik';
import { createMasterSchema } from 'FormSchema/CreateMasterSchema';

// import { ParametersCard } from 'components/ParametersCard';
import { createMaster, getMasterById, updateMaster, getResultTemplate, getDepartment } from 'actions/LaboratoryActions';
// import _ from 'lodash';
import useQuery from 'utils/getQueryParams';
import ParameterTable from './ParameterTable';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import { handleChangeWhiteSpace } from 'utils/removeWhiteSpaces';

function _PersistMaster(props: IPersistMasterProps) {
  const [loading, setLoading] = useState(true);

  const resultType = props.resultTemplate.map((r) => ({
    value: r._id,
    label: r.name,
  }));

  const navigate = useNavigate();

  const query = useQuery();
  const masterId = query.get('id');
  const copyFrom = query.get('copyFrom');
  const laboratoryId = props.laboratory?.details._id || '';

  const formik: IFormikCreateMaster = useFormik({
    initialValues: createMasterSchema.initialValues,
    validationSchema: createMasterSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (masterId) {
          await props.updateMaster({
            ...values,
            laboratoryId,
            _id: masterId,
          });
        } else {
          await props.createMaster({
            ...values,
            laboratoryId,
          });
        }
        navigate('/laboratory/masters');
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleChange = (event: any) => {
    const { value } = event.target;
    const length = event.target.value.length;
    if (value[length - 1] === ' ' && value[length - 2] === ' ') {
      event.target.value = handleChangeWhiteSpace(value);
    }
    formik.handleChange(event);
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Masters',
      onClick: () => navigate('/laboratory/masters'),
    },
    {
      label: 'Create Masters',
      onClick: () => navigate('/laboratory/masters/persist'),
    },
  ];

  // const handleDeleteParameter = (index: number) => {
  //   let updateParameters = formik.values.parameters.filter((item, idx) => idx !== index);
  //   formik.setFieldValue('parameters', updateParameters);
  // };
  const onFocus = async () => {
    try {
      await props.getDepartment(laboratoryId, { page: 0, size: 0 });
      await props.getResultTemplate(laboratoryId);
      if (masterId || copyFrom) {
        const master = await props.getMasterById(laboratoryId, (masterId || copyFrom) as string);
        formik.setValues({
          sampleName: master.sampleName,
          genericName: master.genericName,
          environmentCondition: master?.environmentCondition || '',
          printTemplate: master?.printTemplate || '',
          // @ts-ignore: Unreachable code error
          parameters: master.parameters,
          requiredOpinion: !!master.requiredOpinion,
          testingMethod: master.testingMethod,
          resultHeader: master.resultHeader || '',
          resultFooter: master.resultFooter || '',
          marking: master.marking || '',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Create Masters" />
      <Box p={2} pt={10}>
        {loading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Box pt={2} pb={2}>
                <Typography variant="subtitle2">Sample Master</Typography>
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <CustomFieldWithLabel label="Sample Name" width={110}>
                        <TextField
                          // label={'Sample Name'}
                          name={'sampleName'}
                          value={formik.values.sampleName}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.sampleName && formik.errors.sampleName ? true : false}
                          helperText={formik.touched.sampleName && formik.errors.sampleName}
                          type={'text'}
                          required
                          fullWidth
                          variant="outlined"
                        />
                      </CustomFieldWithLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFieldWithLabel label="Generic Name" width={110}>
                        <TextField
                          name={'genericName'}
                          value={formik.values.genericName}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.genericName && formik.errors.genericName ? true : false}
                          helperText={formik.touched.genericName && formik.errors.genericName}
                          type={'text'}
                          required
                          fullWidth
                          variant="outlined"
                        />
                      </CustomFieldWithLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomFieldWithLabel label="Testing Method" width={110}>
                        <TextField
                          name={'testingMethod'}
                          value={formik.values.testingMethod}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.testingMethod && formik.errors.testingMethod ? true : false}
                          helperText={formik.touched.testingMethod && formik.errors.testingMethod}
                          type={'text'}
                          required
                          fullWidth
                          variant="outlined"
                        />
                      </CustomFieldWithLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomFieldWithLabel label="Marking" width={110}>
                        <TextField
                          name={'marking'}
                          value={formik.values.marking}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.marking && formik.errors.marking ? true : false}
                          helperText={formik.touched.marking && formik.errors.marking}
                          type={'text'}
                          fullWidth
                          variant="outlined"
                        />
                      </CustomFieldWithLabel>
                    </Grid>
                    {/* <Grid item xs={4}>
                      <TextField
                        label={'Environment Condition'}
                        name={'environmentCondition'}
                        value={formik.values.environmentCondition}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.environmentCondition && formik.errors.environmentCondition ? true : false}
                        helperText={formik.touched.environmentCondition && formik.errors.environmentCondition}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid> */}

                    <Grid item xs={2}>
                      <Box display={'flex'} alignItems={'center'} height={'100%'}>
                        <Checkbox
                          checked={formik.values.requiredOpinion}
                          onChange={(e) => {
                            formik.setFieldValue('requiredOpinion', e.target.checked);
                          }}
                        />
                        <Typography>Required Opinion</Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        multiline
                        minRows={3}
                        label={'Result Header'}
                        name={'resultHeader'}
                        value={formik.values.resultHeader}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.resultHeader && formik.errors.resultHeader ? true : false}
                        helperText={formik.touched.resultHeader && formik.errors.resultHeader}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <TextField
                        multiline
                        minRows={3}
                        label={'Result Footer'}
                        name={'resultFooter'}
                        value={formik.values.resultFooter}
                        onChange={handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.resultFooter && formik.errors.resultFooter ? true : false}
                        helperText={formik.touched.resultFooter && formik.errors.resultFooter}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>

              <Box pt={2} pb={2}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="subtitle2">Parameters</Typography>
                  {/* <Button variant="text" onClick={() => setParameterModal(true)}>
                    Add Parameter
                  </Button> */}
                </Box>
                <Divider />
              </Box>

              {/* {!formik.values.parameters.length && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="caption">No parameter added yet</Typography>
                </Box>
              )} */}

              <ParameterTable value={formik.values.parameters} setFieldValue={formik.setFieldValue} />

              {/* {formik.values.parameters.map((i, idx) => (
                <ParametersCard data={i} onDelete={() => handleDeleteParameter(idx)} />
              ))} */}

              <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Box>
                  <Button
                    disabled={formik.isSubmitting}
                    variant="outlined"
                    onClick={() => navigate('/laboratory/masters')}
                  >
                    Back
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => formik.handleSubmit()}
                  >
                    {masterId ? 'Update' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  resultTemplate: state.laboratory.resultTemplate,
  enums: state.system.enums,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createMaster, getMasterById, updateMaster, getResultTemplate, getDepartment }, dispatch),
  };
}

export const PersistMaster = connect(mapStateToProps, mapDispatchToProps)(_PersistMaster);
