import { Box, Button, Grid, TextField } from '@mui/material';
import useStyles from './styles';
import { Typography } from '@mui/material';
import translations from 'utils/translations';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login } from 'actions/AuthActions';
import { ILogin } from 'pages/Login';
import { IReducer } from 'Reducers/IReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { common } from 'constants/ImagesFiles';

function _Login(props: ILogin) {
  const classes = useStyles();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email.').required('Required'),
    password: Yup.string().required('Required'),
  });
  const initialValues = {
    email: '',
    password: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await props.login(values);
      setSubmitting(false);
    },
  });

  return (
    <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <Grid item xs={3}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <img src={common.exactLogo} className={classes.logo} alt="logo" />
            <Box>
              <Typography variant="h6" lineHeight={0.8} p={0} m={0}>
                Exact Laboratory Software
              </Typography>
              <Typography variant="caption" fontSize={12}>
                The next generation of laboratory
              </Typography>
            </Box>
          </Box>
          <Typography mb={2} mt={1}>
            {translations.pages.loginPage.loginTitle}
          </Typography>
          <TextField
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            label={translations.pages.loginPage.usernameLabel}
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={formik.touched.email && formik.errors.email}
            variant="filled"
            size="small"
          />
          <TextField
            name={'password'}
            type={'password'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            label={translations.pages.loginPage.passwordLabel}
            error={formik.touched.password && formik.errors.password ? true : false}
            helperText={formik.touched.password && formik.errors.password}
            variant={'filled'}
            size={'small'}
          />
          <Box display={'flex'} justifyContent={'space-between'} mt={2}>
            <Typography>
              Don't have an account?{' '}
              <Button onClick={() => navigate('/signup')}>{translations.pages.loginPage.signupButtonText}</Button>
            </Typography>
            <Button
              variant="contained"
              style={{ width: 100 }}
              disabled={formik.isSubmitting}
              onClick={async () => {
                await formik.handleSubmit();
              }}
            >
              {translations.pages.loginPage.loginButtonText}
            </Button>
          </Box>

          {/* <Button
            variant="text"
            onClick={() => {
              navigate('/signup');
            }}
          >
            {translations.pages.loginPage.signupButtonText}
          </Button> */}
        </Box>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ login }, dispatch),
  };
}

export const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);
