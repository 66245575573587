import * as Yup from 'yup';

export const createCustomerSchema = {
  initialValues: {
    name: '',
    email: '',
    phone: '',
    country: '+91',
    laboratoryId: '',
    contactDetails: [
      {
        name: '',
        designation: '',
        department: '',
        email: '',
        phone: '',
        country: '+91',
      },
    ],
    address: [
      {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    ],
    bankingDetails: {
      bank: '',
      branch: '',
      accountName: '',
      accountNumber: '',
      ifscCode: '',
    },
    pan: '',
    gst: '',
    aadhar: '',
    drugLicenceNumber: '',
    paymentTerms: 0,
    creditLimit: 0,
  },
  validationSchema: Yup.object().shape({
    laboratoryId: Yup.string().optional(),
    name: Yup.string().required('Please enter customer name'),
    country: Yup.string().optional(),
    email: Yup.string().optional(),
    phone: Yup.number().optional(),
    contactDetails: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().optional(),
        designation: Yup.string().optional(),
        department: Yup.string().optional(),
        email: Yup.string().optional().email(),
        phone: Yup.string().min(10).optional(),
        country: Yup.string().optional(),
      })
    ),
    address: Yup.array().of(
      Yup.object().shape({
        line1: Yup.string().optional(),
        line2: Yup.string().optional(),
        city: Yup.string().optional(),
        state: Yup.string().optional(),
        zip: Yup.number().optional(),
      })
    ),
    bankingDetails: Yup.object().shape({
      bank: Yup.string().optional(),
      branch: Yup.string().optional(),
      accountName: Yup.string().optional(),
      accountNumber: Yup.string().optional(),
      ifscCode: Yup.string().optional(),
    }),
    pan: Yup.string().optional(),
    gst: Yup.string().optional(),
    aadhar: Yup.string().optional(),
    drugLicenceNumber: Yup.string().optional(),
    paymentTerms: Yup.number().optional(),
    creditLimit: Yup.number().optional(),
  }),
};
