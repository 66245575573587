import { ThemeProvider } from '@mui/material/styles';
import theme from 'configurations/theme';
import axios from 'axios';
import { Typography } from '@mui/material';
import { Provider } from 'react-redux';
import { Store, Persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import Navigations from 'Navigations';
import { SnackbarProvider } from 'notistack';
// @ts-ignore
import QuillBetterTable from 'quill-better-table';
import Quill from 'quill';

import TokenBlot from 'components/quill/TokenBlot';
import TokenDrop, { TOKEN_MODULE_NAME } from 'components/quill/TokenDrop';
import 'quill/dist/quill.snow.css';
import 'quill-better-table/dist/quill-better-table.css';

//@ts-ignore
Quill.register(TokenBlot);
Quill.register(`modules/${TOKEN_MODULE_NAME}`, TokenDrop);
Quill.register(
  {
    'modules/better-table': QuillBetterTable,
  },
  true
);

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

function App() {
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={Persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Navigations />
          </SnackbarProvider>
          {process.env.REACT_APP_ENV_TYPE === 'local' || process.env.REACT_APP_ENV_TYPE === 'staging' ? (
            <div
              className="no-print"
              style={{
                position: 'fixed',
                background: '#a76440',
                borderTopLeftRadius: 7,
                right: 0,
                bottom: 0,
                padding: 5,
                paddingLeft: 15,
                paddingRight: 15,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="button" color={'#fff'}>
                {process.env.REACT_APP_ENV_TYPE}
              </Typography>
            </div>
          ) : null}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
