import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ITestGroupTabel } from 'pages/TestGroup/TestGroupTable';

export default function _TestGroupTable(props: ITestGroupTabel) {
  const navigate = useNavigate();
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Prefix</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          {props.data.map((testGroupDetails) => (
            <TableRow key={testGroupDetails._id}>
              <TableCell>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(`/laboratory/testgroups/persist?id=${testGroupDetails._id}`);
                  }}
                >
                  {testGroupDetails.name}
                </Link>
              </TableCell>
              <TableCell>{testGroupDetails.prefix}</TableCell>
              <TableCell>{testGroupDetails.description}</TableCell>
              <TableCell align="right">
                <Button onClick={() => props.onEdit(testGroupDetails)}>Edit</Button>
                <Button color="error" onClick={() => props.onDelete(testGroupDetails)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  department: state.laboratory.current?.departments ?? [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const TestGroupTable = connect(mapStateToProps, mapDispatchToProps)(_TestGroupTable);
