import React, { useEffect, useState } from 'react';
import './invoice.css';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import A4 from 'PdfPages/pages/A4';
import { getInvoiceByIdAction } from 'actions/LaboratoryActions';
import useQuery from 'utils/getQueryParams';
import { ICustomer, IInvoice, ISample } from 'exactt-types/lib';
import moment from 'moment';

const _InvoicePrint = (props: {
  laboratory: null | ILaboratoryDetails;
  setDisableScroll: (value: boolean) => void;
}) => {
  let query = useQuery();
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoice | undefined>();

  const onFocus = async () => {
    const invoice = await getInvoiceByIdAction(props.laboratory?.details._id || '', query.get('id') || '');
    setInvoiceDetails(invoice);
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
  }, []);

  if (!invoiceDetails) return null;

  // @ts-ignore
  const customer = invoiceDetails.customer as ICustomer;

  return (
    <A4
      body={
        <div className="font-container">
          <div className="header-container">
            <div className="d-flex-space-between header-border">
              <div>
                <img className="logo" src={props.laboratory?.details?.logo} alt="Company Logo" />
              </div>
              <div className="right-text">
                <div className="bold-title company-name titillium-web-semibold item-header">
                  {props.laboratory?.details?.name}
                </div>
                <div className="header-text">
                  <div>GSTIN: {props.laboratory?.details?.gst}</div>
                  <div>{props.laboratory?.details?.address?.line1}</div>
                  <div>{props.laboratory?.details?.address?.line2}</div>
                  <div>
                    {props.laboratory?.details?.address?.city} - {props.laboratory?.details?.address?.zip}
                  </div>
                  <div>{props.laboratory?.details?.address?.state}, INDIA</div>
                </div>
              </div>
            </div>
            <div className="d-flex-space-between mt-5">
              <div>
                <div className="bold-title">Billed To:</div>
                {/* @ts-ignore */}
                <div className="bold-title company-name item-header">{customer.name}</div>
                <div className="header-text">
                  {customer.gst && (
                    <div>
                      GSTIN:
                      {/* @ts-ignore */}
                      <span className="normal-text">{customer.gst || ' N/A'}</span>
                    </div>
                  )}

                  <div>{customer.address?.[0]?.line1}</div>
                  <div>{customer.address?.[0]?.line2}</div>
                  <div>
                    {customer.address?.[0]?.city} - {customer.address?.[0]?.zip}, {customer.address?.[0]?.state}
                  </div>
                </div>
              </div>
              <div className="right-text">
                <div className="doc-title titillium-web-semibold item-header">INVOICE</div>
                <div className="bold-title titillium-web-semibold">Invoice No.</div>
                <div className="header-text">{invoiceDetails.invoiceNumber}</div>
                <div className="bold-title titillium-web-semibold">Date</div>
                <div className="header-text">{moment(invoiceDetails.invoiceDate).format('DD/MM/YYYY')}</div>
                <div className="bold-title titillium-web-semibold">Due Date</div>
                <div className="header-text">{moment(invoiceDetails.dueDate).format('DD/MM/YYYY')}</div>
              </div>
            </div>
          </div>
          <div className="main-container">
            <table className="item-table" style={{ width: '100%' }}>
              <thead>
                <tr className="item-header">
                  <th>SUMMARY</th>
                  <th>HSN/SAC</th>
                  <th>QTY.</th>
                  <th>RATE(INR)</th>
                  <th className="right-text">AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {(invoiceDetails.samples as any[]).map((sample: ISample, index: any) => (
                  <tr key={index} className="bottom-border">
                    <td>
                      <div className="bold-title titillium-web-semibold">{sample.name}</div>
                      <div style={{ fontSize: 12, marginTop: -5 }}>
                        <i>({sample.sampleId})</i>
                      </div>
                    </td>
                    <td>38220019</td>
                    <td>1</td>
                    <td>₹{sample.rate}</td>
                    <td className="right-text">₹{sample.rate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="total-container">
              <table className="total-table">
                <tbody>
                  <tr className="bottom-border">
                    <th>TOTAL</th>
                    <td></td>
                    <td className="right-text">₹ {invoiceDetails.grandTotal - invoiceDetails.totalTax}</td>
                  </tr>
                  {invoiceDetails.igst ? (
                    <tr className="bottom-border">
                      <th>IGST</th>
                      <td>@18%</td>
                      <td className="right-text">₹ {invoiceDetails.totalTax}</td>
                    </tr>
                  ) : (
                    <>
                      <tr className="bottom-border">
                        <th>CGST</th>
                        <td>@9%</td>
                        <td className="right-text">₹ {invoiceDetails.totalTax / 2}</td>
                      </tr>
                      <tr className="bottom-border">
                        <th>SGST</th>
                        <td>@9%</td>
                        <td className="right-text">₹ {invoiceDetails.totalTax / 2}</td>
                      </tr>
                    </>
                  )}

                  <tr className="bottom-border">
                    <th>SUB TOTAL</th>
                    <td></td>
                    <td className="right-text item-header bold-title">₹ {invoiceDetails.grandTotal}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="total-container mt-5" style={{ marginTop: '10mm' }}>
              This is a computer invoice and does not require any signature.
            </div>
            <div className="right-text">
              <b>Signature</b>
            </div>
            <div className="d-flex-space-between mt-5">
              <div>
                <div className="left-text">
                  <div className="bold-title">PAY VIA</div>
                  <div className="normal-text"> BANK TRANSFER</div>
                </div>
              </div>
              <div>
                <div className="bold-title">BANK DETAILS</div>

                <div>
                  <span className="bold-title">Bank Name:</span>
                  <span className="normal-text">{props.laboratory?.details?.bankingDetails?.bank}</span>
                </div>
                <div>
                  <span className="bold-title">Account Name:</span>
                  <span className="normal-text">{props.laboratory?.details?.bankingDetails?.accountName}</span>
                </div>
                <div>
                  <span className="bold-title">Account Number:</span>
                  <span className="normal-text">{props.laboratory?.details?.bankingDetails?.accountNumber}</span>
                </div>
                <div>
                  <span className="bold-title">Branch:</span>
                  <span className="normal-text">{props.laboratory?.details?.bankingDetails?.branch}</span>
                </div>
                <div>
                  <span className="bold-title">IFSC code:</span>
                  <span className="normal-text">{props.laboratory?.details?.bankingDetails?.ifscCode}</span>
                </div>
              </div>
              <div>
                <div className="left-text">
                  <div className="bold-title">TEXT DETAILS</div>
                  <div>
                    <span className="bold-title">GSTIN:</span>
                    <span className="normal-text">{props.laboratory?.details?.gst}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      header=""
    ></A4>
  );
};

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

const InvoicePrint = connect(mapStateToProps)(_InvoicePrint);

export default InvoicePrint;
