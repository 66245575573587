import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Divider, Grid, CircularProgress } from '@mui/material';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { useFormik } from 'formik';
import useQuery from 'utils/getQueryParams';
import { CreateDepartmentSchema } from 'FormSchema/CreateDepartmentSchema';
import { IFormikCreateDepartment, IPersistDepartmentProps } from './IDepartment';
import { useNavigate } from 'react-router-dom';
import { updateDepartment, createDepartment, getDepartmentById } from 'actions/LaboratoryActions';

function _PersistDepartment(props: IPersistDepartmentProps) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let query = useQuery();
  const departmentId = query.get('id');
  const laboratoryId = props.laboratory?.details._id || '';

  const formik: IFormikCreateDepartment = useFormik({
    initialValues: CreateDepartmentSchema.initialValues,
    validationSchema: CreateDepartmentSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (departmentId) {
          await props.updateDepartment(
            laboratoryId,
            {
              name: values.name,
              description: values.description,
              laboratoryId: laboratoryId,
              _id: departmentId,
            },
            '_id'
          );
          navigate('/laboratory/departments');
        } else {
          await props.createDepartment(laboratoryId, {
            name: values.name,
            description: values.description,
            laboratoryId: laboratoryId,
          });
          navigate('/laboratory/departments');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Departments',
      onClick: () => navigate('/laboratory/departments'),
    },
    {
      label: 'Create Department',
      onClick: () => navigate('/laboratory/departments/persist'),
    },
  ];

  const onFocus = async () => {
    try {
      if (departmentId) {
        const department = await props.getDepartmentById(laboratoryId, departmentId);
        formik.setValues({
          name: department.name,
          description: department?.description || '',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Create Department" />
      <Box p={2} pt={10}>
        {loading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={7}>
              <Box pt={2} pb={2}>
                <Typography variant="subtitle2">Basic department details</Typography>
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label={'Name'}
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={formik.touched.name && formik.errors.name}
                    type={'text'}
                    required
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Description'}
                    name={'description'}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description ? true : false}
                    helperText={formik.touched.description && formik.errors.description}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Box>
                  <Button
                    disabled={formik.isSubmitting}
                    variant="outlined"
                    onClick={() => navigate('/laboratory/departments')}
                  >
                    Back
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    {departmentId ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createDepartment, updateDepartment, getDepartmentById }, dispatch),
  };
}

export const PersistDepartment = connect(mapStateToProps, mapDispatchToProps)(_PersistDepartment);
