import { Box } from '@mui/material';
import GeneralHeader from 'PdfPages/components/GeneralHeader';
import GeneralTable from 'PdfPages/components/GeneralTable';
import GeneralFooter from 'PdfPages/components/GeneralFooter';
import { getSampleById } from 'actions/LaboratoryActions';
// import GeneralPwdHeader from 'PdfPages/components/PwdHeader';

// import GeneralPwdFooter from 'PdfPages/components/PwdFooter';

import A4 from 'PdfPages/pages/A4';
import { useEffect } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { IMaster, ISample, IUser } from 'exactt-types/lib';
import GeneralFields, { GeneralFieldsValue } from 'PdfPages/components/GeneralFields';
import GeneralFinalFooter from 'PdfPages/components/GeneralFinalFooter';
import moment from 'moment';

interface SampleResultPrintProps {
  setDisableScroll: (value: boolean) => void;
  getSampleById: (laboratoryId: string, _id: string) => Promise<any>;
  laboratory: ILaboratoryDetails | null;
  sampleDetails: ISample | null;
}

export function _SampleResultPrint(props: SampleResultPrintProps) {
  let query = useQuery();
  let sampleId = query.get('id');

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(props.laboratory?.details._id || '', sampleId);
      setTimeout(() => {
        // window.print();
      }, 1000 * 5);
      // setLoading(false);
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();

    // eslint-disable-next-line
  }, []);

  const values: GeneralFieldsValue[] = [];
  const valuesPart2: GeneralFieldsValue[] = [];
  const valuesPart3: GeneralFieldsValue[] = [];
  const parameters: any[] = [];

  if (props.sampleDetails) {
    values.push({ label: 'Nature of Sample', value: props.sampleDetails.genericName });
    values.push({ label: 'Grade/Variety/Type/Class/Size', value: props.sampleDetails.marking || '' });
    values.push({ label: 'Brand name if any', value: props.sampleDetails.brandName || '' });
    values.push({
      label: 'Batch No. & Date of Manufacture',
      value: `${props.sampleDetails.batchNo}, ${moment(props.sampleDetails.mfgDate).format('DD/MM/YYYY')}`,
    });
    values.push({ label: 'Quantity', value: props.sampleDetails.sampleQty || '' });
    values.push({ label: 'Mode of Packing', value: props.sampleDetails.samplePacking || '' });
    values.push({ label: 'Date of Receipt', value: moment(props.sampleDetails.collectionDate).format('DD/MM/YYYY') });
    values.push({ label: 'Any other Information', value: 'N/A' });
    values.push({ label: 'Used by Date', value: moment(props.sampleDetails.expDate).format('DD/MM/YYYY') });
    values.push({ label: 'Source water', value: props.sampleDetails.additionalFields?.['Water source'] });
    valuesPart2.push({
      label: 'Date of Sampling',
      value: moment(props.sampleDetails.collectionDate).format('DD/MM/YYYY'),
    });
    valuesPart2.push({
      label: 'Sampling Location ',
      value: props.sampleDetails.sampleCondition || '',
    });
    valuesPart2.push({
      label: 'Sampling Method and Plan ',
      value: props.sampleDetails.samplingMethod || '',
    });
    valuesPart2.push({
      label: 'Transportation environment condition ',
      value: props.sampleDetails.envCondition || '',
    });
    valuesPart2.push({
      label: 'Sample collected by lab Representative name ',
      value: typeof props.sampleDetails.collectionBy === 'string' ? props.sampleDetails.collectionBy || 'N/A' : '',
    });
    valuesPart3.push({
      label: 'Date of start of Testing',
      value: moment(props.sampleDetails.analysisDate).format('DD/MM/YYYY'),
    });
    valuesPart3.push({
      label: 'Date of Completion',
      value: moment(props.sampleDetails.completeDate).format('DD/MM/YYYY'),
    });

    props.sampleDetails?.parameters?.forEach((parameter) => {
      // find parameter from master list (props.sampleDetails?.master?.parameters)
      const masterParameter = (props?.sampleDetails?.master as IMaster)?.parameters?.find(
        (p) => p.name === parameter.name
      );

      parameters.push({
        characteristic: parameter.name,
        results: parameter.result,
        requirements: parameter.requirements,
        permissibleLimit: masterParameter?.acceptableLimit || '-',
        method: masterParameter?.method,
        group: masterParameter?.resultGroup,
      });
    });
  }

  return (
    <Box>
      <A4
        header={<GeneralHeader laboratory={props.laboratory} sample={props.sampleDetails} />}
        body={
          <>
            <Box mt={1} fontWeight={'bold'}>
              Part A: Particular of Sample Submitted
            </Box>
            <GeneralFields values={values} labelWidth={180} />
            <Box mt={1} fontWeight={'bold'}>
              Part B: Sampling Details
            </Box>
            <GeneralFields values={valuesPart2} autoWidth />
            <Box mt={1} fontWeight={'bold'}>
              Part C: Test Result
            </Box>
            <GeneralFields fontWeight="bold" values={valuesPart3} autoWidth />
            <Box>
              <span style={{ fontWeight: 'bold' }}>Description :</span>
              <span> {props.sampleDetails?.description}</span>
            </Box>
            <Box mt={1}>
              <GeneralTable fields={parameters} />
            </Box>
          </>
        }
        footer={<GeneralFooter sample={props.sampleDetails} laboratory={props.laboratory} />}
        finalFooter={<GeneralFinalFooter />}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
      },
      dispatch
    ),
  };
}

export const SampleResultPrint = connect(mapStateToProps, mapDispatchToProps)(_SampleResultPrint);
