import { Box } from '@mui/material';
import { useEffect } from 'react';
import useQuery from 'utils/getQueryParams';
import { editorTokens } from 'pages/ResultTemplate/PersistResultTemplate/editorTokens';
import './print.css';
import QRCode from 'react-qr-code';

const tokensById: any = {};
editorTokens.forEach((token) => {
  tokensById[token.id] = token;
});

interface SampleResultPrintProps {
  setDisableScroll: (value: boolean) => void;
}

export function SampleLabel(props: SampleResultPrintProps) {
  let query = useQuery();

  let sampleId = query.get('sampleId') as string;
  let labelCount = Number(query.get('labelCount') as string) || '1';

  const onFocus = async () => {
    if (sampleId) {
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      {/* render below code in loop based   */}
      {[...Array(labelCount)].map((_, index) => (
        <Box
          key={index}
          style={{
            width: '52mm',
            height: '23.5mm',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '1mm',
          }}
        >
          {/* <QRCode
            size={10}
            style={{ width: 'auto', maxHeight: '50%', height: '50%' }}
            value={sampleId}
            viewBox={`0 0 53 53`}
            level="L"
          /> */}
          <Box
            style={{
              width: '100%',
              textAlign: 'center',
              marginTop: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                fontSize: 30,
                fontWeight: 'bolder',
                fontFamily: 'arial',
                maxWidth: '90%',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {/* {sampleId} */}
              {sampleId.split('/').map((item, index) => (
                <div>
                  {item}
                  {sampleId.split('/').length - 1 === index ? '' : '/'}
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
