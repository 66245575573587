import { useEffect, useState } from 'react';
import { Box, Button, Pagination, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { IHeader } from 'components/Header';
import { searchTestGroupList, deleteTestGroup } from 'actions/LaboratoryActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { ITestGroupProps } from 'pages/TestGroup';
import { DeleteDialog } from 'components/DeleteDialog';
import { ITestGroup } from 'exactt-types/lib';
import { TestGroupTable } from './TestGroupTable';

function _TestGroup(props: ITestGroupProps) {
  const navigate = useNavigate();

  const [deleteTestGroupData, setDeleteTestGroup] = useState<ITestGroup | undefined>(undefined);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // pagination
  const [page, setPage] = useState(1);
  const size = 15;

  const laboratoryId = props.laboratory?.details._id || '';

  const onFocus = async () => {
    await props.searchTestGroupList(laboratoryId, { page: page, size: size, query: searchQuery });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.searchTestGroupList(laboratoryId, { page: value, size: size, query: searchQuery });
  };
  const handleDeleteTestGroup = async () => {
    try {
      setDeleteLoading(true);
      await props.deleteTestGroup(laboratoryId, deleteTestGroupData?._id || '', {
        page: 1,
        size: size,
        query: searchQuery,
      });
    } catch (err) {
      console.log(err);
    } finally {
      props.searchTestGroupList(laboratoryId, { page: page, size: size, query: searchQuery });
      setDeleteModal(false);
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    onFocus();
    props.searchTestGroupList(laboratoryId || '', { page: page, size: size, query: searchQuery });
    // eslint-disable-next-line
  }, [searchQuery]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Test Groups',
      onClick: () => navigate('/laboratory/testgroups'),
    },
  ];

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Test Groups"
        right={
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <TextField
              label={'Search'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => navigate('/laboratory/testgroups/persist')}
            >
              Create Test Group
            </Button>
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <TestGroupTable
          data={props.testGroups}
          onDelete={(testGroup) => {
            setDeleteTestGroup(testGroup);
            setDeleteModal(true);
          }}
          onEdit={(testGroup) => navigate(`/laboratory/testgroups/persist?id=${testGroup._id}`)}
        />
      </Box>
      <DeleteDialog
        title="Remove test group"
        message={`Are you sure you want to remove ${deleteTestGroupData?.name} test group?`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => handleDeleteTestGroup()}
        isLoading={deleteLoading}
      />

      <Stack spacing={2}>
        <Pagination count={props.count} shape="rounded" page={page} onChange={handlePageChange} defaultPage={page} />
      </Stack>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    testGroups: state.laboratory.testGroups,
    count: state.laboratory.count.testGroup,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchTestGroupList, deleteTestGroup }, dispatch),
  };
}

export const TestGroup = connect(mapStateToProps, mapDispatchToProps)(_TestGroup);
