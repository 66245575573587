import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, TableBody, Link } from '@mui/material';
import { IDepartmentTable } from './IDepartmentTable';
import { useNavigate } from 'react-router-dom';

export default function _DepartmentTable(props: IDepartmentTable) {
  const navigate = useNavigate();
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {/* Mapp code */}
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/laboratory/departments/persist?id=${row._id}`);
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => props.onEdit(row)}>Edit</Button>
                  <Button color="error" onClick={() => props.onDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  departments: state.laboratory.current?.departments || [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const DepartmentTable = connect(mapStateToProps, mapDispatchToProps)(_DepartmentTable);
