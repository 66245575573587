import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { ISample } from 'exactt-types/lib';

export default function GeneralFooter(props: { sample: ISample | null; laboratory: ILaboratoryDetails | null }) {
  if (!props.sample) return null;

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10mm', marginTop: 40 }}>
      <div>
        <strong>{(props.sample.reviewedBy as any).name}</strong>
        <br />
        <strong style={{ fontSize: 12 }}>Reviewed By</strong>
      </div>
      <div>
        <img
          alt="stem"
          src={props.laboratory?.details.stampImage || props.laboratory?.details.logo}
          style={{ width: '25mm', marginTop: -10 }}
        />
      </div>
      <div>
        <strong>{(props.sample.authorizedSignature1 as any).name}</strong>
        <br />
        <strong style={{ fontSize: 12 }}>Authorized Signatory</strong>
      </div>
      {props.sample.authorizedSignature2 ? (
        <div>
          <strong>{(props.sample.authorizedSignature2 as any).name}</strong>
          <br />
          <strong style={{ fontSize: 12 }}>Authorized Signatory</strong>
        </div>
      ) : null}
    </div>
  );
}
