import * as Yup from 'yup';

export const CreateDepartmentSchema = {
  initialValues: {
    name: '',
    description: '',
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().optional(),
  }),
};
