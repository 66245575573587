import { ICreateLaboratoryTranslation } from 'pages/CreateLaboratory';

export const createLaboratoryPage: ICreateLaboratoryTranslation = {
  step1Lable: 'Laboratory Details',
  step1Description: 'Fill up your laboratory details',
  step2Lable: 'Location Details',
  step2Description: 'Fill up your laboratory’s location details',
  step3Lable: 'Banking Details',
  step3Description: 'Fill up your laboratory’s backing details',
  step4Lable: 'Other Details',
  step4Description: 'Fill up your laboratory’s other details',
  step5Lable: 'Preview',
  step5Description: 'Please verify all details before continue',
  forms: {
    basicDetails: {
      title: 'Set up your organization',
      subTitle: 'Tell us about your laboratory',
      labName: 'Laboratory Name',
      contactPerson: 'Contact Person',
      labType: 'Laboratory Type',
      code: 'Code',
      phoneNumber: 'Phone Number',
      email: 'Email',
      website: 'Website',
    },
    locationDetails: {
      addressLine1: 'Address line 1',
      addressLine2: 'Address line 2',
      city: 'City',
      state: 'State',
      zipCode: 'Zip code',
    },
    bankingDeatils: {
      accountName: 'Account name',
      accountNumber: 'Account number',
      bank: 'Bank',
      branch: 'Branch',
      ifscCode: 'IFSC Code',
    },
    otherDetails: {
      gstNumber: 'GST Number',
      panNumber: 'PAN Number',
      nablNumber: 'NABL lic Number',
      drugNumber: 'Drug lic number',
      otherNumber: 'Other lic number',
    },
  },
};
