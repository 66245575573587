import * as Yup from 'yup';

export const createReferenceSchema = {
  initialValues: {
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: 0,
    },
    phone: '',
    companyName: '',
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.object().optional(),
    phone: Yup.string().optional(),
  }),
};
