import { Login } from 'pages/Login';
import { Signup } from 'pages/Signup';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

export default function AuthNavigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="signup" element={<Signup />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
