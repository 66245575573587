import { IBankingDetailsForm } from 'pages/CreateLaboratory/BankingDetailsForm';
import { Grid, TextField, Button } from '@mui/material';
import translations from 'utils/translations';

export function BankingDetailsForm(props: IBankingDetailsForm) {
  const { formik, onBack, onNext } = props;

  const isValid =
    formik.errors.bank ||
    formik.errors.branch ||
    formik.errors.accountName ||
    formik.errors.accountNumber ||
    formik.errors.ifscCode
      ? false
      : true;

  return (
    <Grid container mt={1}>
      <Grid item xs={12}>
        <TextField
          name="bank"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.bank}
          label={translations.pages.createLaboratoryPage.forms.bankingDeatils.bank}
          error={formik.touched.bank && formik.errors.bank ? true : false}
          helperText={formik.touched.bank && formik.errors.bank}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="branch"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.branch}
          label={translations.pages.createLaboratoryPage.forms.bankingDeatils.branch}
          error={formik.touched.branch && formik.errors.branch ? true : false}
          helperText={formik.touched.branch && formik.errors.branch}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="accountName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accountName}
          label={translations.pages.createLaboratoryPage.forms.bankingDeatils.accountName}
          error={formik.touched.accountName && formik.errors.accountName ? true : false}
          helperText={formik.touched.accountName && formik.errors.accountName}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="accountNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.accountNumber}
          label={translations.pages.createLaboratoryPage.forms.bankingDeatils.accountNumber}
          error={formik.touched.accountNumber && formik.errors.accountNumber ? true : false}
          helperText={formik.touched.accountNumber && formik.errors.accountNumber}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="ifscCode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.ifscCode}
          label={translations.pages.createLaboratoryPage.forms.bankingDeatils.ifscCode}
          error={formik.touched.ifscCode && formik.errors.ifscCode ? true : false}
          helperText={formik.touched.ifscCode && formik.errors.ifscCode}
          variant="outlined"
          size="small"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} display={'flex'} flexDirection={'row-reverse'} mt={1}>
        <Button sx={{ marginRight: 1 }} variant={'contained'} disabled={!isValid} onClick={() => onNext()}>
          {translations.components.steps.nextButton}
        </Button>
        <Button sx={{ marginRight: 1 }} onClick={() => onBack()}>
          {translations.components.steps.backButton}
        </Button>
      </Grid>
    </Grid>
  );
}
