import axios, { AxiosResponse } from 'axios';
import { IAccess } from 'exactt-types/lib';
import { IInviteUserRequst } from 'services/UserServices';

export const inviteUserService = async (request: IInviteUserRequst): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${request.laboratoryId}/invite`, request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getLaboratoryUsersList = async (request: { laboratoryId: string }): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${request.laboratoryId}/users`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const editLaboratoryUser = async (request: IAccess): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${request.laboratoryId}/user`, request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const removeLaboratoryUser = async (request: {
  laboratoryId: string;
  accessId: string;
}): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${request.laboratoryId}/user/${request.accessId}`);
    return res;
  } catch (err) {
    throw err;
  }
};
