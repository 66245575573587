import { Box } from '@mui/material';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import React from 'react';

interface IDocumentLatterHeadProps {
  laboratory: ILaboratoryDetails | null;
}

export default function DocumentLatterHead(props: IDocumentLatterHeadProps) {
  if (!props.laboratory) return null;
  return (
    <Box display={'flex'} border={'1px solid #000'} p={2}>
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 14, fontWeight: 'bold' }}>{props.laboratory.details.name}</div>
        <div style={{ fontSize: 14 }}>{props?.laboratory?.details?.address?.line1}</div>
        <div style={{ fontSize: 14 }}>{props?.laboratory?.details?.address?.line2}</div>
        <div style={{ fontSize: 14 }}>
          {props?.laboratory?.details?.address?.city}, {props?.laboratory?.details?.address?.state} -{' '}
          {props?.laboratory?.details?.address?.zip}
        </div>
      </div>
      <div>
        <img src={props.laboratory.details.logo} alt="logo" style={{ width: '40mm', objectFit: 'contain' }} />
      </div>
    </Box>
  );
}
