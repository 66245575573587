import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box } from '@mui/material';
import { IUserTabel } from 'pages/User/UserTable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IDepartment } from 'exactt-types/lib';

function _UserTable(props: IUserTabel) {
  const findDeprtmentById = (id: string): IDepartment | undefined => {
    return props.department.find((item) => item._id === id);
  };

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="right">Department</TableCell>
              <TableCell align="right">Role</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {props.data.map((userDetails) => (
            <TableRow key={userDetails._id}>
              <TableCell>{userDetails.user.name}</TableCell>
              <TableCell>{userDetails.user.email}</TableCell>
              <TableCell>
                {userDetails.user.country}-{userDetails.user.phone}
              </TableCell>
              <TableCell align="right">{findDeprtmentById(userDetails.departmentId)?.name}</TableCell>
              <TableCell align="right">{userDetails.role}</TableCell>
              <TableCell align="right">
                <Button onClick={() => props.onEdit(userDetails)}>Edit</Button>
                <Button color="error" onClick={() => props.onDelete(userDetails)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  department: state.laboratory.current?.departments ?? [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const UserTable = connect(mapStateToProps, mapDispatchToProps)(_UserTable);
