import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Divider, Grid, CircularProgress } from '@mui/material';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IFormikCreateReference, IPersistReferenceProps } from 'pages/Reference/PersistReference';
import { useFormik } from 'formik';
import { updateReference, getReferenceId, createReference } from 'actions/LaboratoryActions';
import useQuery from 'utils/getQueryParams';
import { createReferenceSchema } from 'FormSchema/CreateReferenceSchema';

function _PersistReference(props: IPersistReferenceProps) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let query = useQuery();
  const referenceId = query.get('id');
  const laboratoryId = props.laboratory?.details._id || '';

  const formik: IFormikCreateReference = useFormik({
    initialValues: createReferenceSchema.initialValues,
    validationSchema: createReferenceSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        if (referenceId) {
          await props.updateReference(laboratoryId, {
            name: values.name,
            companyName: values.companyName,
            address: {
              line1: values.address.line1,
              line2: values.address.line2,
              city: values.address.city,
              state: values.address.state,
              zip: values.address.zip,
            },
            laboratoryId: laboratoryId,
            phone: values.phone,
            _id: referenceId,
          });
          navigate('/laboratory/references');
        } else {
          await props.createReference(laboratoryId, {
            name: values.name,
            companyName: values.companyName,
            address: {
              line1: values.address.line1,
              line2: values.address.line2,
              city: values.address.city,
              state: values.address.state,
              zip: values.address.zip,
            },
            laboratoryId: laboratoryId,
            phone: values.phone,
          });
          navigate('/laboratory/references');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'References',
      onClick: () => navigate('/laboratory/references'),
    },
    {
      label: 'Create reference',
      onClick: () => navigate('/laboratory/reference/persist'),
    },
  ];

  const onFocus = async () => {
    try {
      if (referenceId) {
        const reference = await props.getReferenceId(laboratoryId, referenceId);
        formik.setValues({
          name: reference.name,
          address: {
            line1: reference.address?.line1 || '',
            line2: reference.address?.line2 || '',
            city: reference.address?.city || '',
            state: reference.address?.state || '',
            zip: reference.address?.zip || 0,
          },
          phone: reference.phone || '',
          companyName: reference.companyName || '',
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Create Reference" />
      <Box p={2} pt={10}>
        {loading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={7}>
              <Box pt={2} pb={2}>
                <Typography variant="subtitle2">Basic reference details</Typography>
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label={'Name'}
                    name={'name'}
                    required
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={formik.touched.name && formik.errors.name}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Phone'}
                    name={'phone'}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && formik.errors.phone ? true : false}
                    helperText={formik.touched.phone && formik.errors.phone}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={'Company Name'}
                    name={'companyName'}
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.companyName && formik.errors.companyName ? true : false}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <TextField
                label={'Address Line 1'}
                name={'address.line1'}
                value={formik.values.address.line1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // @ts-ignore: Unreachable code error
                error={formik.touched.address?.line1 && formik.errors.address?.line1 ? true : false}
                // @ts-ignore: Unreachable code error
                helperText={formik.touched.address?.line1 && formik.errors.address?.line1}
                type={'text'}
                fullWidth
                variant="outlined"
              />
              <TextField
                label={'Address Line 2'}
                name={'address.line2'}
                value={formik.values.address.line2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // @ts-ignore: Unreachable code error
                error={formik.touched.address?.line2 && formik.errors.address?.line2 ? true : false}
                // @ts-ignore: Unreachable code error
                helperText={formik.touched.address?.line2 && formik.errors.address?.line2}
                type={'text'}
                fullWidth
                variant="outlined"
              />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    label={'City'}
                    name={'address.city'}
                    value={formik.values.address.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // @ts-ignore: Unreachable code error
                    error={formik.touched.address?.city && formik.errors.address?.city ? true : false}
                    // @ts-ignore: Unreachable code error
                    helperText={formik.touched.address?.city && formik.errors.address?.city}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={'State'}
                    name={'address.state'}
                    value={formik.values.address.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // @ts-ignore: Unreachable code error
                    error={formik.touched.address?.state && formik.errors.address?.state ? true : false}
                    // @ts-ignore: Unreachable code error
                    helperText={formik.touched.address?.state && formik.errors.address?.state}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={'Zip'}
                    name={'address.zip'}
                    value={formik.values.address.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // @ts-ignore: Unreachable code error
                    error={formik.touched.address?.zip && formik.errors.address?.zip ? true : false}
                    // @ts-ignore: Unreachable code error
                    helperText={formik.touched.address?.zip && formik.errors.address?.zip}
                    type={'number'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Box>
                  <Button
                    disabled={formik.isSubmitting}
                    variant="outlined"
                    onClick={() => navigate('/laboratory/references')}
                  >
                    Back
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    variant="contained"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    {referenceId ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createReference, updateReference, getReferenceId }, dispatch),
  };
}

export const PersistReference = connect(mapStateToProps, mapDispatchToProps)(_PersistReference);
