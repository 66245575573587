import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      background: "#15324e",
      minWidth: 50,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    drawer: {
      background: theme.palette.background.default,
      width: 250,
    },
    logo: {
      width: 35,
    },
  })
);

export default useStyles;
