import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import useStyles from './styles';
import translations from 'utils/translations';
import { ISignup } from './ISignup';
import { countryPhoneCodes } from 'utils/countryPhoneCodes';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { signup } from 'actions/AuthActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const options = countryPhoneCodes.map((item) => ({ label: `${item.dial_code} ${item.name}`, value: item.dial_code }));

const logo = require('assets/images/exact.png');
const bg = require('assets/images/unauth-bg.jpg');

function _Signup(props: ISignup) {
  const classes = useStyles();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email.').required('Required'),
    name: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    confimPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const initialValues = {
    name: '',
    country: '+91',
    phone: '',
    email: '',
    password: '',
    confimPassword: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const signupRequest = {
          name: values.name,
          country: values.country,
          phone: values.phone,
          email: values.email,
          password: values.password,
        };
        await props.signup(signupRequest);
        navigate('/');
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Box display={'flex'} height={'100vh'}>
      <Grid container display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img src={logo} className={classes.logo} alt="logo" />
              <Typography variant="h5" mt={3}>
                EXACT Laboratory Software
              </Typography>
              <Typography variant="subtitle1" mt={3}>
                {translations.pages.signupPage.signupSubTitle}
              </Typography>
            </Box>

            <TextField
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.touched.email && formik.errors.email}
              label={translations.pages.signupPage.usernameLabel}
              variant="standard"
              size="small"
              required
            />
            <TextField
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name ? true : false}
              helperText={formik.touched.name && formik.errors.name}
              label={translations.pages.signupPage.nameLabel}
              variant="standard"
              size="small"
              required
            />
            <Grid container width={'100%'}>
              <Grid item xs={4} pr={1}>
                <FormControl variant="standard" size="small" sx={{ width: '100%' }} required>
                  <InputLabel id="country-select-label">{translations.pages.signupPage.countryLabel}</InputLabel>
                  <Select
                    labelId="country-select-label"
                    id="country-select"
                    name="country"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    error={formik.touched.country && formik.errors.country ? true : false}
                    label={translations.pages.signupPage.countryLabel}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {options.map((item) => (
                      <MenuItem value={item.value} key={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  error={formik.touched.phone && formik.errors.phone ? true : false}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label={translations.pages.signupPage.phoneLabel}
                  variant="standard"
                  size="small"
                  fullWidth={true}
                  required
                />
              </Grid>
            </Grid>
            <TextField
              name={'password'}
              type={'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && formik.errors.password ? true : false}
              helperText={formik.touched.password && formik.errors.password}
              label={translations.pages.signupPage.passwordLabel}
              variant={'standard'}
              size={'small'}
              required
            />
            <TextField
              name="confimPassword"
              type={'password'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confimPassword}
              error={formik.touched.confimPassword && formik.errors.confimPassword ? true : false}
              helperText={formik.touched.confimPassword && formik.errors.confimPassword}
              label={translations.pages.signupPage.confirmPasswordLabel}
              variant="standard"
              size="small"
              required
            />

            <Button
              fullWidth={false}
              disabled={formik.isSubmitting}
              variant="contained"
              onClick={async () => await formik.handleSubmit()}
            >
              {translations.pages.signupPage.signupButtonText}
            </Button>
            <Button variant="text" disabled={formik.isSubmitting} onClick={() => navigate('/')}>
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
      <img src={bg} alt="bg" style={{ height: '100vh', width: 500 }} />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ signup }, dispatch),
  };
}

export const Signup = connect(mapStateToProps, mapDispatchToProps)(_Signup);
