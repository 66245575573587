import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Box, TableBody, Button } from '@mui/material';
import { ITestResultTable } from './ITestResultTable';

export default function _TestResultTable(props: ITestResultTable) {
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          {/* Mapp code */}
          <TableBody>
            {props.data.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>-</TableCell>
                <TableCell align="right">
                  <Button onClick={() => props.onEdit(row)}>Edit</Button>
                  <Button color="error" onClick={() => props.onDelete(row)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  departments: state.laboratory.current?.departments || [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const TestResultTable = connect(mapStateToProps, mapDispatchToProps)(_TestResultTable);
