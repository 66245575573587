import { useEffect, useState } from 'react';
import { ICustomerProps } from 'pages/Customer';
import { Box, Button, Pagination, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { IHeader } from 'components/Header';
import { deleteCustomerById, searchCustomer } from 'actions/CustomerActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { CustomerTable } from './CustomerTable/CustomerTable';
import { ICustomer } from 'exactt-types/lib';
import { DeleteDialog } from 'components/DeleteDialog';

function _Customer(props: ICustomerProps) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<undefined | ICustomer>(undefined);

  const [searchQuery, setSearchQuery] = useState('');

  const [page, setPage] = useState(1);
  const size = 15;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.searchCustomer(props.laboratory?.details?._id || '', { page: value, size: size, query: searchQuery });
  };

  const onFocus = async () => {
    await props.searchCustomer(props.laboratory?.details._id || '', { page: page, size: size, query: searchQuery });
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Customers',
      onClick: () => navigate('/laboratory/customers'),
    },
  ];

  const handleDeleteCustomer = () => {
    try {
      setDeleteLoading(true);
      props.deleteCustomerById(props.laboratory?.details._id || '', selectedCustomer?._id || '');
      setDeleteModal(false);
      setSelectedCustomer(undefined);
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      onFocus();
    }
  };

  useEffect(() => {
    props.searchCustomer(props.laboratory?.details?._id || '', { page: page, size: size, query: searchQuery });
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Customers"
        right={
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <TextField
              label={'Search'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => navigate('/laboratory/customers/persist')}
            >
              Add Customer
            </Button>
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <CustomerTable
          data={props.customers || []}
          onDelete={(customer) => {
            setSelectedCustomer(customer);
            setDeleteModal(true);
          }}
          onEdit={(customer) => {
            navigate(`/laboratory/customers/persist?id=${customer._id}`);
          }}
        />
      </Box>
      <DeleteDialog
        title="Delete Customer"
        message={`Are you sure you want to remove ${selectedCustomer?.name} customer?`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => handleDeleteCustomer()}
        isLoading={deleteLoading}
      />
      <Stack spacing={2}>
        <Pagination count={props.count} shape="rounded" page={page} onChange={handlePageChange} defaultPage={page} />
      </Stack>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    customers: Array.isArray(state.laboratory.customer) ? state.laboratory.customer : [state.laboratory.customer],
    count: state.laboratory.count.customer,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ deleteCustomerById, searchCustomer }, dispatch),
  };
}

export const Customer = connect(mapStateToProps, mapDispatchToProps)(_Customer);
