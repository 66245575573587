import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { PersistUserModal } from 'pages/User/PersistUserModal';
import { IUser } from 'pages/User';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { getUserList, removeUserAccess } from 'actions/UserAction';
import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import { UserTable } from 'pages/User/UserTable';
import { ILaboratoryUser } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { DeleteDialog } from 'components/DeleteDialog';

export function _User(props: IUser) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [inviteModal, setInviteModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<ILaboratoryUser | undefined>(undefined);
  const [editUser, setEditUser] = useState<ILaboratoryUser | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const fetchLaboratoryUsers = async () => {
    let batch: any[] = [];
    batch.push(props.getUserList({ laboratoryId: props.laboratory?.details._id || '' }));
    await Promise.all(batch);
    setLoading(false);
  };

  const handleSetEditUser = (user: ILaboratoryUser) => {
    setEditUser(user);
    setInviteModal(true);
  };

  const handleSetDeleteUser = (user: ILaboratoryUser) => {
    setDeleteUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    await props.removeUserAccess({
      laboratoryId: props.laboratory?.details._id || '',
      accessId: deleteUser?._id ?? '',
    });
    await fetchLaboratoryUsers();
    setDeleteUser(undefined);
    setDeleteModal(false);
    setDeleteLoading(false);
  };

  useEffect(() => {
    if (!inviteModal) setEditUser(undefined);
  }, [inviteModal]);

  useEffect(() => {
    fetchLaboratoryUsers();
    // eslint-disable-next-line
  }, []);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Users',
      onClick: () => navigate('/laboratory/users'),
    },
  ];

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Users"
        right={
          <Button variant={'contained'} startIcon={<AddIcon />} onClick={() => setInviteModal(true)}>
            Add user
          </Button>
        }
      />
      <Box p={2} pt={10}>
        {loading ? (
          <ScreenLoader />
        ) : (
          <UserTable
            data={props.userList}
            onEdit={(user) => handleSetEditUser(user)}
            onDelete={(user) => handleSetDeleteUser(user)}
          />
        )}
      </Box>

      <DeleteDialog
        title="Remove user"
        message={`Are you sure you want to remove ${deleteUser?.user?.name}?`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={() => handleDeleteUser()}
        isLoading={deleteLoading}
      />
      <PersistUserModal isOpen={inviteModal} onClose={() => setInviteModal(false)} editUser={editUser} />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  department: state.department.list,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ getUserList, removeUserAccess }, dispatch),
  };
}

export const User = connect(mapStateToProps, mapDispatchToProps)(_User);
