import _ from 'lodash';
import React from 'react';

export interface IGeneralTableProps {
  fields: {
    characteristic: string;
    results: string;
    requirements: string;
    permissibleLimit: string;
    method: string;
    group: string;
    unit: string;
  }[];
}

export default function GeneralTable(props: IGeneralTableProps) {
  const fieldsWithGroups = _.groupBy(props.fields, 'group');

  console.log('fieldsWithGroups -> ', props.fields);

  return (
    <>
      {Object.keys(fieldsWithGroups).map((group, gidx) => {
        const fields = fieldsWithGroups[group];

        return (
          <div className="table-with-border" style={{ width: '100%', marginTop: 20 }}>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th colSpan={6} style={{ textAlign: 'left' }}>
                    {group === 'undefined' ? 'N/A' : group}
                  </th>
                </tr>
                <tr>
                  <th style={{ width: '10mm' }}>Sr.No.</th>
                  <th>Characteristic</th>
                  <th style={{ width: '30mm' }}>Results</th>
                  <th style={{ width: '30mm' }}> MaxRequirement (Acceptable Limit)</th>
                  <th style={{ width: '30mm' }}>Permissible Limit in absence of Alternate source </th>
                  <th style={{ width: '30mm' }}>Method of Test, Ref. IS or As per SOP No</th>
                </tr>
              </thead>
              <tbody>
                {fields.map((field, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{field.characteristic}</td>
                      <td>
                        {field.results} {field.unit}
                      </td>
                      <td>{field.requirements}</td>
                      <td>{field.permissibleLimit}</td>
                      <td>{field.method}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}
