import { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IRegisterProps } from './IRegister';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import { ITestGroup } from 'exactt-types/lib';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import AsyncSelect from 'react-select/async';
import { searchTestGroupList } from 'actions/LaboratoryActions';

function _Register(props: IRegisterProps) {
  const navigate = useNavigate();
  const laboratoryId = props.laboratory?.details._id || '';
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedTestGroup, setSelectedTestGroup] = useState<ITestGroup | null>(null);

  useEffect(() => {
    props.searchTestGroupList(laboratoryId, { page: 1, size: 100, query: '' });
  }, []);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Register',
      onClick: () => navigate('/laboratory/register'),
    },
  ];

  const testGroupOptions = async (inputValue: string) => {
    const list = await props.searchTestGroupList(laboratoryId, { page: 1, size: 20, query: inputValue });
    return list.data.testGroups.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Register" />
      <Box
        pt={12}
        ml={3}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
        flexDirection={'column'}
        width={400}
      >
        <Box display={'flex'} justifyContent={'center'} alignContent={'center'} gap={1}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Start Date"
              inputFormat="DD-MM-YYYY"
              value={moment(startDate, 'YYYY-MM-DD').toDate()}
              onChange={(data) => {
                setStartDate(moment(data).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="End Date"
              inputFormat="DD-MM-YYYY"
              value={moment(endDate, 'YYYY-MM-DD').toDate()}
              onChange={(data) => {
                setEndDate(moment(data).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <Box justifyContent={'center'} alignContent={'center'} gap={1} mt={1} mb={1}>
          <CustomFieldWithLabel width={120} label="Test Group">
            <AsyncSelect
              loadOptions={testGroupOptions}
              value={{ value: selectedTestGroup?._id, label: selectedTestGroup?.name }}
              defaultOptions={(props.testGroupsList || []).map((i) => ({
                label: i.name,
                value: i._id,
              }))}
              onChange={(newValue: any) => {
                const testGroup = props.testGroupsList?.find((i) => i._id === newValue?.value);
                if (testGroup) setSelectedTestGroup(testGroup);
              }}
            />
          </CustomFieldWithLabel>
        </Box>

        <Button
          variant={'contained'}
          color={'primary'}
          sx={{ height: '40px', marginTop: '8px' }}
          onClick={() => {
            window.open(
              `${window.location.origin}/laboratory/print/inward?from=${startDate}&to=${endDate}&testGroup=${selectedTestGroup?._id}`,
              'Print',
              'width=1200,height=500'
            );
          }}
        >
          Inward / Outward
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            window.open(
              `${window.location.origin}/laboratory/print/retained?from=${startDate}&to=${endDate}&testGroup=${selectedTestGroup?._id}`,
              'Print',
              'width=1080,height=500'
            );
          }}
          sx={{ height: '40px', marginTop: '8px' }}
        >
          Retained / Discarded
        </Button>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    testGroupsList: state.laboratory.testGroups,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        searchTestGroupList,
      },
      dispatch
    ),
  };
}

export const Register = connect(mapStateToProps, mapDispatchToProps)(_Register);
