import { IPersistCustomer, IFormikCreateCustomer } from 'pages/Customer/PersistCustomer';
import { TextField, Button, Box, Grid, InputLabel, Select, MenuItem, FormControl, FormHelperText } from '@mui/material';
import { countryPhoneCodes } from 'utils/countryPhoneCodes';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createCustomerSchema } from 'FormSchema/CreateCustomerSchema';
import { addCustomer, getCustomerById, updateCustomerById } from 'actions/CustomerActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { ICustomer } from 'exactt-types/lib';
import useQuery from 'utils/getQueryParams';
import { useEffect, useState } from 'react';
import { ISteps, Steps } from 'components/Steps';

const options = countryPhoneCodes
  .filter((i) => i.code === 'IN')
  .map((item) => ({ label: `${item.dial_code} ${item.name}`, value: item.dial_code }));

function _PersistCustomer(props: IPersistCustomer) {
  const navigate = useNavigate();
  let query = useQuery();
  const customerId = query.get('id');
  const [step, setStep] = useState(0);

  const formik: IFormikCreateCustomer = useFormik({
    initialValues: createCustomerSchema.initialValues,
    validationSchema: createCustomerSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const request: ICustomer = {
          // @ts-ignore: Unreachable code error
          address: [...values.address],
          bankingDetails: values.bankingDetails,
          contactDetails: values.contactDetails.map((i) => ({ ...i, phone: i.phone.toString() })),
          country: values.country,
          email: values.email,
          gst: values.gst,
          laboratoryId: props.laboratory?.details?._id || '',
          name: values.name,
          pan: values.pan,
          phone: values.phone.toString(),
          aadhar: values.aadhar,
          creditLimit: values.creditLimit,
          drugLicenceNumber: values.drugLicenceNumber,
          paymentTerms: values.paymentTerms,
        };
        if (!customerId) {
          await props.addCustomer(request);
        } else {
          props.updateCustomerById({ ...request, _id: customerId });
        }
        navigate('/laboratory/customers');
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const onFocus = async () => {
    if (customerId) {
      // @ts-ignore: Unreachable code error
      const customer = await props.getCustomerById(props.laboratory?.details._id || '', customerId);

      if (customer) {
        formik.setValues({
          laboratoryId: props.laboratory?.details?._id || '',
          address: customer.address as any,
          bankingDetails: customer.bankingDetails || {
            bank: '',
            branch: '',
            accountName: '',
            accountNumber: '',
            ifscCode: '',
          },
          contactDetails: customer.contactDetails || [
            { name: '', designation: '', department: '', email: '', phone: '', country: '' },
          ],
          email: customer.email || '',
          gst: customer.gst || '',
          name: customer.name,
          pan: customer.pan || '',
          phone: customer.phone || '',
          aadhar: customer.aadhar || '',
          creditLimit: customer.creditLimit || 0,
          drugLicenceNumber: customer.drugLicenceNumber || '',
          paymentTerms: customer.paymentTerms || 0,
          country: customer.country || '',
        });
      }
    }
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Customers',
      onClick: () => navigate('/laboratory/customers'),
    },
    {
      label: customerId ? 'Edit Customer' : 'Create Customer',
      onClick: () => navigate('/laboratory/customers/persist'),
    },
  ];

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  const steps: ISteps['steps'] = [
    {
      label: 'Basic customer details',
      description: 'Basic customer details like name, email, phone etc.',
    },
    {
      label: 'Contact details',
      description: 'Contact details of the customer.',
    },
    {
      label: 'Banking details',
      description: 'Banking details of the customer.',
    },
    {
      label: 'Other details',
      description: 'Other details of the customer.',
    },
  ];

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title={customerId ? 'Edit Customer ' : 'Create Customer'} />
      <Box p={2} pt={10}>
        <Grid container>
          <Grid item xs={7}>
            <Steps steps={steps} step={step} onBack={onBack}>
              {(currentStep) => (
                <Grid container>
                  <Grid item xs={12} display={currentStep === 0 ? 'flex' : 'none'}>
                    <Box width={'100%'}>
                      <TextField
                        autoFocus
                        label={'Name of customer'}
                        name={'name'}
                        required
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && formik.errors.name ? true : false}
                        helperText={formik.touched.name && formik.errors.name}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Email'}
                        name={'email'}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && formik.errors.email ? true : false}
                        helperText={formik.touched.email && formik.errors.email}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <FormControl size="small" fullWidth>
                            <InputLabel id="country-select-label">country</InputLabel>
                            <Select
                              labelId="country-select-label"
                              id="country-select"
                              name="country"
                              value={formik.values.country}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={formik.touched.country && formik.errors.country ? true : false}
                              label="country"
                              autoWidth
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {options.map((item) => (
                                <MenuItem value={item.value} key={item.label}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>{formik.touched.country && formik.errors.country}</FormHelperText>
                        </Grid>

                        <Grid item xs={8}>
                          <TextField
                            label={'Phone'}
                            name={'phone'}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && formik.errors.phone ? true : false}
                            helperText={formik.touched.phone && formik.errors.phone}
                            type={'number'}
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                          />
                        </Grid>
                      </Grid>

                      <TextField
                        label={'Address Line 1'}
                        name={'address[0].line1'}
                        value={formik.values.address[0].line1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // @ts-ignore: Unreachable code error
                        error={formik.touched.address?.[0]?.line1 && formik.errors.address?.[0]?.line1 ? true : false}
                        // @ts-ignore: Unreachable code error
                        helperText={formik.touched.address?.[0]?.line1 && formik.errors.address?.[0]?.line1}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Address Line 2'}
                        name={'address[0].line2'}
                        value={formik.values.address[0].line2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        // @ts-ignore: Unreachable code error
                        error={formik.touched.address?.[0]?.line2 && formik.errors.address?.[0]?.line2 ? true : false}
                        // @ts-ignore: Unreachable code error
                        helperText={formik.touched.address?.[0]?.line2 && formik.errors.address?.[0]?.line2}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <TextField
                            label={'City'}
                            name={'address[0].city'}
                            value={formik.values.address[0].city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // @ts-ignore: Unreachable code error
                            error={formik.touched.address?.[0]?.city && formik.errors.address?.[0]?.city ? true : false}
                            // @ts-ignore: Unreachable code error
                            helperText={formik.touched.address?.[0]?.city && formik.errors.address?.[0]?.city}
                            type={'text'}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label={'State'}
                            name={'address[0].state'}
                            value={formik.values.address[0].state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              // @ts-ignore: Unreachable code error
                              formik.touched.address?.[0]?.state && formik.errors.address?.[0]?.state ? true : false
                            }
                            // @ts-ignore: Unreachable code error
                            helperText={formik.touched.address?.[0]?.state && formik.errors.address?.[0]?.state}
                            type={'text'}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label={'Zip'}
                            name={'address[0].zip'}
                            value={formik.values.address[0].zip}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // @ts-ignore: Unreachable code error
                            error={formik.touched.address?.[0]?.zip && formik.errors.address?.[0]?.zip ? true : false}
                            // @ts-ignore: Unreachable code error
                            helperText={formik.touched.address?.[0]?.zip && formik.errors.address?.[0]?.zip}
                            type={'number'}
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} display={currentStep === 1 ? 'flex' : 'none'}>
                    <Box>
                      <TextField
                        label={'Contact person name'}
                        name={'contactDetails[0].name'}
                        value={formik.values.contactDetails[0].name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          // @ts-ignore: Unreachable code error
                          formik.touched.contactDetails?.[0]?.name && formik.errors.contactDetails?.[0]?.name
                            ? true
                            : false
                        }
                        // @ts-ignore: Unreachable code error
                        helperText={formik.touched.contactDetails?.[0]?.name && formik.errors.contactDetails?.[0]?.name}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Designation'}
                        name={'contactDetails[0].designation'}
                        value={formik.values.contactDetails[0].designation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          // @ts-ignore: Unreachable code error
                          formik.touched.contactDetails?.[0]?.designation &&
                          // @ts-ignore: Unreachable code error
                          formik.errors.contactDetails?.[0]?.designation
                            ? true
                            : false
                        }
                        helperText={
                          // @ts-ignore: Unreachable code error
                          formik.touched.contactDetails?.[0]?.designation &&
                          // @ts-ignore: Unreachable code error
                          formik.errors.contactDetails?.[0]?.designation
                        }
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Department'}
                        name={'contactDetails[0].department'}
                        value={formik.values.contactDetails[0].department}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.contactDetails?.[0]?.department &&
                          // @ts-ignore: Unreachable code error
                          formik.errors.contactDetails?.[0]?.department
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched.contactDetails?.[0]?.department &&
                          // @ts-ignore: Unreachable code error
                          formik.errors.contactDetails?.[0]?.department
                        }
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Contact person email'}
                        name={'contactDetails[0].email'}
                        value={formik.values.contactDetails[0].email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          // @ts-ignore: Unreachable code error
                          formik.touched.contactDetails?.[0]?.email && formik.errors.contactDetails?.[0]?.email
                            ? true
                            : false
                        }
                        helperText={
                          // @ts-ignore: Unreachable code error
                          formik.touched.contactDetails?.[0]?.email && formik.errors.contactDetails?.[0]?.email
                        }
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />

                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <FormControl size="small" fullWidth>
                            <InputLabel id="country-select-label">country</InputLabel>
                            <Select
                              labelId="country-select-label"
                              id="country-select"
                              name="contactDetails[0].country"
                              value={formik.values.contactDetails[0].country}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.contactDetails?.[0]?.country &&
                                // @ts-ignore: Unreachable code error
                                formik.errors.contactDetails?.[0]?.country
                                  ? true
                                  : false
                              }
                              label="country"
                              autoWidth
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {options.map((item) => (
                                <MenuItem value={item.value} key={item.label}>
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormHelperText>
                            {
                              // @ts-ignore: Unreachable code error
                              formik.touched.contactDetails?.[0]?.country && formik.errors.contactDetails?.[0]?.country
                            }
                          </FormHelperText>
                        </Grid>

                        <Grid item xs={8}>
                          <TextField
                            label={'Phone'}
                            name={'contactDetails[0].phone'}
                            value={formik.values.contactDetails[0].phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              // @ts-ignore: Unreachable code error
                              formik.touched.contactDetails?.[0]?.phone && formik.errors.contactDetails?.[0]?.phone
                                ? true
                                : false
                            }
                            helperText={
                              // @ts-ignore: Unreachable code error
                              formik.touched.contactDetails?.[0]?.phone && formik.errors.contactDetails?.[0]?.phone
                            }
                            type={'number'}
                            variant="outlined"
                            size="small"
                            fullWidth={true}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} display={currentStep === 2 ? 'flex' : 'none'}>
                    <Box>
                      <TextField
                        label={'Bank'}
                        name={'bankingDetails.bank'}
                        value={formik.values.bankingDetails.bank}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched?.bankingDetails?.bank && formik.errors?.bankingDetails?.bank ? true : false
                        }
                        helperText={formik.touched?.bankingDetails?.bank && formik.errors?.bankingDetails?.bank}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Branch'}
                        name={'bankingDetails.branch'}
                        value={formik.values.bankingDetails.branch}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched?.bankingDetails?.branch && formik.errors?.bankingDetails?.branch ? true : false
                        }
                        helperText={formik.touched?.bankingDetails?.branch && formik.errors?.bankingDetails?.branch}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Account Number'}
                        name={'bankingDetails.accountNumber'}
                        value={formik.values.bankingDetails.accountNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched?.bankingDetails?.accountNumber && formik.errors?.bankingDetails?.accountNumber
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched?.bankingDetails?.accountNumber && formik.errors?.bankingDetails?.accountNumber
                        }
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Account Name'}
                        name={'bankingDetails.accountName'}
                        value={formik.values.bankingDetails.accountName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched?.bankingDetails?.accountName && formik.errors?.bankingDetails?.accountName
                            ? true
                            : false
                        }
                        helperText={
                          formik.touched?.bankingDetails?.accountName && formik.errors?.bankingDetails?.accountName
                        }
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'IFSC Code'}
                        name={'bankingDetails.ifscCode'}
                        value={formik.values.bankingDetails.ifscCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched?.bankingDetails?.ifscCode && formik.errors?.bankingDetails?.ifscCode
                            ? true
                            : false
                        }
                        helperText={formik.touched?.bankingDetails?.ifscCode && formik.errors?.bankingDetails?.ifscCode}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} display={currentStep === 3 ? 'flex' : 'none'}>
                    <Box width={'100%'}>
                      <TextField
                        label={'PAN'}
                        name={'pan'}
                        value={formik.values.pan}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.pan && formik.errors.pan ? true : false}
                        helperText={formik.touched.pan && formik.errors.pan}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'GST'}
                        name={'gst'}
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.gst && formik.errors.gst ? true : false}
                        helperText={formik.touched.gst && formik.errors.gst}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Aadhar'}
                        name={'aadhar'}
                        value={formik.values.aadhar}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.aadhar && formik.errors.aadhar ? true : false}
                        helperText={formik.touched.aadhar && formik.errors.aadhar}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Drug Licence Number'}
                        name={'drugLicenceNumber'}
                        value={formik.values.drugLicenceNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.drugLicenceNumber && formik.errors.drugLicenceNumber ? true : false}
                        helperText={formik.touched.drugLicenceNumber && formik.errors.drugLicenceNumber}
                        type={'text'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Payment Terms (in days)'}
                        name={'paymentTerms'}
                        value={formik.values.paymentTerms}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.paymentTerms && formik.errors.paymentTerms ? true : false}
                        helperText={formik.touched.paymentTerms && formik.errors.paymentTerms}
                        type={'number'}
                        fullWidth
                        variant="outlined"
                      />
                      <TextField
                        label={'Credit Limit'}
                        name={'creditLimit'}
                        value={formik.values.creditLimit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.creditLimit && formik.errors.creditLimit ? true : false}
                        helperText={formik.touched.creditLimit && formik.errors.creditLimit}
                        type={'number'}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Steps>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={1}>
              <Box>
                <Button
                  onClick={() => (step === 0 ? navigate('/laboratory/customers') : onBack())}
                  disabled={formik.isSubmitting}
                  variant="outlined"
                >
                  Back
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  disabled={formik.isSubmitting}
                  variant="contained"
                  onClick={() => (step !== 3 ? onNext() : formik.handleSubmit())}
                >
                  {step === 3 ? (customerId ? 'Update' : 'Save') : 'Next'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ addCustomer, getCustomerById, updateCustomerById }, dispatch),
  };
}

export const PersistCustomer = connect(mapStateToProps, mapDispatchToProps)(_PersistCustomer);
