import React, { useState } from 'react';
import Quill from 'quill';
import TokenBlot from './quill/TokenBlot';
import { TOKEN_MODULE_NAME } from './quill/TokenDrop';
import { Button } from '@mui/material';
import QuillBetterTable from 'quill-better-table';
import { common } from 'constants/ImagesFiles';

const TOKEN_MATCHER = /\{\{(\w*)\|(\w*)\}\}/gi;

// Replace the token embeds from the Quill output with placeholder syntax.
const htmlToTokens = (htmlString) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(htmlString, 'text/html');
  dom.querySelectorAll(`.${TokenBlot.className}`).forEach((token) => {
    const {
      dataset: { id, slug },
    } = token;
    token.replaceWith(`{{${slug}|${id}}}`);
  });
  return dom.body.innerHTML;
};

const tokensToHtml = (tokenizedString, tokensById) => {
  return tokenizedString.replace(TOKEN_MATCHER, (match, slug, id) => {
    const token = tokensById[id];
    // Ignore matches that aren't for a real token.
    if (!token) {
      return match;
    }
    const { title } = token;
    return `<${TokenBlot.tagName} class="${TokenBlot.className}" contenteditable="false" data-title="${title}" data-slug="${slug}" data-id="${id}">${title}</${TokenBlot.tagName}>`;
  });
};

const Editor = ({ value, onChange, quillRef, tokensById, id }) => {
  const [editor, setEditor] = useState(null);
  const tableModule = editor?.getModule('better-table');
  console.log('tableModule -> ', tableModule);

  const CONFIG = {
    // formats: ['bold', 'italic', TokenBlot.blotName],
    modules: {
      // table: false, // disable table module
      'better-table': {
        operationMenu: {
          items: {
            unmergeCells: {
              text: 'Another unmerge cells name',
            },
          },
        },
      },
      // toolbar: [
      //   [{ header: [1, 2, 3, 4, false] }],
      //   ['bold', 'italic', 'underline', 'strike'],
      //   [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      //   [{ list: 'ordered' }, { list: 'bullet' }],
      //   ['link', 'image', 'video'],
      // ],
      toolbar: {
        container: `#toolbar-${id}`,
      },
      [TOKEN_MODULE_NAME]: true,
      keyboard: {
        bindings: QuillBetterTable.keyboardBindings,
      },
    },
    theme: 'snow',
  };

  const onValueChange = (html) => onChange(htmlToTokens(html));

  // When the containing div is mounted, initialize the Quill instance.
  // Don't mount if an instance already exists!
  const onMount = (container) => {
    if (!editor && container) {
      // Set the innerHTML once so that Quill is initialized with the starting value.
      container.innerHTML = tokensToHtml(value, tokensById);
      const quill = new Quill(container, CONFIG);
      quill.on('text-change', () => onValueChange(quill.root.innerHTML));
      setEditor(quill);
      quillRef.current = quill;
    }
  };

  console.log('tableModule -> ', tableModule);

  return (
    <div>
      <div id={`toolbar-${id}`} style={{ marginTop: 5, width: '21cm' }}>
        <span className="ql-formats" style={{ display: 'flex', gap: 10 }}>
          <select className="ql-header" defaultValue="3" style={{ width: 150 }}>
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option value="3">Normal Text</option>
          </select>
          <Button variant="text" style={{ minWidth: 0 }} className="ql-bold" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-italic" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-underline" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-strike" />
          <select className="ql-align" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-list" value="ordered" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-list" value="bullet" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-indent" value="-1" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-indent" value="+1" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-link" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-image" />
          <Button variant="text" style={{ minWidth: 0 }} className="ql-video" />
          <Button
            style={{ minWidth: 0 }}
            onClick={() => {
              tableModule.insertTable(3, 3);
            }}
          >
            <img src={common.tableIcon} height={15} />
          </Button>
        </span>
      </div>
      <div ref={onMount} style={{ height: 500, width: '21cm' }} />
    </div>
  );
};

export default Editor;
