import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IDashboard } from 'pages/Dashboard';
import { useNavigate } from 'react-router-dom';
import { getLaboratoryById, resetCurrentLaboratory, getMyTasks } from 'actions/LaboratoryActions';
import { getUserList } from 'actions/UserAction';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { TaskTable } from './TaskTable';

const TOKENS = [
  { title: 'first name', slug: 'first_name', id: '123' },
  { title: 'last name', slug: 'last_name', id: '456' },
  { title: 'email address', slug: 'email', id: '789' },
];

const tokensById: any = {};
TOKENS.forEach((token) => {
  tokensById[token.id] = token;
});

function Dashboard(props: IDashboard) {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchLaboratoryById = async (id: string) => {
    let batch: any[] = [];
    batch.push(props.getLaboratoryById({ id }));
    batch.push(props.getUserList({ laboratoryId: id }));
    await Promise.all(batch);
    setLoading(false);
  };

  useEffect(() => {
    if (props.laboratoryList.length) {
      fetchLaboratoryById(props.laboratoryList[0]._id ?? '0');
      props.getMyTasks(props.laboratoryList[0]._id ?? '0');
    } else {
      props.resetCurrentLaboratory();
      navigate('/laboratory/notfound');
    }
    // eslint-disable-next-line
  }, []);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Tasks',
      onClick: () => navigate('/'),
    },
  ];

  return loading ? (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Your tasks" />
      <Box pt={10}>
        <TaskTable data={props.tasks} />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratoryList: state.laboratory.list,
  laboratory: state.laboratory.current,
  tasks: state.laboratory.tasks,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ getLaboratoryById, resetCurrentLaboratory, getUserList, getMyTasks }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
