import { Box, Button } from '@mui/material';
import { useEffect } from 'react';

interface A4Props {
  header: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  finalFooter?: React.ReactNode;
  landscape?: boolean;
}

export default function A4(props: A4Props) {
  useEffect(() => {
    setTimeout(() => {
      // window.print();
    }, 1000);

    // eslint-disable-next-line
  }, []);
  return (
    <Box
      style={{
        width: props.landscape ? '297mm' : '210mm',
        fontSize: 12,
        fontFamily: 'Arial',
      }}
      id="page-content"
    >
      <Box
        style={{
          width: props.landscape ? '297mm' : '210mm',
          display: 'flex',
          justifyContent: 'center',
        }}
        className="no-print"
      >
        <Button
          onClick={() => {
            window.print();
          }}
          variant="contained"
        >
          Print
        </Button>
      </Box>
      <Box>
        <table style={{ width: '100%', paddingLeft: '7mm', paddingRight: '7mm' }}>
          <thead>
            <tr>
              <td style={{ paddingTop: '5mm' }}>{props.header}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.body}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td style={{ paddingBottom: '7mm' }}>{props.footer}</td>
            </tr>
          </tfoot>
        </table>
        <table style={{ width: '100%', paddingLeft: '7mm', paddingRight: '7mm' }}>
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
        <footer></footer>

        {props.finalFooter}
      </Box>
    </Box>
  );
}
