import { useMemo } from 'react';
import { ICustomFieldWithLabelProps } from 'components/CustomFieldWithLabel';
import { Box, Typography } from '@mui/material';

export function CustomFieldWithLabel(props: ICustomFieldWithLabelProps) {
  const { children } = props;

  const childrenMemo = useMemo(
    () => children,
    [children.props.value, children.props.parameters, children.props.error, children.props.helperText]
  );

  return (
    <Box display={'flex'} gap={1} alignItems={'center'}>
      <Box width={props.width || 190}>
        <Typography
          fontSize={props.label.length > 23 ? 10.5 : 12}
          fontWeight={'bold'}
          style={{
            width: '100%',
            // textAlign: 'right'
          }}
        >
          {props.label}
        </Typography>
      </Box>
      <Box flex={1}>{childrenMemo}</Box>
    </Box>
  );
}
