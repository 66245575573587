import { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Divider, Grid, CircularProgress } from '@mui/material';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IFormikCreateTestGroup, IPersistTestGroupProps } from 'pages/TestGroup/PersistTestGroup';
import { useFormik } from 'formik';
import { getTestGroupById, updateTestGroup, createTestGroup } from 'actions/LaboratoryActions';
import useQuery from 'utils/getQueryParams';
import { createTestGroupSchema } from 'FormSchema/CreateTestGroupSchema';
import AdditionalFieldsTable from './AdditionalFieldsTable';

function _PersistTestGroup(props: IPersistTestGroupProps) {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let query = useQuery();
  let testGroupId = query.get('id');
  const laboratoryId = props.laboratory?.details._id || '';
  const formik: IFormikCreateTestGroup = useFormik({
    initialValues: createTestGroupSchema.initialValues,
    validationSchema: createTestGroupSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (testGroupId) {
          await props.updateTestGroup({
            ...values,
            laboratoryId,
            _id: testGroupId,
            extraFields: [],
          });
        } else {
          await props.createTestGroup({ ...values, laboratoryId, extraFields: [] });
        }
        navigate('/laboratory/testgroups');
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Test Groups',
      onClick: () => navigate('/laboratory/testgroups'),
    },
    {
      label: 'Create Test Groups',
      onClick: () => navigate('/laboratory/testgroups/persist'),
    },
  ];

  const onFocus = async () => {
    try {
      if (testGroupId) {
        const testGroup = await props.getTestGroupById(laboratoryId, testGroupId);
        formik.setValues({
          name: testGroup.name,
          description: testGroup.description || '',
          prefix: testGroup.prefix || '',
          additionalFields: testGroup.additionalFields as any,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Create Masters" />
      <Box p={2} pt={10}>
        {loading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={7}>
              <Box pt={2} pb={2}>
                <Typography variant="subtitle2">Create Test Group</Typography>
                <Divider />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label={'Name'}
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && formik.errors.name ? true : false}
                    helperText={formik.touched.name && formik.errors.name}
                    type={'text'}
                    required
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={'Prefix'}
                    name={'prefix'}
                    value={formik.values.prefix}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.prefix && formik.errors.prefix ? true : false}
                    helperText={formik.touched.prefix && formik.errors.prefix}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={'Description'}
                    name={'description'}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && formik.errors.description ? true : false}
                    helperText={formik.touched.description && formik.errors.description}
                    type={'text'}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <AdditionalFieldsTable
                    value={formik.values.additionalFields || []}
                    onChange={(value) => {
                      formik.setFieldValue('additionalFields', value);
                    }}
                  />
                </Grid>
              </Grid>

              <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Box>
                  <Button
                    disabled={formik.isSubmitting}
                    variant="outlined"
                    onClick={() => navigate('/laboratory/testgroups')}
                  >
                    Back
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button variant="contained" disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
                    {testGroupId ? 'Update' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ createTestGroup, getTestGroupById, updateTestGroup }, dispatch),
  };
}

export const PersistTestGroup = connect(mapStateToProps, mapDispatchToProps)(_PersistTestGroup);
