import actionTypes from 'constants/ActionTypes';
import { IActionInterface } from 'Reducers/IActionDispatch';
import { IUserReducer } from 'Reducers/UserReducer';

const initialState: IUserReducer = {
  laboratoryId: null,
};

export const UserReducer = (state: IUserReducer = initialState, action: IActionInterface): IUserReducer => {
  switch (action.type) {
    case actionTypes.auth.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
