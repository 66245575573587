import { render } from '@testing-library/react';
import { ISample } from 'exactt-types/lib';
import _ from 'lodash';
import React from 'react';

export interface IGeneralTableProps {
  sample: ISample | null;
  fields: {
    characteristic: string;
    results: string;
    requirements: string;
    permissibleLimit: string;
    method: string;
    unit: string;
    group: string;
    type: string;
  }[];
}

export default function GeneralPharmaTable2(props: IGeneralTableProps) {
  const fieldsWithGroups = _.groupBy(props.fields, 'group');

  const renderFields: any[] = [];
  const addedGroups: any[] = [];

  const groups = Object.keys(fieldsWithGroups);
  const parameterNames = props.fields.map((field) => field.characteristic);

  console.log('groups -> ', groups);
  console.log('parameterNames -> ', parameterNames);

  const groupHasAssay = groups.map((i) => i.toLowerCase()).includes('assay');
  const parameterHasAssay = parameterNames.map((i) => i.toLowerCase()).includes('assay');

  const hasAssay = parameterHasAssay || groupHasAssay;

  props.fields.map((field, index) => {
    if (!field.group) {
      renderFields.push(field);
    }
    if (field.group && !addedGroups.includes(field.group)) {
      const data = fieldsWithGroups[field.group];
      renderFields.push(data);
      addedGroups.push(field.group);
    }
  });

  return (
    <>
      <div className="table-with-border" style={{ width: '100%', marginTop: 20 }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '10mm' }}>No.</th>
              <th>Test Parameters</th>
              {hasAssay && props.sample?.labelClaim && <th>Label Claim</th>}
              <th style={{ width: '30%' }}>Results</th>
              <th style={{ width: '30%' }}> Specification {props.sample?.testMethod}</th>
            </tr>
          </thead>

          <tbody>
            {renderFields.map((field, index) => {
              const isGroup = Array.isArray(field);

              const isParameterAssay = String(field.characteristic).toLowerCase() === 'assay';
              const isGroupAssay = isGroup ? String(field[0].group).toLowerCase() === 'assay' : false;

              const isAssay = isParameterAssay || isGroupAssay;

              let claimUnit = '';

              if (props.sample?.labelClaim) {
                claimUnit = props.sample?.labelClaim.split(' ')[1];
              }

              if (isGroup) {
                return (
                  <>
                    <tr key={index} style={{ borderTop: 0 }}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: 'left' }}>
                        <div>{field[0].group}</div>
                        {field.map((f: any, idx: number) => (
                          <div style={{ paddingTop: 2 }}>{f.characteristic}</div>
                        ))}
                      </td>
                      {hasAssay && props.sample?.labelClaim ? (
                        isAssay ? (
                          <td>
                            <div>&nbsp;</div>
                            <div>{props.sample?.labelClaim}</div>
                          </td>
                        ) : (
                          <td></td>
                        )
                      ) : null}
                      <td>
                        <div>&nbsp;</div>
                        {field.map((f: any, idx: number) => (
                          <div style={{ paddingTop: 2 }}>
                            {f.type === 'percentageLC' && props.sample?.labelClaim
                              ? `${
                                  (parseFloat(props.sample?.labelClaim || '') * parseFloat(f.results)) / 100
                                } i.e. ${claimUnit} ${parseFloat(f.results)} % of Label Amount`
                              : `${f.results} ${f.unit}`}
                          </div>
                        ))}
                      </td>
                      <td>
                        <div>&nbsp;</div>
                        {field.map((f: any, idx: number) => (
                          <div style={{ paddingTop: 2 }}>{f.requirements}</div>
                        ))}
                      </td>
                    </tr>
                  </>
                );
              }
              return (
                <>
                  <tr key={index} style={{ borderTop: 0 }}>
                    <td>{index + 1}</td>
                    <td>{field.characteristic}</td>
                    {hasAssay && props.sample?.labelClaim ? (
                      isAssay ? (
                        <td>
                          <div>{props.sample?.labelClaim}</div>
                        </td>
                      ) : (
                        <td></td>
                      )
                    ) : null}
                    <td>
                      {field.type === 'percentageLC' && props.sample?.labelClaim
                        ? `${
                            (parseFloat(props.sample?.labelClaim || '') * parseFloat(field.results)) / 100
                          } ${claimUnit} i.e. ${parseFloat(field.results)} % of Label Amount`
                        : `${field.results} ${field.unit}`}
                    </td>
                    <td>{field.requirements}</td>
                    {/* <td>{field.method}</td> */}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
        <div style={{ marginTop: 10, paddingBottom: 10 }}>
          In the opinion of the undersigned, the sample referred to above is of Standard Quality as defined in{' '}
          {props.sample?.testMethod} the Specification & Act or the rules made there under
        </div>
      </div>
    </>
  );
}
