import { Box, Button, Pagination, Stack, TextField } from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IResultTemplateProps } from './IResultTemplate';
import { TestResultTable } from './ResultTemplateTable';
import { searchResultTemplate } from 'actions/LaboratoryActions';
import { useEffect, useState } from 'react';
import { deleteResultTemplate } from 'services/LaboratoryService/LaboratoryService';
import { DeleteDialog } from 'components/DeleteDialog';

export function _ResultTemplate(props: IResultTemplateProps) {
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedResultTemplate, setSelectedResultTemplate] = useState<undefined | any>(undefined || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const size = 15;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    props.searchResultTemplate(props.laboratory?.details?._id || '', { page: value, size: size, query: searchQuery });
  };

  const onFocus = async () => {
    await props.searchResultTemplate(props.laboratory?.details?._id || '', {
      page: page,
      size: size,
      query: searchQuery,
    });
  };

  const handleDeleteResultTemplate = () => {
    try {
      setDeleteLoading(true);
      props.deleteResultTemplate(props.laboratory?.details?._id || '', selectedResultTemplate?._id || '');
      setDeleteModal(false);
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      onFocus();
    }
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Test Result',
      onClick: () => navigate('/laboratory/resultTemplate'),
    },
  ];

  useEffect(() => {
    props.searchResultTemplate(props.laboratory?.details?._id || '', { page: page, size: size, query: searchQuery });
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Test Result"
        right={
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <TextField
              label={'Search Result Template'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant={'contained'}
              startIcon={<AddIcon />}
              onClick={() => navigate('/laboratory/resultTemplate/persist')}
            >
              Add Result
            </Button>
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <TestResultTable
          onDelete={(resultTemplate) => {
            setDeleteModal(true);
            setSelectedResultTemplate(resultTemplate);
          }}
          data={props.resultTemplate || []}
          onEdit={(resultTemplate) => {
            navigate(`/laboratory/resultTemplate/persist?id=${resultTemplate._id}`);
          }}
        />
      </Box>
      <DeleteDialog
        title={'Delete Result Template'}
        message={`Are you sure you want to delete ${selectedResultTemplate.name} ?`}
        isVisible={deleteModal}
        onClose={() => setDeleteModal(false)}
        onAction={handleDeleteResultTemplate}
        isLoading={deleteLoading}
      />
      <Stack spacing={2}>
        <Pagination count={props.count} shape="rounded" page={page} onChange={handlePageChange} defaultPage={page} />
      </Stack>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  resultTemplate: state.laboratory.resultTemplate,
  count: state.laboratory.count.resultTemplate,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchResultTemplate, deleteResultTemplate }, dispatch),
  };
}

export const ResultTemplate = connect(mapStateToProps, mapDispatchToProps)(_ResultTemplate);
