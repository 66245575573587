import axios, { AxiosResponse } from 'axios';
import { IDepartment, ILaboratory, IMaster, IReference, IResultTemplate, ISample, ITestGroup } from 'exactt-types/lib';
import { IGetLaboratoryById, IAssignSampleData, IAddResultData, IMultiAssign } from 'services/LaboratoryService';
import { IUpdateResult } from './ILaboratoryService';

export const getLaboratoryList = async (): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get('/laboratory');
    return res;
  } catch (err: any) {
    throw err;
  }
};

export const getLaboratoryById = async (request: IGetLaboratoryById): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${request.id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createLaboratory = async (request: ILaboratory): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory`, request);
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchMasterList = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/master/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const createMaster = async (data: IMaster): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${data.laboratoryId}/master`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getMasterById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/master/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteMasterById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/master/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateMaster = async (data: IMaster): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${data.laboratoryId}/master`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchTestGroup = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/testGroup/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTestGroup = async (data: ITestGroup): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${data.laboratoryId}/testGroup`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateTestGroup = async (data: ITestGroup): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${data.laboratoryId}/testGroup`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteTestGroup = async (laboratoryId: string, _id: string = ''): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/testGroup/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getTestGroupById = async (laboratoryId: string, _id: string = ''): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/testGroup/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getMyTasks = async (laboratoryId: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/myTasks`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createSample = async (data: ISample): Promise<AxiosResponse<any, any>> => {
  try {
    const reference = data.reference ? data.reference : undefined;
    let res = await axios.post(`/laboratory/${data.laboratoryId}/sample`, { ...data, reference });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getSample = async (
  laboratoryId: string,
  params: { page: number; size: number }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateSample = async (data: ISample): Promise<AxiosResponse<any, any>> => {
  try {
    const reference = data.reference ? data.reference : undefined;
    let res = await axios.put(`/laboratory/${data.laboratoryId}/sample`, { ...data, reference });
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchSample = async (
  laboratoryId: string,
  params: {
    page?: number;
    size?: number;
    query?: string;
    startDate?: string;
    endDate?: string;
    testGroup?: string;
    customer?: string;
    status?: string;
  }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getSampleById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const assignSample = async (
  laboratoryId: string,
  _id: string,
  data: IAssignSampleData
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/sample/parameter/${_id}/assign`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const addResult = async (
  laboratoryId: string,
  _id: string,
  data: IAddResultData
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/sample/parameter/${_id}/addResult`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const approveResult = async (
  laboratoryId: string,
  _id: string,
  approveBy: string
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/sample/parameter/${_id}/approve`, { approveBy });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateResult = async (
  laboratoryId: string,
  data: IUpdateResult,
  sampleId: string
): Promise<AxiosResponse<any, any>> => {
  try {
    const body = {};

    let res = await axios.put(`/laboratory/${laboratoryId}/sample/${sampleId}/complete-information`, data);
    console.log('res Parmas up-> ', res);
    return res;
  } catch (err) {
    throw err;
  }
};

export const rejectResult = async (
  laboratoryId: string,
  _id: string,
  rejectBy: string
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/sample/parameter/${_id}/reject`, { rejectBy });
    return res;
  } catch (err) {
    throw err;
  }
};

export const generateURLNumber = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}/generateUlr`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createJobAllotment = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}/jobAllotment`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createTestRequest = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}/testRequest`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const generateResult = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}/result`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const multiAssign = async (laboratoryId: string, data: IMultiAssign): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/sample/${data.sampleId}/assignMultipleUser`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createJobSheet = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/sample/${_id}/jobSheet`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getReferenceId = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/reference/${_id}`);

    return res;
  } catch (err) {
    throw err;
  }
};

export const getReference = async (
  laboratoryId: string,
  params: { page: number; size: number }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/reference`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchReference = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/reference/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};
export const updateReference = async (laboratoryId: string, data: IReference): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${laboratoryId}/reference/`, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const createReference = async (laboratoryId: string, data: IReference): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/reference`, data);
    return res;
  } catch (err) {
    throw err;
  }
};
export const deleteReferenceById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/reference/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getDepartmentById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/department/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getDepartment = async (
  laboratoryId: string,
  params: { page: number; size: number }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/department`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const searchDepartment = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/department/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateDepartment = async (laboratoryId: string, data: IDepartment): Promise<AxiosResponse<any, any>> => {
  try {
    const body = {
      _id: data._id,
      name: data.name,
      description: data.description,
    };
    let res = await axios.put(`/laboratory/${laboratoryId}/department`, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createDepartment = async (laboratoryId: string, data: IDepartment): Promise<AxiosResponse<any, any>> => {
  try {
    const body = {
      name: data.name,
      description: data.description,
    };
    let res = await axios.post(`/laboratory/${laboratoryId}/department`, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteDepartmentById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/department/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createResultTemplate = async (
  laboratoryId: string,
  data: IResultTemplate
): Promise<AxiosResponse<any, any>> => {
  try {
    const body = {
      name: data.name,
      header: data.header,
      body: data.body,
      footer: data.footer,
    };
    let res = await axios.post(`/laboratory/${laboratoryId}/resultTemplate`, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateResultTemplate = async (
  laboratoryId: string,
  data: IResultTemplate
): Promise<AxiosResponse<any, any>> => {
  try {
    const body = {
      _id: data._id,
      name: data.name,
      header: data.header,
      body: data.body,
      footer: data.footer,
    };
    let res = await axios.put(`/laboratory/${laboratoryId}/resultTemplate`, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getResultTemplate = async (laboratoryId: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/resultTemplate`);
    return res;
  } catch (err) {
    throw err;
  }
};
export const searchResultTemplate = async (
  laboratoryId: string,
  params: { page: number; size: number; query: string }
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/resultTemplate/search?search=${params.query}`, { params });
    return res;
  } catch (err) {
    throw err;
  }
};

export const getResultTemplateById = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/resultTemplate/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deleteResultTemplate = async (laboratoryId: string, _id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/resultTemplate/${_id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPendingInvoiceSamples = async (
  laboratoryId: string,
  customerId: string
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/invoice/getPendingInvoiceSamples/${customerId}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const generateInvoice = async (
  laboratoryId: string,
  customerId: string,
  data: any
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/invoice/${customerId}`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getCustomerInvoice = async (
  laboratoryId: string,
  customerId: string
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/invoice/${customerId}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const updateCutomerAmount = async (
  laboratoryId: string,
  customerId: string,
  amount: any
): Promise<AxiosResponse<any, any>> => {
  try {
    const data = {
      amount: amount,
    };
    let res = await axios.post(`/laboratory/${laboratoryId}/customer/${customerId}/makePayment`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getInvoiceById = async (laboratoryId: string, id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/invoice/id/${id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPriceList = async (laboratoryId: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/priceList`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const createPriceList = async (laboratoryId: string, data: any): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.post(`/laboratory/${laboratoryId}/priceList`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPriceListById = async (laboratoryId: string, id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(`/laboratory/${laboratoryId}/priceList/${id}`);
    return res;
  } catch (err) {
    throw err;
  }
};

export const getPriceListByMasterAndCustomer = async (
  laboratoryId: string,
  masterId: string,
  customerId
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.get(
      `/laboratory/${laboratoryId}/priceList/byCustomer?masterId=${masterId}&customerId=${customerId}`
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const updatePriceList = async (
  laboratoryId: string,
  id: string,
  data: any
): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.put(`/laboratory/${laboratoryId}/priceList/${id}`, data);
    return res;
  } catch (err) {
    throw err;
  }
};

export const deletePriceList = async (laboratoryId: string, id: string): Promise<AxiosResponse<any, any>> => {
  try {
    let res = await axios.delete(`/laboratory/${laboratoryId}/priceList/${id}`);
    return res;
  } catch (err) {
    throw err;
  }
};
