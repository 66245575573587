import { combineReducers } from 'redux';
import { AuthReducer } from 'Reducers/AuthReducer';
import { SystemReducer } from 'Reducers/SystemReducer';
import { IReducer } from 'Reducers/IReducer';
import { DepartmentReducer } from 'Reducers/DeaprtmentReducer';
import { LaboratoryReducer } from 'Reducers/LaboratoryReducer';
import { UserReducer } from 'Reducers/UserReducer';

export const rootReducer = combineReducers<IReducer>({
  auth: AuthReducer,
  system: SystemReducer,
  department: DepartmentReducer,
  laboratory: LaboratoryReducer,
  user: UserReducer,
});
