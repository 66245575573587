const actionTypes = {
  auth: {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',
    LOGOUT: 'LOGOUT',
  },
  system: {
    SHOW_SNACK: 'SHOW_SNACK',
    GET_ENUMS: 'GET_ENUMS',
  },
  laboratory: {
    GET_LABORATORYS: 'GET_LABORATORYS',
    GET_LABORATORY_BY_ID: 'GET_LABORATORY_BY_ID',
    RESET_CURRENT_LABORATORY: 'RESET_CURRENT_LABORATORY',
  },
  users: {
    INVITE_USER: 'INVITE_USER',
    GET_LABORATORY_USERS_LIST: 'GET_LABORATORY_USERS_LIST',
    SEARCH_LABORATORY_USERS_LIST: 'SEARCH_LABORATORY_USERS_LIST',
  },
  customer: {
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
    GET_CUSTOMER_LIST: 'GET_CUSTOMER_LIST',
    GET_CUSTOMER_BY_ID: 'GET_CUSTOMER_BY_ID',
    GET_CUSTOMER: 'GET_CUSTOMER',
    SEARCH_CUSTOMER: 'SEARCH_CUSTOMER',
  },
  master: {
    ADD_MASTER: 'ADD_MASTER',
    UPDATE_MASTER: 'UPDATE_MASTER',
    SEARCH_MASTER: 'SEARCH_MASTER',
    GET_MASTER_LIST: 'GET_MASTER_LIST',
    GET_MASTER_BY_ID: 'GET_MASTER_BY_ID',
    DELETE_MASTER_BY_ID: 'DELETE_MASTER_BY_ID',
  },
  testgroup: {
    ADD_TESTGROUP: 'ADD_TESTGROUP',
    SEARCH_TESTGROUP: 'SEARCH_TESTGROUP',
    GET_TEST_GROUP: 'GET_TEST_GROUP',
    EDIT_TESTGROUP: 'EDIT_TESTGROUP',
    GET_TESTGROUP_BY_ID: 'GET_TESTGROUP_BY_ID',
    DELETE_TESTGROUP_BY_ID: 'DELETE_TESTGROUP_BY_ID',
  },
  sample: {
    ADD_SAMPLE: 'ADD_SAMPLE',
    SEARCH_SAMPLE: 'SEARCH_SAMPLE',
    UPDATE_SAMPLE: 'UPDATE_SAMPLE',
    GET_SAMPLE: 'GET_SAMPLE',
    GET_SAMPLE_BY_ID: 'GET_SAMPLE_BY_ID',
    ASSIGN_SAMPLE: 'ASSIGN_SAMPLE',
    ASSIGN_MULTIPLE_SAMPLE: 'ASSIGN_MULTIPLE_SAMPLE',
    GET_MY_TASKS: 'GET_MY_TASKS',
    UPDATE_RESULT: 'UPDATE_RESULT',
  },
  reference: {
    ADD_REFERENCE: 'ADD_REFERENCE',
    SEARCH_REFERENCE: 'SEARCH_REFERENCE',
    GET_REFERENCE: 'GET_REFERENCE',
    GET_REFERENCE_BY_ID: 'GET_REFERENCE_BY_ID',
    UPDATE_REFERENCE: 'UPDATE_REFERENCE',
    DELETE_REFERENCE_BY_ID: 'DELETE_REFERENCE_BY_ID',
  },
  department: {
    ADD_DEPARTMENT: 'ADD_DEPARTMENT',
    GET_DEPARTMENT: 'GET_DEPARTMENT',
    SEARCH_DEPARTMENT: 'SEARCH_DEPARTMENT',
    GET_DEPARTMENT_BY_ID: 'GET_DEPARTMENT_BY_ID',
    UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
    DELETE_DEPARTMENT_BY_ID: 'DELETE_DEPARTMENT_BY_ID',
  },
  resultTemplate: {
    ADD_RESULT_TEMPLATE: 'ADD_RESULT_TEMPLATE',
    SEARCH_RESULT_TEMPLATE: 'SEARCH_RESULT_TEMPLATE',
    GET_RESULT_TEMPLATE: 'GET_RESULT_TEMPLATE',
    GET_RESULT_TEMPLATE_BY_ID: 'GET_RESULT_TEMPLATE_BY_ID',
    UPDATE_RESULT_TEMPLATE: 'UPDATE_RESULT_TEMPLATE',
    DELETE_RESULT_TEMPLATE_BY_ID: 'DELETE_RESULT_TEMPLATE_BY_ID',
  },
  audit: {
    ADD_AUDIT: 'ADD_AUDIT',
    GET_AUDIT: 'GET_AUDIT',
    UPDATE_AUDIT: 'UPDATE_AUDIT',
  },
  priceList: {
    GET_PRICE_LIST: 'GET_PRICE_LIST',
    GET_PRICE_LIST_BY_ID: 'GET_PRICE_LIST_BY_ID',
    ADD_PRICE_LIST: 'ADD_PRICE_LIST',
    UPDATE_PRICE_LIST: 'UPDATE_PRICE_LIST',
    DELETE_PRICE_LIST: 'DELETE_PRICE_LIST',
  },
};
export type IActionType = typeof actionTypes;

export default actionTypes;
