import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 150,
      alignSelf: 'center',
      marginBottom: 20,
    },
  })
);

export default useStyles;
