import { TableContainer, Table, TableHead, TableRow, TableCell, Box, TableBody, Button, Chip } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { useNavigate } from 'react-router-dom';
import { ISampleTable } from './ISampleTable';
import moment from 'moment';

function _SampleTable(props: ISampleTable) {
  const navigate = useNavigate();
  const pharmaCheck = props.laboratory?.details?.labType === 'PHARMA';

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500, marginTop: '0.6%' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={230}>Sample ID</TableCell>
              <TableCell width={100}>Status</TableCell>
              <TableCell>Sample Name</TableCell>
              <TableCell>Due date</TableCell>
              {pharmaCheck ? <TableCell>Test Group</TableCell> : <TableCell>Sample Type</TableCell>}
              <TableCell>Generic Name</TableCell>
              {props.role === 'ADMIN' && <TableCell>Customer Name</TableCell>}
              {props.role === 'ADMIN' && <TableCell>Due Amount</TableCell>}
              {props.role === 'ADMIN' && <TableCell align="right">Total Amount</TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.data.map((data) => {
              // tax is data.gst so calculating total with tax
              const taxAmount = (data.rate * data.gst) / 100;
              const totalWithTax = data.rate + taxAmount;
              const dueAmount = data.rate - data.paidAmount;

              return (
                <TableRow key={data._id}>
                  <TableCell>
                    <Button
                      variant={'text'}
                      onClick={() => navigate(`/laboratory/samples/sampleDetails?id=${data._id}`)}
                      style={{ fontSize: 14 }}
                    >
                      ↗ {data.sampleId ? data.sampleId : 'SAMPLE ID not found'}
                    </Button>
                  </TableCell>
                  <TableCell>
                    {data.status === 'PENDING' && (
                      <Chip label={'Pending'} size="small" variant={'outlined'} color={'error'} />
                    )}
                    {data.status === 'INPROGRESS' && (
                      <Chip label={'In Process'} size="small" variant={'outlined'} color={'info'} />
                    )}
                    {data.status === 'COMPLETED' && (
                      <Chip label={'Complete'} size="small" variant={'outlined'} color={'success'} />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data?.name || 'NAME not found'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data.dueDate ? moment(data.dueDate).format('DD/MM/YYYY') : 'ASSIGNED DATE not found'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {pharmaCheck
                      ? data?.testGroup?.name || 'N/A'
                      : data.formType
                      ? data.formType
                      : 'No Sample Type found'}
                  </TableCell>
                  <TableCell
                    style={{
                      maxWidth: 0,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data.genericName || 'Not Assigned yet'}
                  </TableCell>
                  {props.role === 'ADMIN' && (
                    <TableCell
                      style={{
                        maxWidth: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {typeof data?.customer === 'object' && (
                        <>{data?.customer?.name.slice(0, 100) || 'No Customer '}</>
                      )}
                    </TableCell>
                  )}
                  {props.role === 'ADMIN' && <TableCell>₹{dueAmount}</TableCell>}
                  {props.role === 'ADMIN' && <TableCell>₹{totalWithTax}</TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  sample: state.laboratory.sampleDetails,
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const SampleTable = connect(mapStateToProps, mapDispatchToProps)(_SampleTable);
