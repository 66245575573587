import { Box, TextField } from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { AuditTable } from './AuthTable';
import { IAudit } from './IAudit';
import { useEffect, useState } from 'react';
import { searchSample } from 'actions/LaboratoryActions';

export function _Audit(props: IAudit) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  // API  calls
  useEffect(() => {
    props.searchSample(props.laboratory?.details?._id || '', { page: 1, size: 10, query: searchQuery });
    // eslint-disable-next-line
  }, [searchQuery]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Audit',
      onClick: () => navigate('/laboratory/audits'),
    },
  ];

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Audit"
        right={
          <Box display={'flex'} alignItems={'center'}>
            <TextField
              label={'Search Audit'}
              size={'small'}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        }
      />
      <Box p={2} pt={10}>
        <AuditTable
          data={props.sampleList}
          onEdit={(data) => {
            navigate(`/laboratory/samples/persistSample?id=${data._id}`);
          }}
        />
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  referenceList: state.laboratory.reference,
  sampleList: state.laboratory.samples,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchSample }, dispatch),
  };
}

export const Audit = connect(mapStateToProps, mapDispatchToProps)(_Audit);
