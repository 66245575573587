import { TableContainer, Table, TableHead, TableRow, TableCell, Box, TableBody, Button } from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { IAuditTable } from './IAuditTable';
import { useNavigate } from 'react-router-dom';

function _AuditTable(props: IAuditTable) {
  const navigate = useNavigate();

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500, marginTop: '0.6%' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={300}>Sample ID</TableCell>
              <TableCell width={200}>Sample Name</TableCell>
              <TableCell width={200}>Customer Name</TableCell>
              <TableCell width={300}>Reference Name</TableCell>
              <TableCell align="right" width={200}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.data.map((data) => (
              <TableRow key={data._id}>
                <TableCell>
                  <Button
                    variant={'text'}
                    onClick={() => navigate(`/laboratory/samples/sampleDetails?id=${data._id}`)}
                    style={{ fontSize: 14, display: 'flex', alignItems: 'center' }}
                  >
                    ↗ {data.sampleId ? data.sampleId : 'SAMPLE ID not found'}
                  </Button>
                </TableCell>
                <TableCell>{data.name || '-'}</TableCell>
                <TableCell>{(data.customer as any)?.name || '-'}</TableCell>
                <TableCell>{(data.reference as any)?.name || '-'}</TableCell>
                <TableCell align="right">
                  <Button onClick={() => props.onEdit(data)}>UPDATE</Button>
                  <Button onClick={() => props.onEdit(data)}>INVOICE</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  reference: state.laboratory.reference,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const AuditTable = connect(mapStateToProps, mapDispatchToProps)(_AuditTable);
