import axios, { AxiosResponse } from 'axios';
import { IMediaUploadRequest } from 'services/Common';

export const mediaUploadService = async (request: IMediaUploadRequest): Promise<AxiosResponse<any, any>> => {
  try {
    var data = new FormData();
    data.append('file', request.file);

    let res = await axios.post('/media', data);
    return res;
  } catch (err: any) {
    throw err;
  }
};
