import actionTypes from 'constants/ActionTypes';
import { IActionInterface } from 'Reducers/IActionDispatch';
import { ISystemReducer } from 'Reducers/SystemReducer';

const initialState: ISystemReducer = {
  snackbar: {
    variant: 'default',
    message: null,
  },
  enums: null,
};

export const SystemReducer = (state: ISystemReducer = initialState, action: IActionInterface): ISystemReducer => {
  switch (action.type) {
    case actionTypes.system.SHOW_SNACK:
      return {
        ...state,
        snackbar: {
          variant: action.payload.variant ?? 'default',
          message: action.payload.message,
        },
      };

    case actionTypes.system.GET_ENUMS:
      return {
        ...state,
        enums: action.payload.enums,
      };

    case actionTypes.auth.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
