import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { TableContainer, Table, TableHead, TableRow, TableCell, Button, Box, Link } from '@mui/material';
import { IMasterTabel } from 'pages/Master/MasterTable';

export default function _MasterTable(props: IMasterTabel) {
  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell width={'50%'}>Sample Name</TableCell>
              <TableCell>Generic Name</TableCell>
              {/* <TableCell>Parameters</TableCell> */}
              <TableCell width={200} align="right"></TableCell>
            </TableRow>
          </TableHead>
          {props.data.map((masterDetails) => (
            <TableRow key={masterDetails._id}>
              <TableCell>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.onEdit(masterDetails);
                  }}
                >
                  {masterDetails.sampleName}
                </Link>
              </TableCell>
              <TableCell>{masterDetails.genericName}</TableCell>
              {/* <TableCell>{masterDetails.parameters.map((item) => item.name).join(',')}</TableCell> */}
              <TableCell align="right">
                <Button onClick={() => props.onCopy(masterDetails)}>Copy</Button>
                <Button onClick={() => props.onEdit(masterDetails)}>Edit</Button>
                <Button color="error" onClick={() => props.onDelete(masterDetails)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  department: state.laboratory.current?.departments ?? [],
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export const MasterTable = connect(mapStateToProps, mapDispatchToProps)(_MasterTable);
