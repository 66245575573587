import * as Yup from 'yup';

export const createMasterSchema = {
  initialValues: {
    sampleName: '',
    genericName: '',
    testingMethod: '',
    marking: '',
    environmentCondition: '',
    permissibleLimit: '',
    printTemplate: '',
    parameters: [],
    requiredOpinion: false,
    resultHeader: '',
    resultFooter: '',
    validation: [
      {
        type: undefined,
        min: undefined,
        max: undefined,
        validOptions: undefined,
        invalidOptions: undefined,
        validResult: undefined,
        invalidResult: undefined,
      },
    ],
  },
  validationSchema: Yup.object().shape({
    sampleName: Yup.string().required('Name is required'),
    genericName: Yup.string().required('Generic name is required'),
    testingMethod: Yup.string().required('Testing method is required'),
    printTemplate: Yup.string().optional(),
    environmentCondition: Yup.string().optional(),
    marking: Yup.string().optional(),
    parameters: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          unit: Yup.string().optional(),
          requirements: Yup.string().optional(),
          isNABL: Yup.boolean().optional(),
          department: Yup.string().optional(),
          validations: Yup.object().optional(),
          permissibleLimit: Yup.string().optional(),
        })
      )
      .optional(),
  }),
};
