import actionTypes from 'constants/ActionTypes';
import { IActionInterface } from 'Reducers/IActionDispatch';
import { IDepartmentReducer } from 'Reducers/DeaprtmentReducer';

const initialState: IDepartmentReducer = {
  list: [],
  count: 0,
};

export const DepartmentReducer = (
  state: IDepartmentReducer = initialState,
  action: IActionInterface
): IDepartmentReducer => {
  switch (action.type) {
    case actionTypes.auth.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
