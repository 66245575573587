import { Grid, IconButton, Typography, Divider } from '@mui/material';
import { ISteps } from './ISteps';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const Steps = (props: ISteps) => {
  return (
    <Grid container>
      <Grid item xs={12} display={'flex'} alignItems={'center'} onClick={() => props.onBack()}>
        <IconButton size={'small'} disabled={props.step === 0}>
          <ArrowBackIosIcon fontSize={'small'} />
        </IconButton>

        <Typography color={'GrayText'}>{`${props.step + 1}/${props.steps.length}`}</Typography>
      </Grid>

      <Grid item xs={12} mt={1}>
        <Typography variant={'body1'}>{props.steps[props.step].label}</Typography>
        <Typography variant={'body2'} color={'GrayText'} mb={1}>
          {props.steps[props.step].description}
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} mt={1}>
        {props.children(props.step)}
      </Grid>
    </Grid>
  );
};
