import actionTypes from 'constants/ActionTypes';
import { IAccess } from 'exactt-types/lib';
import { IDispatchType } from 'Reducers/IActionDispatch';
import { IInviteUserRequst, UserServices } from 'services/UserServices';
import showAlert from './showAlert';

export const inviteUser = (request: IInviteUserRequst): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await UserServices.inviteUserService(request);
      showAlert(dispatch, 'User added!', 'success');
      dispatch({
        type: actionTypes.users.INVITE_USER,
        payload: res.data,
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const getUserList = (request: { laboratoryId: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await UserServices.getLaboratoryUsersList(request);
      dispatch({
        type: actionTypes.users.GET_LABORATORY_USERS_LIST,
        payload: {
          list: res.data,
        },
      });
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const editUserAccess = (request: IAccess): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await UserServices.editLaboratoryUser(request);
      showAlert(dispatch, 'User edited successfully!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};

export const removeUserAccess = (request: { laboratoryId: string; accessId: string }): any => {
  return async (dispatch: IDispatchType): Promise<any> => {
    try {
      let res = await UserServices.removeLaboratoryUser(request);
      showAlert(dispatch, 'User removed successfully!', 'success');
      return res;
    } catch (error: any) {
      showAlert(dispatch, error.response.data.message ?? 'something wrong!', 'error');
      return error;
    }
  };
};
